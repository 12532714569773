import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NotificationUiModule } from '../../ui/notification/notification.module';
import { UiModule } from '../../ui/ui.module';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { CapsuleNotificationListComponent } from './capsule-notification-list/capsule-notification-list.component';


@NgModule({
    declarations: [
        NotificationListComponent,
        CapsuleNotificationListComponent,
    ],
    imports: [
        CommonModule,
        UiModule,
        NotificationUiModule,
    ],
    exports: [
        NotificationListComponent,
    ],
})

export class NotificationModule {}
