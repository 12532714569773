import {
    createAction,
    props,
} from '@ngrx/store';

import {
    Authorization,
    PhoneCode,
    PhoneCredentials,
} from '../../schemas/auth.schemas';


export const initAuthAction = createAction(
    '[Auth] Init Auth',
);

export const extractAuthorizationAction = createAction(
    '[Auth] Extract Authorization',
);

export const signUpSuccessAction = createAction(
    '[Auth] Sign Up Success',
    props<{ authorization: Authorization }>(),
);

export const signUpFailureAction = createAction(
    '[Auth] Sign Up Failure',
    props<{ error: string; }>(),
);

export const signInSuccessAction = createAction(
    '[Auth] Sign In Success',
    props<{ authorization: Authorization }>(),
);

export const signInFailureAction = createAction(
    '[Auth] Sign In Failure',
    props<{ error: string; }>(),
);

export const signOutAction = createAction(
    '[Auth] Sign Out',
);

export const signOutSuccessAction = createAction(
    '[Auth] Sign Out Success',
);

export const signOutFailureAction = createAction(
    '[Auth] Sign Out Failure',
);

export const sendCodeToPhoneAction = createAction(
    '[Auth] Send Code To Phone',
    props<{ phone: string }>(),
);

export const sendCodeToPhoneSuccessAction = createAction(
    '[Auth] Send Code To Phone Success',
    props<{ sentAt: number }>(),
);

export const sendCodeToPhoneFailureAction = createAction(
    '[Auth] Send Code To Phone Failure',
    props<{ error: string }>(),
);

export const signUpByPhoneCodeAction = createAction(
    '[Auth] Sign Up By Phone Code',
    props<{ phoneCode: PhoneCode }>(),
);

export const signUpByPhoneCodeSuccessAction = createAction(
    '[Auth] Sign Up By Phone Code Success',
);

export const signUpByPhoneCodeFailureAction = createAction(
    '[Auth] Sign Up By Phone Code Failure',
    props<{ error: string; }>(),
);

export const signInByPhoneCredentialsAction = createAction(
    '[Auth] Sign In By Phone Credentials',
    props<{ credentials: PhoneCredentials }>(),
);

export const signInByPhoneCredentialsSuccessAction = createAction(
    '[Auth] Sign In By Phone Credentials Success',
);

export const signInByPhoneCredentialsFailureAction = createAction(
    '[Auth] Sign In By Phone Credentials Failure',
    props<{ error: string; }>(),
);
