import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ConnectionFacade } from '../../../facades/connection.facade';
import { Location } from '../../../schemas/location.schemas';


@Component({
    selector: 'app-connection-disconnect',
    templateUrl: './connection-disconnect.component.html',
    styleUrls: ['./connection-disconnect.component.scss'],
})
export class ConnectionDisconnectComponent implements OnInit {

    @Input() location: Location | undefined;
    @Input() isConnected: boolean | undefined;

    @Output() connectionDisconnect = new EventEmitter<Location>();

    constructor(private connectionFacade: ConnectionFacade) {
    }

    ngOnInit(): void {
    }

    disconnect(location: Location | undefined): void {
        if (location) {
            this.connectionFacade.disconnect(location);
            this.connectionDisconnect.emit(location);
        }
    }
}
