<form [formGroup]="codeForm" (input)="onFormChange()" (submit)="onSubmit()" novalidate autocomplete="off">

    <div class="form-group">
        <div class="form-group__text">
            <span>Мы совершим звонок-подтверждение на номер: </span>
            <p>{{ phone | phoneFormat }}.</p>
            <span>Звонок бесплатный, отвечать на него не нужно.</span>
            <p>Введите последние 4 цифры номера.</p>
        </div>
    </div>

    <div class="form-group">

        <div class="title-form-group">Код подтверждения</div>
        <div class="input-group">

            <input id="form-phone-code" data-test-id="form-phone-code" type="text" formControlName="code"
                   class="form-control"
                   [ngClass]="{'form-error': !!formError}" tabindex="1" placeholder="0 0 0 0" required>
            <span data-test-id="form-phone-code-error" class="invalid-feedback"
                  *ngIf="!!formError && codeForm.controls['code'].touched">
                Неверный код
            </span>
            <span data-test-id="form-phone-code-error" class="invalid-feedback"
                  *ngIf="codeForm.controls['code'].invalid && codeForm.controls['code'].touched">
                Код должен состоять из 4 цифр
            </span>

        </div>

        <div class="form-group__text not-call">Не получили звонок?</div>
    </div>

    <div class="form-group">
        <button data-test-id="form-phone-code-repeat-send"
                type="button"
                class="btn btn-secondary sc-wide"
                tabindex="2"
                (click)="onResendCode()" [disabled]="cannotResendCode || cannotSubmit">
            Позвонить еще раз <span *ngIf="codeResendCountdown">({{ codeResendCountdown }})</span>
        </button>
    </div>

    <div class="form-group form-group--top-border">
        <a data-test-id="form-phone-code-back" routerLink="/auth" tabindex="4">Вернуться ко входу</a>
    </div>

</form>
