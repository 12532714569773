<div class="contact d-flex align-items-center justify-content-start w-100"
     [class.border-0]="item?.isBorder">

    <ng-template [ngIf]="item?.image">
        <img [class.contact__image]="item?.image" src="{{ item?.image }}" alt="image">
    </ng-template>

    <div class="contact__wrap" [class.ml-0]="!item?.image">
        <div class="contact__title">{{ item?.title }}</div>
        <div class="contact__text">{{ item?.text }}</div>
    </div>

    <ng-template [ngIf]="item?.count">
        <span class="badge badge-primary ml-auto">{{item?.count}}</span>
    </ng-template>

    <ng-content></ng-content>

</div>
