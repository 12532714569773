<div class="location-structure">
    <div class="location-structure__title">Структура капсулы</div>
    <div>
        <ui-swipe-block *ngFor="let location of locations"
                        [isSwiped]="swipedLocation?.id === location?.id"
                        (blockSwipe)="onLocationSwipe(location)">

            <ui-location-info
                    slot="swipe_layer"
                    [location]="location"
                    [level]="location?.level"
                    (locationConnect)="onLocationConnect(location)"
                    [isActive]="location?.id === currentConnectedLocationId">
            </ui-location-info>

            <app-favorite-location [location]="location" slot="visible_layer"></app-favorite-location>

        </ui-swipe-block>

    </div>
</div>
