import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwipableModule } from '../../../directives/swipable/swipable.module';
import { FloatPanelComponent } from './float-panel.component';


const components = [
    FloatPanelComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        SwipableModule,
    ],
    exports: [
        ...components,
    ],
})
export class FloatPanelModule {}
