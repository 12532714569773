import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    Observable,
    timer,
} from 'rxjs';
import {
    map,
    switchMap,
    takeWhile,
    tap,
} from 'rxjs/operators';

import { Code } from '../schemas/phone.schemas';
import * as phoneActions from '../store/actions/phone.actions';
import * as phoneSelectors from '../store/selectors/phone.selectors';


const log = new Logger('PhoneFacade');

@Injectable({
    providedIn: 'root',
})
export class PhoneFacade {

    constructor(
        private store$: Store,
    ) { }

    get isLoading$(): Observable<boolean> {
        return this.store$.pipe(
            select(phoneSelectors.selectIsLoading),
        );
    }

    get error$(): Observable<schemas.ResponseError | null> {
        return this.store$.pipe(
            select(phoneSelectors.selectError),
        );
    }

    get code$(): Observable<Code | null> {
        return this.store$.pipe(
            select(phoneSelectors.selectCode),
        );
    }

    get codePhone$(): Observable<string | undefined> {
        return this.store$.pipe(
            select(phoneSelectors.selectCodePhone),
        );
    }

    get codeSentAt$(): Observable<number | undefined> {
        return this.store$.pipe(
            select(phoneSelectors.selectCodeSentAt),
            tap(sentAt => log.info('Code Sent At:', sentAt)),
        );
    }

    get codeResendCountdown$(): Observable<number> {
        const duration = 60 * 1000;

        return this.codeSentAt$.pipe(
            switchMap(sentAt => timer(0, 1000).pipe(
                map(() => {
                    return (typeof sentAt === 'number' && sentAt > 0)
                        ? Math.ceil((sentAt + duration - Date.now()) / 1000)
                        : 0;
                }),
                takeWhile(countdown => countdown >= 0),
            )),
        );
    }

    get canSendCode$(): Observable<boolean> {
        return this.isLoading$.pipe(
            switchMap(isLoading => this.codeResendCountdown$.pipe(
                map(countdown => {
                    return !isLoading && !countdown;
                }),
            )),
        );
    }

    get cannotSendCode$(): Observable<boolean> {
        return this.canSendCode$.pipe(
            map(canSend => !canSend),
        );
    }

    sendCode(phone: string): void {
        this.store$.dispatch(phoneActions.sendCodeAction({phone}));
    }

    sendSignUpCode(phone: string): void {
        this.store$.dispatch(phoneActions.sendSignUpCodeAction({phone}));
    }

    sendSignInCode(phone: string): void {
        this.store$.dispatch(phoneActions.sendSignInCodeAction({phone}));
    }

    sendPasswordResetCode(phone: string): void {
        this.store$.dispatch(phoneActions.sendPasswordResetCodeAction({phone}));
    }

}
