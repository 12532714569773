import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { schemas } from '@scatch/ngx-app-lib';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-auth-phone-signup-step-first-form',
    templateUrl: './auth-phone-signup-step-first-form.component.html',
    styleUrls: ['./auth-phone-signup-step-first-form.component.scss'],
})
export class AuthPhoneSignupStepFirstFormComponent implements OnInit {

    signUpForm: FormGroup;

    @Input() cannotSubmit: boolean | null = null;
    @Input() formError: schemas.ResponseError | null = null;
    @Output() formSubmit = new EventEmitter();

    constructor() {

        this.signUpForm = new FormGroup({

            phone: new FormControl('', [
                Validators.required,
                Validators.pattern(/^([0-9]{8,14}|)$/),
            ]),

        });

    }

    ngOnInit(): void {
    }

    onFormSubmit(): void {
        this.signUpForm.value.phone = '+7' + this.signUpForm.value.phone;
        this.formSubmit.emit(this.signUpForm.value);
    }

}
