import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Logger } from '@scatch/ngx-app-lib';
import {
    fromEvent,
    of,
} from 'rxjs';
import {
    catchError,
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import * as authActions from '../actions/auth.actions';
import * as localStorageActions from '../actions/local-storage.actions';


const log = new Logger('LocalStorageEffects');

@Injectable()
export class LocalStorageEffects {

    constructor(
        private actions$: Actions,
    ) {}

    saveAuthorizationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInSuccessAction),
            tap(({authorization}) => {
                log.info('Save authorization to local storage', authorization);
                localStorage.setItem('authorization', JSON.stringify(authorization));
            }),
        ),
        {dispatch: false},
    );

    syncAuthorizationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.initAction,
                localStorageActions.syncAuthorizationAction,
            ),
            switchMap(() => of(localStorage.getItem('authorization')).pipe(
                map(authorization => {
                    if (!authorization) {
                        return authActions.signOutSuccessAction();
                    }
                    log.info('Sync authorization from local storage', authorization);

                    return authActions.signInSuccessAction({
                        authorization: JSON.parse(authorization),
                    });
                }),
                catchError(() => of(authActions.signOutSuccessAction())),
                first(),
            )),
        ),
    );

    listenAuthorizationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.initAction),
            switchMap(() => fromEvent(window, 'storage')),
            map(() => localStorageActions.syncAuthorizationAction()),
        ),
    );

    signOutEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signOutAction),
            tap(() => {
                localStorage.removeItem('authorization');
            }),
        ),
        {dispatch: false},
    );

}
