import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SliderInfoComponent } from './slider-info/slider-info.component';
import { SliderSimpleComponent } from './slider-simple/slider-simple.component';


const components = [
    SliderSimpleComponent,
    SliderInfoComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        SwiperModule,
    ],
    exports: [...components],
})

export class SlidersUiModule {}
