import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListSimpleComponent } from './list-simple/list-simple.component';


@NgModule({
    declarations: [
        ListSimpleComponent
    ],
    exports: [
        ListSimpleComponent
    ],
    imports: [
        CommonModule
    ]
})
export class ListsUiModule { }
