import {
    EntityMap,
    EntityMapOne,
    Predicate,
    Update,
} from '@ngrx/entity';
import {
    createAction,
    props,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import { Capsule } from '../../schemas/capsule.schemas';


export const setCapsules = createAction(
    '[Capsule] Set Capsules',
    props<{ capsules: Capsule[] }>(),
);

export const addCapsule = createAction(
    '[Capsule] Add Capsule',
    props<{ capsule: Capsule }>(),
);

export const setCapsule = createAction(
    '[Capsule] Set Capsule',
    props<{ capsule: Capsule }>(),
);

export const upsertCapsule = createAction(
    '[Capsule] Upsert Capsule',
    props<{ capsule: Capsule }>(),
);

export const addCapsules = createAction(
    '[Capsule] Add Capsules',
    props<{ capsules: Capsule[] }>(),
);

export const upsertCapsules = createAction(
    '[Capsule] Upsert Capsules',
    props<{ capsules: Capsule[] }>(),
);

export const updateCapsule = createAction(
    '[Capsule] Update Capsule',
    props<{ update: Update<Capsule> }>(),
);

export const updateCapsules = createAction(
    '[Capsule] Update Capsules',
    props<{ updates: Update<Capsule>[] }>(),
);

export const mapCapsule = createAction(
    '[Capsule] Map Capsule',
    props<{ entityMap: EntityMapOne<Capsule> }>(),
);

export const mapCapsules = createAction(
    '[Capsule] Map Capsules',
    props<{ entityMap: EntityMap<Capsule> }>(),
);

export const deleteCapsule = createAction(
    '[Capsule] Delete Capsule',
    props<{ id: number }>(),
);

export const deleteCapsules = createAction(
    '[Capsule] Delete Capsules',
    props<{ ids: number[] }>(),
);

export const deleteCapsulesByPredicate = createAction(
    '[Capsule] Delete Capsules By Predicate',
    props<{ predicate: Predicate<Capsule> }>(),
);

export const clearCapsules = createAction(
    '[Capsule] Clear Capsules',
);

export const loadCapsuleAction = createAction(
    '[Capsule] Load Capsule',
    props<{ capsuleId: number }>(),
);

export const loadCapsuleSuccessAction = createAction(
    '[Capsule] Load Capsule Success',
    props<{ capsule: Capsule }>(),
);

export const loadCapsuleFailureAction = createAction(
    '[Capsule] Load Capsule Failure',
    props<{ error: schemas.ResponseError }>(),
);

export const setCurrentCapsuleId = createAction(
    '[Capsule] Set Current Capsule Id',
    props<{ capsuleId: number }>()
);

export const unsetCurrentCapsuleId = createAction(
    '[Capsule] Unset Current Capsule Id',
);

export const loadCurrentCapsuleWidgetsSuccessAction = createAction(
    '[Capsule] Load Current Capsule Widgets Success',
);
