import {
    createAction,
    props,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import { Authorization } from '../../schemas/auth.schemas';


export const resetAction = createAction(
    '[Password] Reset',
    props<{ password: string }>(),
);

export const resetSuccessAction = createAction(
    '[Password] Reset Success',
    props<{ authorization: Authorization }>(),
);

export const resetFailureAction = createAction(
    '[Password] Reset Failure',
    props<{ error: schemas.ResponseError; }>(),
);
