import {
    createAction,
    props,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import { Location } from '../../schemas/location.schemas';

export const connectAction = createAction(
    '[Connection] Connect',
    props<{ token: string }>(),
);

export const connectSuccessAction = createAction(
    '[Connection] Connect Success',
    props<{ connection: schemas.Connection }>(),
);

export const connectFailureAction = createAction(
    '[Connection] Connect Failure',
    props<{ error: schemas.ResponseError }>(),
);

export const connectToLocationAction = createAction(
    '[Connection] Connect To Location',
    props<{ location: Location }>(),
);

export const disconnectFromLocationAction = createAction(
    '[Connection] Disconnect From Location',
    props<{ location: Location }>(),
);

export const setCurrentConnectionAction = createAction(
    '[Connection] Set Current Connection',
    props<{ connection: schemas.Connection }>(),
);
