import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { SocialItem } from '../../../schemas/social.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-social-item',
    templateUrl: './social-item.component.html',
    styleUrls: ['./social-item.component.scss'],
})
export class SocialItemComponent implements OnInit {

    @Input() social: SocialItem | undefined;

    constructor() { }

    ngOnInit(): void {
    }

}
