import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConnectLocationItemComponent } from './connect-location-item/connect-location-item.component';
import { SocialUiModule } from '../social/social.module';


@NgModule({
    declarations: [
        ConnectLocationItemComponent,
    ],
    exports: [
        ConnectLocationItemComponent,
    ],
    imports: [
        CommonModule,
        SocialUiModule,
    ],
})
export class ConnectUiModule {}
