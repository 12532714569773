<sidenav [orientation]="orientation" [ngClass]="getClasses()">

    <!-- Inner -->
    <div class="sidenav-inner">

        <sidenav-router-link icon="ion ion-ios-settings" route="/account/settings"
                             [active]="isActive('/account/settings')">
            Настройки аккаунта
        </sidenav-router-link>

        <sidenav-router-link icon="ion ion-md-list-box" route="/favorites/locations" [active]="isActive('/favorites/locations')">
            Избранное
        </sidenav-router-link>

        <sidenav-router-link icon="ion ion-md-exit" (click)="signOut()">
            Выйти
        </sidenav-router-link>

    </div>
</sidenav>

