import {
    createAction,
    props,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';


export const parseDataAction = createAction(
    '[Scanner] Parse Data',
    props<{ data: string }>(),
);

export const loadDemoCodesAction = createAction(
    '[Scanner] Load Demo Codes',
);

export const loadDemoCodesSuccessAction = createAction(
    '[Scanner] Load Demo Codes Success',
    props<{ demoCodes: schemas.DemoCode[] }>(),
);

export const loadDemoCodesFailureAction = createAction(
    '[Scanner] Load Demo Codes Failure',
    props<{ error: schemas.ResponseError }>(),
);
