<div class="sc-auth">

  <ui-logo></ui-logo>

  <ui-auth-phone-forgot-password-step-third
    [cannotSubmit]="isLoading$ | async"
    [formError]="authError$ | async"
    (formSubmit)="onFormSubmit($event)">
  </ui-auth-phone-forgot-password-step-third>

</div>
