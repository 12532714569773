<div class="account">
    <div class="card overflow-hidden">
        <div class="row no-gutters row-bordered row-border-light">
            <div class="col-md-3 pt-0">
                <div class="list-group list-group-flush account-settings-links">
                    <button type="button" class="list-group-item list-group-item-action"
                            [ngClass]="{active: curTab === 'general'}"
                            (click)="curTab = 'general'">Основные
                    </button>
                    <button type="button" class="list-group-item list-group-item-action"
                            [ngClass]="{active: curTab === 'password'}"
                            (click)="curTab = 'password'">Пароль и безопасность
                    </button>
                    <button type="button" class="list-group-item list-group-item-action"
                            [ngClass]="{active: curTab === 'connections'}" (click)="curTab = 'connections'">Привязанные
                        аккаунты
                    </button>
                </div>
            </div>
            <div class="col-md-9" *ngIf="curTab === 'general'">
                <app-account-profile></app-account-profile>
            </div>
            <div class="col-md-9" *ngIf="curTab === 'password'">
                <app-account-security></app-account-security>
            </div>
            <div class="col-md-9" *ngIf="curTab === 'connections'">
                <app-account-external-services></app-account-external-services>
            </div>
        </div>
    </div>

    <div class="text-right mt-3">
        <button type="button" class="btn btn-default">Отмена</button>
        <button type="button" class="btn btn-primary">Сохранить</button>
    </div>
</div>


