import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocationSwipeModule } from '../../../ui/interactives/swipe-block/swipe-block.module';
import { LocationInfoModule } from '../../../ui/locations/location-info/location-info.module';
import { FavoriteLocationModule } from '../../favorite/favorite-location/favorite-location.module';
import { LocationIconStructureModule } from '../../locations/location-icon-structure/location-icon-structure.module';
import { ConnectionDisconnectModule } from '../connection-disconnect/connection-disconnect.module';
import { ConnectionListComponent } from './connection-list.component';


const components = [
    ConnectionListComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        LocationSwipeModule,
        FavoriteLocationModule,
        LocationIconStructureModule,
        ConnectionDisconnectModule,
        LocationInfoModule,
    ],
    exports: [
        ...components,
    ],
})
export class ConnectionListModule {}
