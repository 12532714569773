<div class="home-capsule">

  <form action="#">

    <div class="form-group home-capsule__header">

<!--      <div>{{ title }}</div>-->
        <div>Домашняя капсула</div>

<!--      <i>-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
<!--          <g id="24 / basic / pencil-create">-->
<!--            <path id="icon" fill-rule="evenodd" clip-rule="evenodd"-->
<!--                  d="M16.4374 2C17.0921 2 17.7197 2.26142 18.1781 2.72342L21.279 5.82432C21.7407 6.286 22.0001 6.91217 22.0001 7.56508C22.0001 8.21799 21.7407 8.84416 21.279 9.30584L9.95751 20.6238C9.25902 21.4295 8.2689 21.9245 7.1346 22.0023H2V21.0023L2.00325 16.7873C2.08844 15.7328 2.57867 14.7523 3.3265 14.0934L14.6954 2.72463C15.1564 2.26083 15.7834 2 16.4374 2ZM7.06398 20.0048C7.59821 19.967 8.09549 19.7184 8.49479 19.2616L16.0567 11.6997L12.3023 7.94519L4.6961 15.5496C4.29095 15.9079 4.04031 16.4092 4 16.8678V20.0029L7.06398 20.0048ZM13.7167 6.53115L17.4709 10.2855L19.8648 7.89162C19.9514 7.80502 20.0001 7.68756 20.0001 7.56508C20.0001 7.4426 19.9514 7.32514 19.8648 7.23854L16.7611 4.13486C16.6755 4.04855 16.5589 4 16.4374 4C16.3158 4 16.1992 4.04855 16.1136 4.13486L13.7167 6.53115Z"-->
<!--                  fill="#66686D"/>-->
<!--          </g>-->
<!--        </svg>-->
<!--      </i>-->

    </div>

    <div class="home-capsule__content">

      <div class="home-capsule__media">
        <div class="sc-icon-upload"></div>
        <div class="media-body ml-4">
          <button type="button" class="btn btn-outline-primary">Загрузить</button> &nbsp;
          <div class="home-capsule__text">Поддерживаются JPG или PNG. Максимальный размер 1Mb.</div>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label">Настройки доступа</label>
        <select class="custom-select">
          <option *ngFor="let access of ['Доступна всем', 'Доступна только мне']" [value]="access">{{ access }}</option>
        </select>
      </div>

      <div class="form-group">

        <button class="btn btn-primary btn-greater sc-wide">

          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="16 / arrow-bottom">
              <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                    d="M10.4999 15.8787V3H13.4999V15.8787L18.4393 10.9393L20.5606 13.0607L11.9999 21.6213L3.43927 13.0607L5.56059 10.9393L10.4999 15.8787Z"
                    fill="white"/>
            </g>
          </svg>

          <span>
          Сохранить QR-код
        </span>

        </button>

        <div class="home-capsule__text">
          Вы можете сохранить QR-код и разместить его на любом носителе, чтобы пользователи могли легко и удобно
          подключиться к вашей капсуле.
        </div>

      </div>

      <div class="form-group">

        <button class="btn btn-secondary btn-greater sc-wide">

          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10 2H20C21.1523 2 22 2.84772 22 4V14C22 15.1523 21.1523 16 20 16H16V20C16 21.1523 15.1523 22 14 22H4C2.84772 22 2 21.1523 2 20V10C2 8.84772 2.84772 8 4 8H8V4C8 2.84772 8.84772 2 10 2ZM8 10H4V20H14V16H10C8.84772 16 8 15.1523 8 14V10ZM10 4V14H20V4H10Z"
                  fill="white"/>
          </svg>

          <span>Скопировать ссылку</span>

        </button>

        <div class="home-capsule__text">
          Капсула доступна по ссылке, вы можете скопировать её в буфер обмена.
        </div>

      </div>
    </div>

  </form>

</div>
