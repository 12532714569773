import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';

import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CapsuleUiModule } from '../../ui/capsule/capsule.module';
import { ConnectionUiModule } from '../../ui/connection/connection.module';
import { LocationsUiModule } from '../../ui/locations/locations.module';
import { TabsModule } from '../../ui/tabs/tabs.module';
import { UiModule } from '../../ui/ui.module';
import { FavoriteModule } from '../favorite/favorite.module';
import { HistoryListModule } from '../history/history-list/history-list.module';
import { CapsuleHomeComponent } from './capsule-home/capsule-home.component';
import { CapsuleInfoComponent } from './capsule-info/capsule-info.component';


@NgModule({
    declarations: [
        CapsuleInfoComponent,
        CapsuleHomeComponent,
    ],
    imports: [
        CommonModule,
        UiModule,
        CapsuleUiModule,
        TabsModule,
        NgbModule,
        DragDropModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ConnectionUiModule,
        FavoriteModule,
        LocationsUiModule,
        HistoryListModule,
    ],
    exports: [
        CapsuleInfoComponent,
    ],
})
export class CapsuleModule {}
