import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Card } from '../../../schemas/about.schemas';
import { Location } from '../../../schemas/location.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-card-simple',
    templateUrl: './card-simple.component.html',
    styleUrls: ['./card-simple.component.scss'],
})
export class CardSimpleComponent implements OnInit {

    @Input() location: Location | null | undefined;
    @Input() card: Card | null | undefined;
    @Input() images: Observable<string[]> | undefined;
    @Output() toggleLocationFavorite = new EventEmitter<Location>();

    constructor() { }

    ngOnInit(): void {
    }

    toggleFavoriteLocation(location: Location | null | undefined): void {
        if (location) {
            this.toggleLocationFavorite.emit(location);
        }
    }

}
