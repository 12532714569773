import {
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-panel',
    templateUrl: './panel.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {

    @Input() currentStage = 0;
    @Input() panel = true;
    @Input() currentPointStage = 0;

}
