import {
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { ConnectionFacade } from '../../facades/connection.facade';
import { LayoutService } from '../layout.service';


@Component({
    selector: 'app-layout-vendor-style-navbar',
    templateUrl: './layout-vendor-style-navbar.component.html',
    styles: [':host { display: block; }'],
    styleUrls: ['./layout-vendor-style-navbar.component.scss'],
})
export class LayoutVendorStyleNavbarComponent {

    isExpanded = false;
    isRTL: boolean;

    @Input() sidenavToggle = true;
    @Input() title: string | null | undefined = '';
    @HostBinding('class.layout-navbar') hostClassMain = true;

    isConnected$: Observable<boolean> = this.connectionFacade.isConnected$;

    constructor(
        private appService: AppService,
        private layoutService: LayoutService,
        private connectionFacade: ConnectionFacade,
    ) {
        this.isRTL = appService.isRTL;
    }

    currentBg(): string {
        return `bg-${this.appService.layoutNavbarBg}`;
    }

    toggleSidenav(): void {
        this.layoutService.toggleCollapsed();
    }

}
