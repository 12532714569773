import {
    Component,
    OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';

import { CapsuleFacade } from '../../../facades/capsule.facade';
import { WidgetFacade } from '../../../facades/widget.facade';
import { Widget } from '../../../schemas/widget.schemas';


@Component({
    selector: 'app-widget-list',
    templateUrl: './widget-list.component.html',
    styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent implements OnInit {

    widgets$: Observable<Widget[]> = this.capsuleFacade.widgets$;

    constructor(
        private capsuleFacade: CapsuleFacade,
        private widgetFacade: WidgetFacade,
    ) { }

    ngOnInit(): void {
    }

    openWidget(widget: Widget): void {
        this.widgetFacade.openWidget(widget);
    }

}
