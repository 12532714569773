import {
    Directive,
    ElementRef,
    EventEmitter,
    Output,
} from '@angular/core';


@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[swipable]',
})
export class SwipableDirective {

    @Output() swipeStart = new EventEmitter<any>();
    @Output() swipeMove = new EventEmitter<any>();
    @Output() swipeEnd = new EventEmitter<any>();

    constructor(
        private elementRef: ElementRef,
    ) {
        elementRef.nativeElement.addEventListener(
            'pointerdown',
            this.handleStart.bind(this),
            true,
        );
        elementRef.nativeElement.addEventListener(
            'pointermove',
            this.handleMove.bind(this),
            true,
        );
        elementRef.nativeElement.addEventListener(
            'pointerup',
            this.handleEnd.bind(this),
            true,
        );
        elementRef.nativeElement.addEventListener(
            'pointercancel',
            this.handleEnd.bind(this),
            true,
        );
    }

    handleStart(event: any): void {
        event.preventDefault();

        if (event.touches && event.touches.length > 1) {
            return;
        }

        event.target.setPointerCapture(event.pointerId);

        this.swipeStart.emit(event);
    }

    handleMove(event: any): void {
        event.preventDefault();

        this.swipeMove.emit(event);
    }

    handleEnd(event: any): void {
        event.preventDefault();

        if (event.touches && event.touches.length > 0) {
            return;
        }

        event.target.releasePointerCapture(event.pointerId);

        this.swipeEnd.emit(event);
    }

}
