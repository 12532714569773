import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    selector: 'app-account-profile',
    templateUrl: './account-profile.component.html',
    styleUrls: ['./account-profile.component.scss'],
})
export class AccountProfileComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
