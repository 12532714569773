<div class="favorite">
    <i [hidden]="isFavorite"
       (click)="toggleFavorite(location)"
       class="favorite__social-item">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 23" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.17034 14.894L4.79412 22.918L12.0001 19.1296L19.2061 22.918L17.8299 14.894L23.6596 9.21138L15.6031 8.04069L12.0001 0.740234L8.39711 8.04069L0.340576 9.21138L6.17034 14.894ZM15.6809 14.1958L16.5498 19.2619L12.0001 16.87L7.45038 19.2619L8.3193 14.1958L4.6385 10.6079L9.72524 9.86871L12.0001 5.25934L14.275 9.86871L19.3617 10.6079L15.6809 14.1958Z"
                  fill="inherit"/>
        </svg>
    </i>

    <i [hidden]="!isFavorite"
       (click)="toggleFavorite(location)"
       class="favorite__social-item favorite__social-item--favorite">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="28" viewBox="0 0 24 23" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.0001 19.1296L4.79412 22.918L6.17034 14.894L0.340576 9.21138L8.39711 8.04069L12.0001 0.740234L15.6031 8.04069L23.6596 9.21138L17.8299 14.894L19.2061 22.918L12.0001 19.1296Z"
                  fill="inherit"/>
        </svg>
    </i>
</div>
