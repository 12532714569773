import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WidgetSimpleComponent } from './widget-simple/widget-simple.component';


@NgModule({
    declarations: [
        WidgetSimpleComponent,
    ],
    exports: [
        WidgetSimpleComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ],
})
export class WidgetsUiModule {}
