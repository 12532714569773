import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    LocationApi,
    Logger,
    utils,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    catchError,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { Location } from '../../schemas/location.schemas';

import * as appActions from '../actions/app.actions';
import * as favoriteActions from '../actions/favorite.actions';
import * as locationActions from '../actions/location.actions';
import * as routerActions from '../actions/router.actions';


const log = new Logger('FavoriteEffects');

@Injectable()
export class FavoriteEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private locationApi: LocationApi,
        private router: Router,
    ) { }

    syncFavoriteLocationsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                appActions.initAction,
                favoriteActions.syncAction,
            ),
            switchMap(() => this.locationApi.fetchFavoriteLocations().pipe(
                map(favoriteLocations => {
                    log.debug('Sync favoriteLocations', favoriteLocations);
                    return favoriteActions.restoreAction({
                        locations: favoriteLocations.locations,
                        folders: favoriteLocations.folders,
                    });
                }),
            )),
        ),
    );

    toggleFavoriteLocationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(favoriteActions.toggleFavoriteLocationAction),
            tap(locationId => log.debug('toggleLocationFavoriteEffect (locationId)', locationId)),
            switchMap(({locationId}) =>
                this.locationApi.toggleFavoriteLocation(locationId).pipe(
                    map(() => this.store$.dispatch(favoriteActions.syncAction())),
                ),
            ),
        ),
        {dispatch: false},
    );


    loadFavoriteLocationsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsSuccessAction),
            tap(({locations}) => log.debug('loadFavoriteLocationsEffect (locations)', locations)),
            switchMap(({locations}) => {
                return this.locationApi.fetchFavoriteLocations().pipe(
                    tap(favoriteLocations => log.debug('loadFavoriteLocationsEffect (locations)', favoriteLocations)),
                    map(favoriteLocations => {
                        const updatedLocations: Location[] = [];
                        const storeFavoriteLocations = favoriteLocations.locations;

                        for (const location of locations) {
                            const index = storeFavoriteLocations.findIndex(item => item.locationId === location.id);
                            updatedLocations.push({
                                ...location,
                                isFavorite: index > -1,
                            });
                        }

                        return locationActions.upsertLocations({
                            locations: updatedLocations,
                        });
                    }),
                );
            }),
        ),
    );

    createFolderEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(favoriteActions.createFolderAction),
            tap((locations) => log.debug('createFolderEffect (locations)', locations)),
            switchMap((locations) => {
                return this.locationApi.addFavoriteLocationFolder(locations.locationLeft.id, locations.locationRight.id).pipe(
                    tap(data => log.debug('addFavoriteLocationFolder (locations)', data)),
                    tap((data) => {
                        const folderId = data.folderId;

                        if (folderId && folderId > 0) {
                            this.store$.dispatch(favoriteActions.createFolderSuccessAction({
                                locations: data.locations,
                                folderId,
                            }));

                            this.router.navigateByUrl('capsule/folder/' + folderId)
                                .catch(reason => log.error('Navigate failed', reason));
                        }
                    }),
                    catchError(response => {
                        log.debug('loadLocationsEffect (response)', response);
                        this.store$.dispatch(favoriteActions.createFolderFailureAction({
                            error: utils.formatErrors(response),
                        }));
                        return of(false);
                    }),
                );
            }),
        ),
        {dispatch: false},
    );

    createFolderSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(favoriteActions.createFolderSuccessAction),
            tap((data) => log.debug('createFolderSuccessEffect (data)', data)),
            map(() => this.store$.dispatch(favoriteActions.syncAction())),
        ),
        {dispatch: false},
    );

    createFolderFailEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(favoriteActions.createFolderFailureAction),
            tap((error) => log.debug('createFolderFailEffect (error)', error)),
        ),
        {dispatch: false},
    );

    updateFolderTitleEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(favoriteActions.updateFolderTitleAction),
            tap((data) => log.debug('updateFolderTitleEffect (data)', data)),
            switchMap((data) => {
                return this.locationApi.updateFolderTitle(data.folderId, data.newValue).pipe(
                    map(() => this.store$.dispatch(favoriteActions.syncAction())),
                );
            }),
        ),
        {dispatch: false},
    );

    ungroupLocationsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(favoriteActions.ungroupLocationsAction),
            tap((folderId) => log.debug('ungroupLocationsEffect (folderId)', folderId)),
            switchMap(({folderId}) => {
                return this.locationApi.ungroupFavoriteLocations(folderId).pipe(
                    switchMap(() => {
                        return [
                            favoriteActions.syncAction(),
                            routerActions.goFavoriteLocationListAction(),
                        ];
                    }),
                );
            }),
        ),
    );

    deleteFavoriteLocationFromFolderEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(favoriteActions.deleteFavoriteLocationFromFolderAction),
            tap((locationId) => log.debug('deleteFavoriteLocationFromFolderEffect(locationId)', locationId)),
            switchMap(({locationId}) => {
                return this.locationApi.deleteFavoriteLocationFromFolder(locationId).pipe(
                    switchMap((result) => {
                        if (!result) {
                            return [
                                favoriteActions.syncAction(),
                                routerActions.goFavoriteLocationListAction(),
                            ];
                        } else {
                            return [favoriteActions.syncAction()];
                        }
                    }),
                );
            }),
        ),
    );

    addFavoriteLocationInFolderEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(favoriteActions.addFavoriteLocationInFolderAction),
            tap((data) => log.debug('addFavoriteLocationInFolderEffect(folderId, locationId)', data.folderId, data.locationId)),
            switchMap((data) => {
                return this.locationApi.addFavoriteLocationInFolder(data.folderId, data.locationId).pipe(
                    map(() => this.store$.dispatch(favoriteActions.syncAction())),
                );
            }),
        ),
        {dispatch: false},
    );

}

