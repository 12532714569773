import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';


interface Point {
    x: number;
    y: number;
}

const requestAnimFrame = ((): (callback: () => void) => void => {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        // @ts-ignore
        window.mozRequestAnimationFrame ||
        (callback => {
            window.setTimeout(callback, 1000 / 60);
        });
})();

const logger = new Logger('SwipeBlockComponent');

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-swipe-block',
    templateUrl: './swipe-block.component.html',
    styleUrls: ['./swipe-block.component.scss'],
})
export class SwipeBlockComponent implements OnInit, AfterViewInit, OnChanges {

    private rafPending = false;
    private swipeElement?: HTMLElement;
    private initialTouchPos?: Point;
    private lastTouchPos?: Point;

    STATE_DEFAULT = 1;
    STATE_LEFT_SIDE = 2;
    currentXPosition = 0;
    itemWidth = 0;
    handleSize = 35;
    currentState = this.STATE_DEFAULT;
    slopValue = 0;

    @Input() isSwiped?: boolean;

    @Output() blockClick = new EventEmitter<void>();
    @Output() blockSwipe = new EventEmitter<void>();

    @ViewChild('swipeBlock') swipeElementRef?: ElementRef;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.swipeElement = this.swipeElementRef?.nativeElement;
        if (this.swipeElement) {
            this.itemWidth = this.swipeElement.clientWidth;
            this.slopValue = this.itemWidth * (1 / 4);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        logger.debug('changes', changes);
        if (changes.isSwiped.currentValue === false) {
            this.changeState(this.STATE_DEFAULT);
        }
    }

    @HostListener('window:resize', ['$event'])
    resize(): void {
        if (this.swipeElement?.clientWidth) {
            this.itemWidth = this.swipeElement?.clientWidth;
            this.slopValue = this.itemWidth * (1 / 4);
        }
    }

    onClickSwipeBlock(): void {
        this.blockClick.emit();
    }

    onSwipeStart(event: PointerEvent): void {
        if (!this.swipeElement) {
            return;
        }

        this.initialTouchPos = {
            x: event.clientX,
            y: event.clientY,
        };

        this.swipeElement.style.transition = 'initial';
    }

    onSwipeMove(event: PointerEvent): void {
        if (!this.initialTouchPos) {
            return;
        }

        this.lastTouchPos = {
            x: event.clientX,
            y: event.clientY,
        };

        if (this.rafPending) {
            return;
        }

        this.rafPending = true;
        requestAnimFrame(this.onAnimFrame.bind(this));
    }

    onSwipeEnd($event: any): void {
        this.rafPending = false;
        this.updateSwipeRestPosition();

        this.initialTouchPos = undefined;
        this.lastTouchPos = undefined;
        this.blockSwipe.emit($event);
    }

    onAnimFrame(): void {
        if (!this.rafPending) {
            return;
        }

        if (this.swipeElement && this.lastTouchPos?.x && this.initialTouchPos?.x) {
            const differenceInX = Math.abs(this.initialTouchPos.x - this.lastTouchPos.x);

            let transformStyle = '';

            switch (this.currentState) {
                case this.STATE_DEFAULT:
                    transformStyle = 'translateX(' + -(differenceInX) + 'px)';
                    break;
                case this.STATE_LEFT_SIDE:
                    const currentX = this.currentXPosition + differenceInX;
                    const translate = currentX < 0 ? currentX : 0;
                    transformStyle = 'translateX(' + translate + 'px)';
                    break;
            }

            this.swipeElement.style.transformStyle = transformStyle;
            this.swipeElement.style.transform = transformStyle;
            this.swipeElement.style.transition = 'all 150ms ease-out';

            this.slopValue = this.itemWidth * (1 / 4);
        }

        this.rafPending = false;
    }

    changeState(newState: any): void {
        if (!this.swipeElement) {
            return;
        }

        this.itemWidth = this.swipeElement.clientWidth;
        this.slopValue = this.itemWidth * (1 / 4);

        let transformStyle;

        switch (newState) {
            case this.STATE_DEFAULT:
                this.currentXPosition = 0;
                break;
            case this.STATE_LEFT_SIDE:
                this.currentXPosition = -(this.itemWidth - this.handleSize);
                break;
        }

        transformStyle = 'translateX(' + this.currentXPosition + 'px)';

        this.swipeElement.style.transformStyle = transformStyle;
        this.swipeElement.style.transform = transformStyle;

        this.currentState = newState;
    }

    updateSwipeRestPosition(): void {
        if (!this.initialTouchPos || !this.lastTouchPos || !this.swipeElement) {
            return;
        }

        const differenceInX = this.initialTouchPos.x - this.lastTouchPos.x;
        this.currentXPosition = this.currentXPosition - differenceInX;

        let newState = this.STATE_DEFAULT;

        if (Math.abs(differenceInX) > this.slopValue) {
            if (this.currentState === this.STATE_DEFAULT) {
                if (differenceInX > 0) {
                    newState = this.STATE_LEFT_SIDE;
                }
            } else {
                if (this.currentState === this.STATE_LEFT_SIDE && differenceInX > 0) {
                    newState = this.STATE_DEFAULT;
                }
            }
        } else {
            newState = this.currentState;
        }

        this.changeState(newState);
        this.swipeElement.style.transition = 'all 150ms ease-out';
    }
}
