import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocationSwipeModule } from '../../../ui/interactives/swipe-block/swipe-block.module';
import { LocationInfoModule } from '../../../ui/locations/location-info/location-info.module';
import { ConnectionDisconnectModule } from '../../connection/connection-disconnect/connection-disconnect.module';
import { FavoriteLocationModule } from '../../favorite/favorite-location/favorite-location.module';
import { LocationIconStructureModule } from '../location-icon-structure/location-icon-structure.module';
import { LocationStructureComponent } from './location-structure.component';


const components = [
    LocationStructureComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        LocationSwipeModule,
        LocationIconStructureModule,
        FavoriteLocationModule,
        LocationInfoModule,
        ConnectionDisconnectModule,
    ],
    exports: [
        ...components,
    ],
})
export class LocationStructureModule {}
