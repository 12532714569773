<form>

  <div class="card-body">
    <button type="button" class="btn btn-facebook">Привязать <strong>Facebook</strong></button>
  </div>

  <hr class="border-light m-0">
  <div class="card-body">
    <div class="mb-2">
      <a href="javascript:void(0)" class="float-right text-muted text-tiny"><i class="ion ion-md-close"></i> Remove</a>
      <i class="ion ion-logo-google text-google"></i>
      Учетная запись привязана
    </div>
    nmaxwell@mail.com
  </div>

</form>
