import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiModule } from '../../ui/ui.module';
import { AccountUiModule } from '../../ui/account/account.module';

import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AccountProfileComponent } from './account-profile/account-profile.component';
import { AccountSecurityComponent } from './account-security/account-security.component';
import { AccountExternalServicesComponent } from './account-external-services/account-external-services.component';


@NgModule({
    declarations: [
        AccountSettingsComponent,
        AccountProfileComponent,
        AccountSecurityComponent,
        AccountExternalServicesComponent,
    ],
    imports: [
        CommonModule,
        UiModule,
        AccountUiModule,
    ],
    exports: [
        AccountSettingsComponent,
        AccountProfileComponent,
    ],
})

export class AccountModule {}
