<div class="sc-auth">

  <ui-logo></ui-logo>

  <ui-form-phone-code-form
    [phone]="codePhone$ | async"
    [codeResendCountdown]="codeResendCountdown$ | async"
    [cannotResendCode]="cannotResendCode$ | async"
    [cannotSubmit]="isLoading$ | async"
    [formError]="(authError$ | async) || (phoneError$ | async)"
    (resendCode)="onResendCode()"
    (formSubmit)="onFormSubmit($event)">
  </ui-form-phone-code-form>

</div>

