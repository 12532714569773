<form [formGroup]="accountProfileForm">

  <div class="card-body media align-items-center">
    <div class="sc-icon-upload d-block ui-w-80"></div>
    <div class="media-body ml-4">
      <button type="button" class="btn btn-outline-primary">Загрузить</button> &nbsp;
      <div class="text-light small mt-1">Поддерживаются JPG или PNG. Максимальный размер 1Mb.</div>
    </div>
  </div>

  <hr class="border-light m-0">

  <div class="form-group">
    <label class="form-label">Имя</label>
    <input [(ngModel)]="accountData.firstName"
           formControlName="firstName" type="text" class="form-control mb-1"
           value="{{ accountData.firstName }}">
  </div>

  <div class="form-group">
    <label class="form-label">Фамилия</label>
    <input [(ngModel)]="accountData.lastName"
           type="text" class="form-control" formControlName="lastName"
           value="{{ accountData.lastName }}">
  </div>

  <div class="form-group">
    <label class="form-label">Пол</label>
    <select class="custom-select" [(ngModel)]="accountData.gender" formControlName="gender">
      <option *ngFor="let gender of ['Мужской', 'Женский']" [value]="gender">{{gender}}</option>
    </select>
  </div>

    <div class="form-group">
        <label class="form-label">Languages</label>
        <ng-select [items]="languages" bindLabel="label" bindValue="value" [multiple]="true" [(ngModel)]="accountData.languages" formControlName="languages"></ng-select>
    </div>

    <div class="form-group">
    <label class="form-label">Город</label>
    <input [(ngModel)]="accountData.city" type="text" formControlName="city" class="form-control" value="{{ accountData.city }}">
  </div>

  <div class="form-group">
    <label class="form-label">Телефон</label>
    <input [(ngModel)]="accountData.phone" type="text" formControlName="phone" class="form-control" value="{{ accountData.phone }}">
  </div>

  <div class="form-group">
    <label class="form-label">E-mail</label>

    <input [(ngModel)]="accountData.email" type="email" formControlName="email" class="form-control mb-1" value="{{ accountData.email }}">

    <div class="alert alert-warning mt-3" *ngIf="!accountData.verified">
      <p>Адрес электронной почты не подтвержден</p>
      <a href="javascript:void(0)">Отправить письмо еще раз</a>
    </div>
  </div>

</form>
