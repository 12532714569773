<ui-widget-list [title]="''">

    <ui-widget-tile *ngFor="let widget of widgets$ | async">
        <ui-widget-simple
                [widget]="widget"
                (clickWidget)="openWidget(widget)">
        </ui-widget-simple>
    </ui-widget-tile>

</ui-widget-list>
