<div class="widget-chat">

  <div class="widget-chat__content">

    <div class="widget-chat__small-icon"></div>
    <div class="widget-chat__text">Название чата: Краткое содержание</div>
    <div class="widget-chat__count">16</div>

  </div>

  <div class="widget-chat__title">Чат локации</div>

</div>
