import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Observable,
    of,
} from 'rxjs';
import {
    map,
    tap,
} from 'rxjs/operators';

import {
    FavoriteLocation,
    Folder,
    Location,
    LocationGeo,
    LocationPoint,
    MapBounds,
} from '../../schemas';
import * as utils from '../../utils';
import { Logger } from '../logger.service';


const logger = new Logger('LocationApi');

@Injectable({
    providedIn: 'root',
})
export class LocationApi {

    constructor(
        private http: HttpClient,
    ) { }

    fetchLocations(params: { locationIds?: number[], workTableId?: number }): Observable<Location[]> {
        const endpoint = '/assets/json/locations.json';

        return this.http.get<Location[]>(endpoint).pipe(
            map(utils.convertKeysToCamel),
            map(locations => locations.filter(
                (location: Location) =>
                    (!params.locationIds?.length || params.locationIds?.includes(location.id)) &&
                    (!params.workTableId || location.capsuleId === params.workTableId),
            )),
            tap(locations => logger.debug('fetchLocations (locations)', locations)),
        );
    }

    fetchPointsByLocationIds(locationIds: number[]): Observable<LocationPoint[]> {
        const endpoint = '/assets/json/location-points.json';

        return this.http.get<LocationPoint[]>(endpoint).pipe(
            map(utils.convertKeysToCamel),
            tap(points => logger.debug('fetchPointsByLocationIds (points)', points)),
            map(points => points.filter(
                (point: LocationPoint) => locationIds.includes(point.locationId),
            )),
        );
    }

    fetchPointByLocationId(locationId: number): Observable<LocationPoint> {
        const endpoint = '/assets/json/location-points.json';

        return this.http.get<LocationPoint>(endpoint).pipe(
            map(utils.convertKeysToCamel),
            tap(points => logger.debug('fetchPointByLocationId (points)', points)),
            map(points => {
                for (const point of points) {
                    if (point.locationId === locationId) {
                        return point;
                    }
                }
                return null;
            }),
        );
    }

    fetchGeoByLocationIds(locationIds: number[] = []): Observable<LocationGeo[]> {
        const endpoint = '/assets/json/locations/geo.json';

        return this.http.get<LocationGeo[]>(endpoint).pipe(
            map(utils.convertKeysToCamel),
            tap(items => logger.debug('fetchGeoByLocationIds (items)', items)),
            map(items => {
                return locationIds.length
                    ? items.filter(
                        (item: LocationGeo) => locationIds.includes(item.locationId),
                    )
                    : items;
            }),
        );
    }

    fetchFavoriteLocations(): Observable<{ locations: FavoriteLocation[], folders: Folder[] }> {
        const favoriteLocations: { locations: FavoriteLocation[], folders: Folder[] } = {
            locations: [],
            folders: [],
        };

        if (localStorage.favoriteLocations) {
            favoriteLocations.locations = JSON.parse(localStorage.favoriteLocations);
        }
        if (localStorage.favoriteLocationFolders) {
            favoriteLocations.folders = JSON.parse(localStorage.favoriteLocationFolders);
        }
        return of(favoriteLocations);
    }

    toggleFavoriteLocation(favoriteLocationId: number): Observable<boolean> {

        let favoriteLocations = localStorage.favoriteLocations;

        if (favoriteLocations) {
            favoriteLocations = JSON.parse(favoriteLocations);

            const locationIndex = favoriteLocations.findIndex((item: { locationId: number; }) => item.locationId === favoriteLocationId);

            if (locationIndex > -1) {
                favoriteLocations.splice(locationIndex, 1);
            } else {
                favoriteLocations.push(
                    {
                        locationId: favoriteLocationId,
                        folderId: 0,
                    },
                );
            }
        } else {
            favoriteLocations = [
                {
                    locationId: favoriteLocationId,
                    folderId: 0,
                },
            ];
        }
        localStorage.setItem('favoriteLocations', JSON.stringify(favoriteLocations));
        return of(true);
    }

    fetchFavoriteLocationFolders(): Observable<{ id: number, title: string }[]> {
        let favoriteLocationFolders = [];
        if (localStorage.favoriteLocationFolders) {
            favoriteLocationFolders = JSON.parse(localStorage.favoriteLocationFolders);
        }
        return of(favoriteLocationFolders);
    }

    addFavoriteLocationFolder(locationLeft: number, locationRight: number):
        Observable<{ locations: number[], folderId: number }> {
        let newFolderId = 1;
        const updatedLocations = [];

        let folders = localStorage.favoriteLocationFolders;
        const locations = JSON.parse(localStorage.favoriteLocations);

        if (folders) {
            folders = JSON.parse(folders);

            const latestId = folders[folders.length - 1]?.id;
            newFolderId = latestId ? latestId + 1 : 1;

            folders.push({
                id: newFolderId,
                title: 'Новая группа ' + newFolderId,
            });

        } else {
            folders = [
                {id: newFolderId, title: 'Новая группа'},
            ];
        }

        let location = locations.find((item: { locationId: number; }) => item.locationId === locationLeft);
        location.folderId = newFolderId;
        updatedLocations.push(location.locationId);

        location = locations.find((item: { locationId: number; }) => item.locationId === locationRight);
        location.folderId = newFolderId;
        updatedLocations.push(location.locationId);

        localStorage.setItem('favoriteLocations', JSON.stringify(locations));
        localStorage.setItem('favoriteLocationFolders', JSON.stringify(folders));

        return of({
            locations: updatedLocations,
            folderId: newFolderId,
        });

    }

    updateFolderTitle(folderId: number | undefined, newValue: string | undefined): Observable<Folder> {
        let folders = localStorage.favoriteLocationFolders;

        if (folders) {
            folders = JSON.parse(folders);
            const index = folders.findIndex((item: { id: number; }) => item.id === folderId);
            if (index > -1) {
                folders[index].title = newValue;
            }
            localStorage.setItem('favoriteLocationFolders', JSON.stringify(folders));
        }

        return of(folders);
    }

    ungroupFavoriteLocations(folderId: number | undefined): Observable<boolean> {
        let locations: FavoriteLocation[] = [];
        let folders: Folder[] = [];

        if (localStorage.favoriteLocations) {
            locations = JSON.parse(localStorage.favoriteLocations);
        }
        if (localStorage.favoriteLocationFolders) {
            folders = JSON.parse(localStorage.favoriteLocationFolders);
        }

        const index = folders.findIndex((item: Folder) => item.id === folderId);

        if (index > -1) {
            folders.splice(index, 1);

            for (const location of locations) {
                if (location.folderId === folderId) {
                    location.folderId = 0;
                }
            }

            localStorage.setItem('favoriteLocations', JSON.stringify(locations));
            localStorage.setItem('favoriteLocationFolders', JSON.stringify(folders));
        }

        return of(true);
    }

    deleteFavoriteLocationFromFolder(locationId: number | undefined): Observable<boolean> {
        let locations: FavoriteLocation[] = [];
        let folders: Folder[] = [];
        let folderId = 0;
        let index = -1;

        if (localStorage.favoriteLocations) {
            locations = JSON.parse(localStorage.favoriteLocations);
        }

        if (localStorage.favoriteLocationFolders) {
            folders = JSON.parse(localStorage.favoriteLocationFolders);
        }

        if (locationId) {
            index = locations.findIndex((item: FavoriteLocation) => item.locationId === locationId);
        }

        if (index > -1) {
            folderId = locations[index].folderId;
            locations[index].folderId = 0;

            const updatedLocations = locations.filter((item: FavoriteLocation) => item.folderId === folderId);
            localStorage.setItem('favoriteLocations', JSON.stringify(locations));

            if (!updatedLocations.length) {
                const folderIndex = folders.findIndex((item: Folder) => item.id === folderId);
                folders.splice(folderIndex, 1);
                localStorage.setItem('favoriteLocationFolders', JSON.stringify(folders));
                return of(false);
            }
        }

        return of(true);
    }


    addFavoriteLocationInFolder(folderId: number | undefined, locationId: number | undefined): Observable<boolean> {
        let locations: FavoriteLocation[] = [];

        if (localStorage.favoriteLocations) {
            locations = JSON.parse(localStorage.favoriteLocations);
        }

        if (folderId && locationId) {
            const index = locations.findIndex(item => item.locationId === locationId);
            locations[index].folderId = folderId;
            localStorage.setItem('favoriteLocations', JSON.stringify(locations));
        }

        return of(true);
    }

    fetchBoundedLocationGeoList(bounds: MapBounds): Observable<LocationGeo[]> {
        const endpoint = '/assets/json/locations/geo.json';

        return this.http.get<LocationGeo[]>(endpoint).pipe(
            map(utils.convertKeysToCamel),
            tap(items => logger.debug('fetchBoundedLocationGeoList (items)', items)),
        );
    }

}
