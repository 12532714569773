import { ConnectionsGuard } from '../guards/connections.guard';
import { ConnectionListComponent } from '../modules/connection/connection-list/connection-list.component';
import { HistoryListComponent } from '../modules/history/history-list/history-list.component';


export const connectionsRoutes = [
    {
        path: '',
        pathMatch: 'full',
        component: ConnectionListComponent,
        canActivate: [ConnectionsGuard],
    },
    {
        path: 'history',
        component: HistoryListComponent,
    },
];
