<ui-float-panel [canSwipe]="true" [level]="level" [levels]="[70]" (panelClose)="close()"
                (levelChange)="onLevelChange($event)">
    <div slot="header" class="header">

        <ui-swipe-block (blockSwipe)="onLocationSwipe(location)"
                        [isSwiped]="swipedLocation?.id === location?.id">

            <ui-location-info
                    slot="swipe_layer"
                    (click)="showMore()"
                    [location]="location"
                    [isActive]="location?.id === currentConnectedLocationId">
            </ui-location-info>

            <app-location-icon-structure [location]="location" slot="hidden_layer"></app-location-icon-structure>
            <app-favorite-location [location]="location" slot="hidden_layer"></app-favorite-location>

            <ui-location-close (closeClick)="close()" slot="visible_layer"></ui-location-close>

        </ui-swipe-block>

    </div>
    <div slot="content" *ngIf="canViewContent">

        <ui-card-simple [card]="card"
                        [images]="imagesSubject.asObservable()"
                        [location]="location"
                        (toggleLocationFavorite)="onToggleLocationFavorite($event)">
        </ui-card-simple>


        <app-contact-list-simple></app-contact-list-simple>
        <app-widget-list-simple></app-widget-list-simple>

        <button class="btn btn-active sc-wide" (click)="connect()">Подключиться к локации</button>

    </div>
</ui-float-panel>
