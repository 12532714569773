import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    distinct,
    filter,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { LocationTagList } from '../schemas/location.schemas';
import * as locationActions from '../store/actions/location.actions';
import * as locationSelectors from '../store/selectors/location.selectors';


const logger = new Logger('LocationFacade');

@Injectable({
    providedIn: 'root',
})
export class LocationFacade {

    constructor(
        private store$: Store,
    ) {}

    loadLocations(params: { locationIds?: number[], workTableId?: number }): void {
        this.store$.dispatch(locationActions.loadLocationsAction(params));
    }

    fetchLocation(locationId: number): Observable<schemas.Location | null> {
        this.loadLocations({locationIds: [locationId]});

        return this.store$.pipe(
            select(locationSelectors.selectLocation, {id: locationId}),
            distinct(location => !!location),
            tap(location => logger.debug('fetchLocation (location)', location)),
        );
    }

    fetchLocations(params: { locationIds?: number[], workTableId?: number }): Observable<schemas.Location[]> {
        this.loadLocations(params);

        return this.store$.pipe(
            select(locationSelectors.selectLocations, params),
            distinct(locations => locations.length),
            tap(locations => logger.debug('fetchLocations (locations)', locations)),
        );
    }

    fetchLocationStructure(locationId: number): Observable<schemas.Location[]> {
        return this.fetchLocation(locationId).pipe(
            filter((location: schemas.Location | null) => !!location),
            tap(location => logger.debug('fetchLocationStructure (location)', location)),
            map(location => {
                return (location as schemas.Location).path.split(',').map(Number) || [];
            }),
            switchMap((locationIds: number[]) => this.fetchLocations({locationIds})),
            filter(locations => locations.length > 0),
            tap(locations => logger.debug('fetchLocationStructure (locations)', locations)),
        );
    }

    loadLocationsTags(locationIds: number[]): void {
        this.store$.dispatch(locationActions.loadLocationsTagsAction({locationIds}));
    }

    fetchLocationTags(locationId: number): Observable<schemas.LocationTag[]> {
        this.loadLocationsTags([locationId]);

        return this.store$.pipe(
            select(locationSelectors.selectLocationTags, {locationId}),
            distinct(locationTags => locationTags.length),
            tap(locationTags => logger.debug('fetchLocationTags (locationTags)', locationTags)),
        );
    }

    fetchLocationsTags(locationIds: number[]): Observable<schemas.LocationTag[]> {
        this.loadLocationsTags(locationIds);

        return this.store$.pipe(
            select(locationSelectors.selectLocationsTags, {locationIds}),
            distinct(locationTags => locationTags.length),
            tap(locationTags => logger.debug('fetchLocationsTags (locationTags)', locationTags)),
        );
    }

    loadTags(tagIds: number[]): void {
        this.store$.dispatch(locationActions.loadTagsAction({tagIds}));
    }

    fetchTags(tagIds: number[]): Observable<schemas.Tag[]> {
        this.loadTags(tagIds);

        return this.store$.pipe(
            select(locationSelectors.selectTags, {tagIds}),
            distinct(tags => tags.length),
            tap(tags => logger.debug('fetchTags (tags)', tags)),
        );
    }

    fetchTag(tagId: number): Observable<schemas.Tag | null> {
        this.loadTags([tagId]);

        return this.store$.pipe(
            select(locationSelectors.selectTag, {tagId}),
            distinct(tag => !!tag),
            tap(tag => logger.debug('fetchTag (tag)', tag)),
        );
    }

    fetchTagsByLocationIds(locationIds: number[]): Observable<schemas.Tag[]> {
        return this.fetchLocationsTags(locationIds).pipe(
            filter(locationTags => locationTags.length > 0),
            tap(locationTags => logger.debug('fetchTagsByLocationIds (locationTags)', locationTags)),
            map((locationTags: schemas.LocationTag[]) => {
                return locationTags.map(locationTag => locationTag.tagId);
            }),
            switchMap((tagIds: number[]) => this.fetchTags(tagIds)),
            filter(tags => tags.length > 0),
            tap(tags => logger.debug('fetchTagsByLocationIds (tags)', tags)),
        );
    }

    fetchLocationsTagList(locationIds: number[]): Observable<LocationTagList> {
        return this.fetchLocationsTags(locationIds).pipe(
            filter(locationTags => locationTags.length > 0),
            tap(locationTags => logger.debug('fetchLocationsTagList (locationTags)', locationTags)),
            switchMap((locationTags: schemas.LocationTag[]) => {
                const tagIds: number[] = locationTags.map(locationTag => locationTag.tagId);

                return this.fetchTags(tagIds).pipe(
                    filter(tags => tags.length > 0),
                    tap(tags => logger.debug('fetchLocationsTagList (tags)', tags)),
                    map((tags: schemas.Tag[]) => {
                        const locationTagList: LocationTagList = {};

                        for (const tag of tags) {
                            locationTags.forEach(locationTag => {
                                if (locationTag.tagId === tag.id) {
                                    const locationId = locationTag.locationId;
                                    if (!locationTagList[locationId]) {
                                        locationTagList[locationId] = [];
                                    }
                                    locationTagList[locationId].push(tag);
                                }
                            });
                        }

                        return locationTagList;
                    }),
                );
            }),
        );
    }

    loadLocationsGeos(locationIds: number[]): void {
        this.store$.dispatch(locationActions.loadLocationsGeosAction({locationIds}));
    }

    fetchLocationsGeos(locationIds: number[]): Observable<schemas.LocationGeo[]> {
        this.loadLocationsGeos(locationIds);

        return this.store$.pipe(
            select(locationSelectors.selectLocationsGeos, {locationIds}),
            distinct(locationGeos => locationGeos.length),
            tap(locationGeos => logger.debug('fetchLocationsGeos (locationGeos)', locationGeos)),
        );
    }

    fetchLocationsGeosByWorkTableId(workTableId: number): Observable<schemas.LocationGeo[]> {
        return this.fetchLocations({workTableId}).pipe(
            filter(locations => locations.length > 0),
            tap(locations => logger.debug('fetchLocationsGeosByWorkTableId (locations)', locations)),
            map(locations => locations.map(location => location.id)),
            switchMap((locationIds: number[]) => this.fetchLocationsGeos(locationIds)),
            distinct(locationGeos => locationGeos.length),
            tap(locationGeos => logger.debug('fetchLocationsGeosByWorkTableId (locationGeos)', locationGeos)),
        );
    }

    loadWorkTables(workTableIds: number[]): void {
        this.store$.dispatch(locationActions.loadWorkTablesAction({
            workTableIds,
        }));
    }

    fetchWorkTable(workTableId: number): Observable<schemas.Capsule | null> {
        this.loadWorkTables([workTableId]);

        return this.store$.pipe(
            select(locationSelectors.selectWorkTable, {workTableId}),
            distinct(workTable => !!workTable),
            tap(workTable => logger.debug('fetchWorkTable (workTable)', workTable)),
        );
    }

    fetchWorkTables(workTableIds: number[]): Observable<schemas.Capsule[]> {
        this.loadWorkTables(workTableIds);

        return this.store$.pipe(
            select(locationSelectors.selectWorkTables, {workTableIds}),
            distinct(workTables => workTables.length),
            tap(workTables => logger.debug('fetchWorkTables (workTables)', workTables)),
        );
    }

    loadWorkTablesWidgets(workTableIds: number[]): void {
        this.store$.dispatch(locationActions.loadWorkTableWidgetsAction({
            workTableIds,
        }));
    }

    fetchWorkTablesWidgets(workTableIds: number[]): Observable<schemas.CapsuleWidget[]> {
        this.loadWorkTablesWidgets(workTableIds);

        return this.store$.pipe(
            select(locationSelectors.selectWorkTableWidgets, {workTableIds}),
            distinct(workTableWidgets => workTableWidgets.length),
            tap(workTableWidgets => logger.debug('fetchWorkTablesWidgets (workTableWidgets)', workTableWidgets)),
        );
    }

}
