<div class="location-slim-list">

    <ui-swipe-block *ngFor="let location of locations"
                    [isSwiped]="swipedLocation?.id === location?.id"
                    (blockSwipe)="onLocationSwipe(location)">

        <ui-location-info
                slot="swipe_layer"
                [location]="location"
                (click)="onLocationClick(location)"
                [isActive]="location?.id === currentConnectedLocationId">
        </ui-location-info>

        <app-location-icon-structure [location]="location" slot="hidden_layer"></app-location-icon-structure>
        <app-favorite-location [location]="location" slot="visible_layer"></app-favorite-location>

    </ui-swipe-block>

</div>
