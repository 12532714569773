import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import {
    filter,
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { MapFacade } from '../../../facades/map.facade';
import { MapService } from '../../../services/map/map.service';


const logger = new Logger('Example:Example');

@Component({
    selector: 'app-map-default',
    templateUrl: './map-default.component.html',
    styleUrls: ['./map-default.component.scss'],
})
export class MapDefaultComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    selectedLocationId: number | undefined;
    selectedLocationIds: number[] = [];

    constructor(
        private mapFacade: MapFacade,
        private mapService: MapService,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.mapService.bounds$.subscribe(this.onBoundsChange.bind(this)),
            this.mapService.pointClick$.subscribe(this.onPointClick.bind(this)),
            this.mapService.clusterClick$.subscribe(this.onClusterClick.bind(this)),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
        this.mapService.reset();
    }

    private onBoundsChange(bounds?: schemas.MapBounds): void {
        logger.debug('onBoundsChange', bounds);

        if (bounds) {
            this.fetchBoundedLocations(bounds);
        }
    }

    private onPointClick(event: schemas.MapMouseEvent): void {
        logger.debug('onPointClick', event);

        this.selectedLocationId = undefined;
        this.selectedLocationIds = [];
        this.selectedLocationId = event.feature?.geometry.type === 'Point'
            ? event.feature?.properties?.locationId
            : undefined;
    }

    private onClusterClick(event: schemas.MapMouseEvent): void {
        logger.debug('onClusterClick', event);

        this.selectedLocationId = undefined;
        this.selectedLocationIds = [];
        this.selectedLocationIds = event.features?.map(feature => {
            return feature?.geometry.type === 'Point'
                ? feature.properties?.locationId
                : undefined;
        }).filter(x => x) as number[];
    }

    private fetchBoundedLocations(bounds: schemas.MapBounds): void {
        this.mapFacade.fetchLocationGeoFeatureCollection(bounds).pipe(
            tap(featureCollection => logger.debug('fetchBoundsLocations, featureCollection', featureCollection)),
            switchMap(featureCollection => this.mapService.isLoaded$.pipe(
                tap(isLoaded => logger.debug('fetchBoundsLocations, isLoaded', isLoaded)),
                filter(isLoaded => isLoaded),
                map(() => featureCollection),
            )),
            first(),
        ).subscribe(featureCollection => {
            this.mapService.showGeoJSONCluster(featureCollection);
        });
    }

    onCloseLocationInfo(): void {
        logger.debug('onCloseLocationInfo');

        this.selectedLocationId = undefined;
    }

    onCloseLocationList(): void {
        logger.debug('onCloseLocationList');

        this.selectedLocationIds = [];
    }

    onLocationClick(location: schemas.Location): void {
        logger.debug('onLocationClick');

        this.selectedLocationId = location.id;
    }

}
