import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit {

    @Input() tag: schemas.Tag | undefined;
    @Input() isSelected: boolean | undefined;
    @Output() tagSelect = new EventEmitter<schemas.Tag>();

    onTagSelect(): void {
        this.tagSelect.emit(this.tag);
    }

    ngOnInit(): void {
    }
}
