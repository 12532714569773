<form>

  <div class="form-group">
    <label class="form-label">Текущий пароль</label>
    <input type="password" class="form-control">
  </div>

  <div class="form-group">
    <label class="form-label">Новый пароль</label>
    <input type="password" class="form-control">
  </div>

  <div class="form-group">
    <label class="form-label">Подтверждение пароля</label>
    <input type="password" class="form-control">
  </div>

</form>
