<swiper class="swiper-container" [config]="config">

    <div *ngFor="let item of images" class="swiper-slide">

        <div (click)="onClick(item)" class="swiper-slide__item">
            <div [style.background-image]="'url(' + item?.image +')'" class="swiper-slide__content"
                 [ngStyle]="{ height: '{{ height }} px' }"></div>
            <div>{{ item?.title }}</div>
        </div>

    </div>

</swiper>
