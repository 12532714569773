<div class="sc-auth">

  <ui-logo></ui-logo>

  <ui-form-phone-form
    [cannotSubmit]="isLoading$ | async"
    [formError]="error$ | async"
    (formSubmit)="onFormSubmit($event)">
  </ui-form-phone-form>

</div>

