<div class="child-container">
    <div class="tabs">
        <div class="tab"><a routerLink="/favorites/locations" class="tab__link">Избранное</a></div>
        <div class="tab tab--active">История</div>
    </div>

    <div class="history">

        <div *ngFor="let result of history">

            <div class="history__title">{{ result.date }}</div>

            <div *ngFor="let item of result.items">

                <ui-swipe-block (blockSwipe)="onLocationSwipe(item.location)"
                                [isSwiped]="swipedLocation?.id === item.location?.id">

                    <ui-location-info
                            slot="swipe_layer"
                            [location]="item.location"
                            (locationConnect)="onLocationConnect(item.location)"
                            [isActive]="item.location?.id === currentConnectedLocationId">
                    </ui-location-info>

                    <app-location-icon-structure [location]="item.location"
                                                 slot="hidden_layer"></app-location-icon-structure>
                    <app-favorite-location [location]="item.location" slot="visible_layer"></app-favorite-location>

                </ui-swipe-block>

            </div>

        </div>

        <div *ngIf="history.length < 1" class="history__temporary">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M32 58.6667C46.7276 58.6667 58.6667 46.7276 58.6667 32C58.6667 17.2724 46.7276 5.33333 32 5.33333C17.2724 5.33333 5.33337 17.2724 5.33337 32C5.33337 46.7276 17.2724 58.6667 32 58.6667Z"
                      stroke="inherit" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M32 16V32L40 40" stroke="inherit" stroke-width="3" stroke-linecap="square"
                      stroke-linejoin="round"/>
            </svg>

            <div>
                Здесь будет находиться история посещений локаций.
            </div>
        </div>

    </div>
</div>

