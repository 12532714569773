import {
    AfterViewInit,
    Component,
    OnDestroy,
} from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutFacade } from '../../facades/layout.facade';
import { LayoutService } from '../layout.service';


@Component({
    selector: 'app-layout-vendor-style',
    templateUrl: './layout-vendor-style.component.html',
    styles: [':host { display: block; height: 100%; }', ':host ::ng-deep .layout-loading .sidenav-link { transition: none !important; }'],
    styleUrls: ['./layout-vendor-style.component.scss'],
})
export class LayoutVendorStyleComponent implements AfterViewInit, OnDestroy {

    public initialized = false;
    ios = false;

    workTableStyleTitle$: Observable<string | undefined> = this.layoutFacade.workTableStyleTitle$;
    workTableStyleBackground$: Observable<string | undefined> = this.layoutFacade.workTableStyleBackground$;

    constructor(private layoutService: LayoutService,
                private layoutFacade: LayoutFacade) {

        if (window.matchMedia('(display-mode: standalone)').matches && this.isIos()) {
            this.ios = this.isIos();
        }
    }

    isIos(): boolean {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.initialized = true;

            this.layoutService.init();
            this.layoutService.update();
            this.layoutService.setAutoUpdate(true);
        });

    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.layoutService.destroy();
        });
    }

    closeSidenav(): void {
        setTimeout(() => {
            this.layoutService.setCollapsed(true);
        });
    }
}
