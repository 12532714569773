import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import {
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { ConnectionFacade } from '../../../facades/connection.facade';
import { LocationFacade } from '../../../facades/location.facade';
import { Location } from '../../../schemas/location.schemas';


const logger = new Logger('LocationStructureComponent');

@Component({
    selector: 'app-location-structure',
    templateUrl: './location-structure.component.html',
    styleUrls: ['./location-structure.component.scss'],
})
export class LocationStructureComponent implements OnInit, OnDestroy {

    locations: Location[] = [];
    swipedLocation?: Location;
    connectedLocationsIds: number[] = [];
    currentConnectedLocationId?: number;

    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private locationFacade: LocationFacade,
        private connectionFacade: ConnectionFacade,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.paramMap.pipe(
                tap(params => logger.debug('params', params, this.route)),
                map(params => Number(params.get('id'))),
                tap(id => logger.debug('route(id)', id)),
                switchMap(id => {
                    return this.locationFacade.fetchLocationStructure(id);
                }),
            ).subscribe(data => this.locations = data),
        );

        this.subscriptions.push(
            this.connectionFacade.currentLocationId$.subscribe(locationId => {
                this.currentConnectedLocationId = locationId;
            }),
        );

        this.subscriptions.push(
            this.connectionFacade.connectedLocationsIds$.subscribe(locationIds => {
                this.connectedLocationsIds = locationIds;
            }),
        );
    }

    onLocationConnect(location: Location): void {
        if (location) {
            this.connectionFacade.connect(location);
        }
    }

    onLocationSwipe(location: Location): void {
        if (location) {
            this.swipedLocation = location;
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }
}
