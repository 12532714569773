import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { schemas } from '@scatch/ngx-app-lib';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-form-phone-code-form',
    templateUrl: './form-phone-code-form.component.html',
    styleUrls: ['./form-phone-code-form.component.scss'],
})
export class FormPhoneCodeFormComponent implements OnInit {

    codeForm: FormGroup;

    @Input() phone: string | null | undefined;
    @Input() cannotResendCode: boolean | null | undefined;
    @Input() codeResendCountdown: number | null | undefined;
    @Input() formError: schemas.ResponseError | null = null;
    @Input() cannotSubmit: boolean | null = null;

    @Output() formSubmit = new EventEmitter();
    @Output() resendCode = new EventEmitter();

    constructor() {

        this.codeForm = new FormGroup({

            code: new FormControl('', [
                Validators.required,
                Validators.pattern(/^(\d{4}|)$/),
            ]),

        });

    }

    ngOnInit(): void {
    }

    onFormChange(): void {
        this.formError = null;

        if (this.codeForm.valid) {
            document.getElementById('form-phone-code')?.blur();
            this.onSubmit();
        }
    }

    onSubmit(): void {
        this.formSubmit.emit(this.codeForm.value);
    }

    onResendCode(): void {
        this.resendCode.emit();
    }

}
