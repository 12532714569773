import { Routes } from '@angular/router';
import { WidgetsGuard } from '../guards/widgets.guard';
import { LayoutIterraWithoutNavbarComponent } from '../layout/layout-iterra-without-navbar/layout-iterra-without-navbar.component';
import { LayoutVendorStyleComponent } from '../layout/layout-vendor-style/layout-vendor-style.component';
import { WidgetFrameComponent } from '../modules/widget/widget-frame/widget-frame.component';
import { WidgetListComponent } from '../modules/widget/widget-list/widget-list.component';


export const widgetsRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: LayoutVendorStyleComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: WidgetListComponent,
                canActivate: [WidgetsGuard],
            },
        ],
    },
    {
        path: 'frame',
        component: LayoutIterraWithoutNavbarComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: WidgetFrameComponent,
            },
        ],
    },
    {
        path: '',
        component: LayoutIterraWithoutNavbarComponent,
        children: [
            {
                path: 'example',
                loadChildren: () => import('../widgets/example/example-widget.module')
                    .then(m => m.ExampleWidgetModule),
            },
        ],
    },
];
