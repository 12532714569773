import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    BehaviorSubject,
    Observable,
} from 'rxjs';
import * as layoutSelectors from '../store/selectors/layout.selectors';


@Injectable({
    providedIn: 'root',
})
export class LayoutFacade {

    private workTableStyleTitleSubject = new BehaviorSubject<string>('');
    private workTableStyleTitle = '';

    private workTableStyleBackgroundSubject = new BehaviorSubject<string>('');
    private workTableStyleBackground = '';

    constructor(
        private store$: Store,
    ) {}

    get workTableStyleTitle$(): Observable<string | undefined> {
        return this.store$.pipe(
            select(layoutSelectors.selectWorkTableStyleTitle),
        );
    }

    get workTableStyleBackground$(): Observable<string | undefined> {
        return this.store$.pipe(
            select(layoutSelectors.selectWorkTableStyleBackground),
        );
    }

}
