<nav class="footer" [ngClass]="currentBg()">
    <div class="container-fluid">

        <a routerLink="/notification/list" class="footer-link"
           [class.footer-link--active]="activeRouteUrl === '/notification/list'">

            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M28.5 15C28.5 8.91128 25.1424 4.6853 20.7981 3.41661C20.6408 3.00957 20.3922 2.63116 20.0542 2.31365C18.8996 1.22878 17.1004 1.22878 15.9458 2.31365C15.6086 2.63046 15.3603 3.0079 15.203 3.41392C10.853 4.67708 7.5 8.89383 7.5 15C7.5 18.9509 6.70347 21.1647 5.22894 22.769C4.9824 23.0373 4.31443 23.6517 3.86197 24.068C3.64791 24.2649 3.48209 24.4174 3.43195 24.468L3 24.906V30.0212H12.132C12.4408 31.6142 13.3052 32.68 14.8683 33.6339C16.7912 34.8073 19.2088 34.8073 21.1317 33.6339C22.5112 32.7921 23.4362 31.4983 23.8115 30.0212H33V24.906L32.5681 24.468C32.5226 24.4218 32.3635 24.2751 32.1555 24.0832C31.7044 23.6671 31.0234 23.0389 30.7731 22.7654C29.2975 21.1536 28.5 18.937 28.5 15ZM20.6051 30.0212H15.2679C15.4799 30.4154 15.8411 30.7131 16.431 31.0731C17.3944 31.661 18.6056 31.661 19.569 31.0731C20.0151 30.8008 20.3637 30.4406 20.6051 30.0212ZM28.5603 24.7911C28.8759 25.1359 29.5524 25.7662 30 26.1779V27.0212H6V26.1786C6.44411 25.7717 7.12265 25.1419 7.43773 24.7991C9.44133 22.6191 10.5 19.6767 10.5 15C10.5 9.32988 13.9992 6.01861 18 6.01861C21.9896 6.01861 25.5 9.34697 25.5 15C25.5 19.6617 26.559 22.605 28.5603 24.7911Z"
                      fill="inherit"/>
            </svg>

        </a>

        <a routerLink="/map" class="footer-link" [class.footer-link--active]="activeRouteUrl === '/map'">

            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M3 29.5827L13.6619 33.1367L24.1154 27.1632L33 30.7171V6.48599L23.8846 2.83984L13.3381 8.86644L3 5.4204V29.5827ZM21 25.4883L15 28.9169V11.3721L21 7.94357V25.4883ZM24 23.8861L30 26.2861V8.5172L24.1154 6.16335L24 6.22928V23.8861ZM6 27.4205L12 29.4205V11.5828L6 9.58279V27.4205Z"
                      fill="inherit"/>
            </svg>

        </a>

        <a routerLink="/scanner/demo" class="footer-link" [class.footer-link--active]="activeRouteUrl === '/scanner/demo'">

            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M24 3H30.2727C31.779 3 33 4.34315 33 6V12H30V6H24V3ZM3 19.5V16.5H33V19.5H3ZM24 33V30H30V24H33V30C33 31.6569 31.779 33 30.2727 33H24ZM5.72727 33H12V30H6V24H3V30C3 31.6569 4.22104 33 5.72727 33ZM12 6V3H5.72727C4.22104 3 3 4.34315 3 6V12H6V6H12Z"
                      fill="inherit"/>
            </svg>

        </a>

        <a routerLink="/widgets" class="footer-link" [class.footer-link--active]="activeRouteUrl === '/widgets'">

            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M30 33L22.5 33C20.8431 33 19.5 31.6569 19.5 30L19.5 22.5C19.5 20.8431 20.8431 19.5 22.5 19.5L30 19.5C31.6569 19.5 33 20.8431 33 22.5L33 30C33 31.6569 31.6569 33 30 33ZM30 16.5L22.5 16.5C20.8431 16.5 19.5 15.1569 19.5 13.5L19.5 6C19.5 4.34315 20.8431 3 22.5 3L30 3C31.6569 3 33 4.34315 33 6L33 13.5C33 15.1569 31.6569 16.5 30 16.5ZM9 16.5L12 16.5L12 12L16.5 12L16.5 9L12 9L12 4.5L9 4.5L9 9L4.5 9L4.5 12L9 12L9 16.5ZM30 6L30 13.5L22.5 13.5L22.5 6L30 6ZM30 22.5L30 30L22.5 30L22.5 22.5L30 22.5ZM13.5 33L6 33C4.34315 33 3 31.6569 3 30L3 22.5C3 20.8431 4.34314 19.5 6 19.5L13.5 19.5C15.1569 19.5 16.5 20.8431 16.5 22.5L16.5 30C16.5 31.6569 15.1569 33 13.5 33ZM13.5 22.5L13.5 30L6 30L6 22.5L13.5 22.5Z"
                      fill="inherit"/>
            </svg>

        </a>

        <a routerLink="/favorites/locations" class="footer-link" [class.footer-link--active]="activeRouteUrl === '/favorites/locations'">

            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7.19008 34.376L17.9991 28.6934L28.808 34.376L26.7437 22.34L35.4883 13.8161L23.4035 12.0601L17.9991 1.10938L12.5946 12.0601L0.509766 13.8161L9.25441 22.34L7.19008 34.376ZM24.8236 28.8919L17.999 25.304L11.1744 28.8919L12.4778 21.2926L6.95661 15.9108L14.5867 14.8021L17.999 7.888L21.4113 14.8021L29.0414 15.9108L23.5202 21.2926L24.8236 28.8919Z"
                      fill="inherit"/>
            </svg>
        </a>


    </div>
</nav>

