import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    selector: 'app-widget-audio-player',
    templateUrl: './widget-audio-player.component.html',
    styleUrls: ['./widget-audio-player.component.scss'],
})
export class WidgetAudioPlayerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
