import {
    Component,
    OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';

import { NotificationsFacade } from '../../../facades/notifications.facade';
import { Notification } from '../../../schemas/notification.schemas';


@Component({
    selector: 'app-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {

    notifications$: Observable<Array<Notification>> = this.notificationsFacade.locations$;

    constructor(private notificationsFacade: NotificationsFacade) { }

    ngOnInit(): void {
        this.notificationsFacade.loadNotifications();
    }

}
