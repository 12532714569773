import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { CardsUiModule } from '../../ui/cards/cards.module';


@NgModule({
    declarations: [
        CardComponent,
    ],
    imports: [
        CommonModule,
        CardsUiModule,
    ],
    exports: [
        CardComponent,
    ],
})
export class CardsModule {}
