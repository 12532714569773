<div class="sc-auth">

  <ui-logo></ui-logo>

  <ui-auth-phone-signup-step-first-form
    [cannotSubmit]="isLoading$ | async"
    [formError]="error$ | async"
    (formSubmit)="onFormSubmit($event)">
  </ui-auth-phone-signup-step-first-form>

</div>
