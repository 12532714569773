import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    Logger,
    schemas,
    utils,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    catchError,
    first,
    map,
    switchMap,
} from 'rxjs/operators';

import { DemoService } from '../../services/demo.service';
import * as connectionActions from '../actions/connection.actions';
import * as scannerActions from '../actions/scanner.actions';


const log = new Logger('ScannerEffects');

@Injectable()
export class ScannerEffects {

    constructor(
        private actions$: Actions,
        private demoService: DemoService,
    ) { }

    loadDemoCodesEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(scannerActions.loadDemoCodesAction),
            switchMap(() => this.demoService.getDemoCodes().pipe(
                map((demoCodes: schemas.DemoCode[]) =>
                    scannerActions.loadDemoCodesSuccessAction({
                        demoCodes,
                    }),
                ),
                catchError(response => of(
                    scannerActions.loadDemoCodesFailureAction({
                        error: utils.formatErrors(response),
                    }),
                )),
                first(),
            )),
        ),
    );

    parseDataEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(scannerActions.parseDataAction),
            map(({data}) =>
                connectionActions.connectAction({
                    token: data,
                }),
            ),
        ),
    );

}
