import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import { Location } from '../../schemas/location.schemas';
import {
    ConnectionReducerState,
    DEFAULT_CONNECTION_FEATURE_NAME,
} from '../reducers/connection.reducer';
import * as locationSelectors from './location.selectors';


const selectConnection = createFeatureSelector<ConnectionReducerState>(DEFAULT_CONNECTION_FEATURE_NAME);

export const selectConnections = createSelector(
    selectConnection,
    state => state.connections,
);

export const selectConnectedLocationsIds = createSelector(
    selectConnections,
    connections => connections.map(connection => connection.location.id),
);

export const selectConnectedLocationsTree = createSelector(
    selectConnections,
    locationSelectors.selectLocationEntities,
    (connections, locationEntities) => {
        const ids: number[] = [];

        for (const connection of connections) {
            const locationIds = connection.location.path.split(',').map(Number);

            let lastIndex = ids.length;

            for (const locationId of locationIds) {
                const index = ids.indexOf(locationId);

                if (index === -1) {
                    ids.splice(++lastIndex, 0, locationId);
                } else {
                    lastIndex = index;
                }
            }
        }

        const locations: Location[] = [];

        for (const id of ids) {
            const location = locationEntities[id];

            if (location) {
                locations.push(location);
            }
        }

        return locations;
    },
);

export const selectConnectedLocations = createSelector(
    selectConnections,
    locationSelectors.selectLocationEntities,
    (connections, locationEntities) => {

        const locations: schemas.Location[] = [];

        for (const connection of connections) {
            const location = locationEntities[connection.location.id];

            if (location) {
                locations.push(location);
            }
        }

        return locations;
    },
);

export const selectAllLocationsIds = createSelector(
    selectConnections,
    connections => {
        const ids: number[] = [];
        for (const connection of connections) {
            const parts = connection.location.path.split(',');
            for (const part of parts) {
                const id = Number(part);
                if (!ids.includes(id)) {
                    ids.push(id);
                }
            }
        }
        return ids;
    },
);

export const selectCurrentConnection = createSelector(
    selectConnection,
    state => state.currentConnection,
);

export const selectIsConnecting = createSelector(
    selectConnection,
    state => state.isConnecting,
);

export const selectIsConnected = createSelector(
    selectCurrentConnection,
    currentConnection => !!currentConnection,
);

export const selectError = createSelector(
    selectConnection,
    state => state.error,
);
