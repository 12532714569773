<div class="tabs">
    <div class="tab tab--active">Избранное</div>
    <div class="tab"><a routerLink="/connections/history" class="tab__link">История</a></div>
</div>

<div class="location-list">

    <div *ngFor="let location of locations">

        <ui-swipe-block (blockSwipe)="onLocationSwipe(location)"
                        [isSwiped]="swipedLocation?.id === location?.id">

            <ui-location-info
                    slot="swipe_layer"
                    [location]="location"
                    (locationConnect)="onLocationConnect(location)"
                    [isActive]="location?.id === currentConnectedLocationId">
            </ui-location-info>

            <app-location-icon-structure [location]="location" slot="hidden_layer"></app-location-icon-structure>
            <app-favorite-location [location]="location" slot="visible_layer"></app-favorite-location>

        </ui-swipe-block>

    </div>

    <div *ngIf="locations.length < 1 && folders.length < 1" class="location-list__temporary">
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="59" viewBox="0 0 64 59" fill="none">
            <path d="M32 3.34809L40.3574 20.0255L40.709 20.7272L41.486 20.8383L60.1262 23.5059L46.6536 36.4394L46.0777 36.9923L46.2147 37.7789L49.4001 56.07L32.6897 47.418L32 47.0609L31.3103 47.418L14.5999 56.07L17.7853 37.7789L17.9223 36.9923L17.3464 36.4394L3.87378 23.5059L22.514 20.8383L23.291 20.7272L23.6426 20.0255L32 3.34809Z"
                  stroke="inherit" stroke-width="3"/>
        </svg>
        <div>
            Вы можете добавить локации в избранное, нажав на звёздочку, чтобы вернуться к ним в любое удобное время.
        </div>
    </div>
</div>

<!--механизм с папками, временно закомментирован-->
<!--    <div cdkDropList cdkDropListDisabled-->
<!--         (cdkDropListDropped)="drop($event)"-->
<!--         #folderList="cdkDropList"-->
<!--         [cdkDropListData]="folders"-->
<!--         [cdkDropListConnectedTo]=[capsuleList]>-->

<!--        <div *ngFor="let folder of folders">-->
<!--            <ui-location-folder [title]="folder?.title" [routerLink]="['/capsule/folder', folder?.id]"-->
<!--                                cdkDrag></ui-location-folder>-->
<!--        </div>-->

<!--    </div>-->

<!--    <div cdkDropList-->
<!--         #capsuleList="cdkDropList"-->
<!--         [cdkDropListData]="locations"-->
<!--         [cdkDropListConnectedTo]="[folderList]">-->

<!--        <div *ngFor="let location of locations">-->

<!--            <ui-location-swipe cdkDrag-->
<!--                               cdkDragLockAxis='y'-->
<!--                               (cdkDragDropped)="dropCapsule($event)"-->
<!--                               [location]="location"-->
<!--                               [isSwiped]="swipedLocation?.id === location?.id"-->
<!--                               (connectLocation)="onLocationConnect($event)"-->
<!--                               (locationSwipe)="onLocationSwipe($event)">-->

<!--                <app-location-icon-structure [location]="location" slot="hidden_layer"></app-location-icon-structure>-->
<!--                <app-favorite-location [location]="location" slot="visible_layer"></app-favorite-location>-->

<!--            </ui-location-swipe>-->

<!--        </div>-->

<!--    </div>-->

