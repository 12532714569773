<div class="list">

    <div class="list__title d-flex align-items-center justify-content-between">
        {{ list?.title }}

        <ng-template [ngIf]="list?.linkTitle">
            <span class="list__link d-flex align-items-center">
                {{ list?.linkTitle }}
                <img src="assets/images/contacts/chevron-right.svg" alt="chevron">
            </span>
        </ng-template>

    </div>

    <div class="list__items">
        <ng-content></ng-content>
    </div>

</div>
