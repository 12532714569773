import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    Logger,
    utils,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    catchError,
    first,
    map,
    switchMap,
} from 'rxjs/operators';

import { Authorization } from '../../schemas/auth.schemas';
import { AuthPhoneApi } from '../../services/api/auth/auth-phone.api';
import { DeviceAuthApi } from '../../services/api/auth/device-auth.api';
import * as authActions from '../actions/auth.actions';
import * as passwordActions from '../actions/password.actions';


const logger = new Logger('AuthEffects');

@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private authPhoneApi: AuthPhoneApi,
        private deviceAuthApi: DeviceAuthApi,
    ) {}

    signUpEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signUpByPhoneCodeAction,
            ),
            map(() => authActions.signUpAction()),
        ),
    );

    signUpSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signUpByPhoneCodeSuccessAction,
            ),
            map(({authorization}) => authActions.signUpSuccessAction({authorization})),
        ),
    );

    signUpFailureEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signUpByPhoneCodeFailureAction,
            ),
            map(({error}) => authActions.signUpFailureAction({error})),
        ),
    );

    signInEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInByDeviceAction,
                authActions.signInByPhoneCodeAction,
                authActions.signInByPhoneCredentialsAction,
                authActions.signInByPasswordResetCodeAction,
                passwordActions.resetAction,
            ),
            map(() => authActions.signInAction()),
        ),
    );

    signInSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInByDeviceSuccessAction,
                authActions.signInByPhoneCodeSuccessAction,
                authActions.signInByPhoneCredentialsSuccessAction,
                authActions.signInByPasswordResetCodeSuccessAction,
                passwordActions.resetSuccessAction,
            ),
            map(({authorization}) => authActions.signInSuccessAction({authorization})),
        ),
    );

    signInFailureEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                authActions.signInByDeviceFailureAction,
                authActions.signInByPhoneCodeFailureAction,
                authActions.signInByPhoneCredentialsFailureAction,
                authActions.signInByPasswordResetCodeFailureAction,
                passwordActions.resetFailureAction,
            ),
            map(({error}) => authActions.signInFailureAction({error})),
        ),
    );

    signUpByPhoneCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signUpByPhoneCodeAction),
            switchMap(({phoneCode}) =>
                this.authPhoneApi.authorizeByPhoneCode(phoneCode).pipe(
                    map((authorization: Authorization) =>
                        authActions.signUpByPhoneCodeSuccessAction({authorization})),
                    catchError(response => of(authActions.signUpByPhoneCodeFailureAction({
                        error: utils.formatErrors(response),
                    }))),
                    first(),
                ),
            ),
        ),
    );

    signInByPhoneCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPhoneCodeAction),
            switchMap(({phoneCode}) =>
                this.authPhoneApi.authorizeByPhoneCode(phoneCode).pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByPhoneCodeSuccessAction({authorization})),
                    catchError(response => of(authActions.signInByPhoneCodeFailureAction({
                        error: utils.formatErrors(response),
                    }))),
                    first(),
                ),
            ),
        ),
    );

    signInByPhoneCredentialsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPhoneCredentialsAction),
            switchMap(({credentials}) =>
                this.authPhoneApi.authorize(credentials).pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByPhoneCredentialsSuccessAction({authorization})),
                    catchError(response => of(authActions.signInByPhoneCredentialsFailureAction({
                        error: utils.formatErrors(response),
                    }))),
                    first(),
                ),
            ),
        ),
    );

    signInByDeviceEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByDeviceAction),
            switchMap(() =>
                this.deviceAuthApi.authorize().pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByDeviceSuccessAction({
                            authorization,
                        }),
                    ),
                    catchError(response =>
                        of(authActions.signInByDeviceFailureAction({
                            error: utils.formatErrors(response),
                        })),
                    ),
                    first(),
                ),
            ),
        ),
    );

    signInByPasswordResetCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signInByPasswordResetCodeAction),
            switchMap(({phoneCode}) =>
                this.authPhoneApi.authorizeByPhoneCode(phoneCode).pipe(
                    map((authorization: Authorization) =>
                        authActions.signInByPasswordResetCodeSuccessAction({authorization})),
                    catchError(response => of(authActions.signInByPasswordResetCodeFailureAction({
                        error: utils.formatErrors(response),
                    }))),
                    first(),
                ),
            ),
        ),
    );

    signOutEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.signOutAction),
            map(() => authActions.signOutSuccessAction()),
            catchError(() => of(authActions.signOutFailureAction())),
        ),
    );

}
