import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';


const log = new Logger('ConnectionService');

@Injectable({
    providedIn: 'root',
})
export class ConnectionService {

    constructor(
        private http: HttpClient,
    ) { }

    connect(token: string): Observable<schemas.Connection> {
        const endpoint = `/assets/json/connections/${token}.json`;

        return this.http.get<schemas.Connection>(endpoint);
    }

    fetchHistory(): Observable<{ locationId: number, date: number }[]> {
        const history = localStorage.getItem('history');
        if (history) {
            return of(JSON.parse(history));
        } else {
            return of([]);
        }
    }
}
