import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CapsuleUiModule } from '../../ui/capsule/capsule.module';
import { ConnectionUiModule } from '../../ui/connection/connection.module';
import { LocationSwipeModule } from '../../ui/interactives/swipe-block/swipe-block.module';
import { LocationInfoModule } from '../../ui/locations/location-info/location-info.module';
import { LocationsUiModule } from '../../ui/locations/locations.module';
import { TabsModule } from '../../ui/tabs/tabs.module';
import { UiModule } from '../../ui/ui.module';
import { ConnectionDisconnectModule } from '../connection/connection-disconnect/connection-disconnect.module';
import { HistoryListModule } from '../history/history-list/history-list.module';
import { LocationIconStructureModule } from '../locations/location-icon-structure/location-icon-structure.module';
import { FavoriteFolderComponent } from './favorite-folder/favorite-folder.component';
import { FavoriteListComponent } from './favorite-list/favorite-list.component';
import { FavoriteLocationModule } from './favorite-location/favorite-location.module';


@NgModule({
    declarations: [
        FavoriteListComponent,
        FavoriteFolderComponent,
    ],
    imports: [
        CommonModule,
        UiModule,
        CapsuleUiModule,
        TabsModule,
        NgbModule,
        DragDropModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ConnectionUiModule,
        LocationsUiModule,
        HistoryListModule,
        LocationSwipeModule,
        FavoriteLocationModule,
        LocationIconStructureModule,
        LocationInfoModule,
        ConnectionDisconnectModule,
    ],
    exports: [
        FavoriteListComponent,
    ],
})
export class FavoriteModule {}
