import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { WidgetTileComponent } from './widget-tile/widget-tile.component';
import { WidgetSimpleComponent } from './widget-simple/widget-simple.component';
import { WidgetListComponent } from './widget-list/widget-list.component';


@NgModule({
    declarations: [
        WidgetTileComponent,
        WidgetSimpleComponent,
        WidgetListComponent,
    ],
    exports: [
        WidgetTileComponent,
        WidgetSimpleComponent,
        WidgetListComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ],
})

export class WidgetUiModule {}
