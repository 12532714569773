import {
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';
import { SwiperOptions } from 'swiper';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-slider-info',
    templateUrl: './slider-info.component.html',
    styleUrls: ['./slider-info.component.scss'],
})
export class SliderInfoComponent implements OnInit {

    @Input() images: Observable<string[] | undefined> | undefined;
    @Input() options: any;
    @ViewChild('slider') slider?: SwiperComponent;

    public height: number | undefined;
    public config: SwiperOptions | undefined;

    constructor() {}

    ngOnInit(): void {

        this.config = {
            slidesPerView: this.options?.slidesPerView || 1,
            spaceBetween: this.options?.spaceBetween || 0,
            keyboard: this.options?.keyboard || true,
            pagination: this.options?.pagination || true,
            loop: this.options?.loop || true,
        };

        this.height = this.options?.height || 230;

        this.images?.subscribe(() => {
            this.slider?.directiveRef?.update();
        });
    }

}
