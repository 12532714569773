import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Location } from '../../../schemas/location.schemas';


const logger = new Logger('LocationIconStructureComponent');

@Component({
    selector: 'app-location-icon-structure',
    templateUrl: './location-icon-structure.component.html',
    styleUrls: ['./location-icon-structure.component.scss'],
})
export class LocationIconStructureComponent implements OnInit {

    @Input() location: Location | undefined;

    constructor(private router: Router) { }

    ngOnInit(): void {
    }

    goToStructure(id: number | undefined): void {
        if (id) {
            this.router.navigate(['locations/' + id + '/structure']);
        }
    }
}
