import {
    createReducer,
    on,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import * as connectionActions from '../actions/connection.actions';


export const DEFAULT_CONNECTION_FEATURE_NAME = 'connection';

export interface ConnectionReducerState {
    connections: schemas.Connection[];
    currentConnection: schemas.Connection | null;
    isConnecting: boolean;
    error: schemas.ResponseError | null;
}

const initialState: ConnectionReducerState = {
    connections: [],
    currentConnection: null,
    isConnecting: false,
    error: null,
};

export const connectionReducer = createReducer(
    initialState,
    on(
        connectionActions.connectAction,
        state => ({
            ...state,
            isConnecting: true,
            error: null,
        }),
    ),
    on(
        connectionActions.connectSuccessAction,
        (state, {connection}) => ({
            ...state,
            isConnecting: false,
            currentConnection: connection,
            connections: [
                ...state.connections.filter(item => item.location.id !== connection.location.id),
                connection,
            ],
        }),
    ),
    on(
        connectionActions.connectFailureAction,
        (state, {error}) => ({
            ...state,
            isConnecting: false,
            error,
        }),
    ),
    on(
        connectionActions.disconnectFromLocationAction,
        (state, {location}) => {
            const connections = state.connections.filter(
                connection => connection.location.id !== location.id,
            );
            const currentConnection = (state.currentConnection?.location.id === location.id)
                ? (connections.length ? connections[connections.length - 1] : null)
                : state.currentConnection;

            return {
                ...state,
                connections,
                currentConnection,
            };
        },
    ),
    on(
        connectionActions.setCurrentConnectionAction,
        (state, {connection}) => ({
            ...state,
            currentConnection: connection,
        }),
    ),
);
