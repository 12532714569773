import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardsUiModule } from '../../ui/cards/cards.module';
import { LocationSwipeModule } from '../../ui/interactives/swipe-block/swipe-block.module';
import { LocationInfoModule } from '../../ui/locations/location-info/location-info.module';
import { FloatPanelModule } from '../../ui/panels/float-panel';
import { TabsModule } from '../../ui/tabs/tabs.module';
import { TagSelectorModule } from '../../ui/tags/tag-selector/tag-selector.module';
import { ContactsModule } from '../contacts/contacts.module';
import { FavoriteLocationModule } from '../favorite/favorite-location/favorite-location.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { LocationFloatListComponent } from './location-float-list/location-float-list.component';
import { LocationIconStructureModule } from './location-icon-structure/location-icon-structure.module';
import { LocationInfoComponent } from './location-info/location-info.component';
import { LocationSlimListComponent } from './location-slim-list/location-slim-list.component';
import { LocationSlimComponent } from './location-slim/location-slim.component';
import { LocationStructureModule } from './location-structure/location-structure.module';
import {LocationsUiModule} from '../../ui/locations/locations.module';


@NgModule({
    declarations: [
        LocationInfoComponent,
        LocationFloatListComponent,
        LocationSlimComponent,
        LocationSlimListComponent,
    ],
    imports: [
        CommonModule,
        FloatPanelModule,
        CardsUiModule,
        ContactsModule,
        WidgetsModule,
        LocationStructureModule,
        TabsModule,
        TagSelectorModule,
        LocationSwipeModule,
        LocationInfoModule,
        LocationIconStructureModule,
        FavoriteLocationModule,
        LocationsUiModule,
    ],
    exports: [
        LocationInfoComponent,
        LocationFloatListComponent,
    ],
})
export class LocationsModule {}
