import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-location-close',
    templateUrl: './location-close.component.html',
    styleUrls: ['./location-close.component.scss'],
})
export class LocationCloseComponent {

    @Output() closeClick = new EventEmitter<void>();

    close(): void {
        this.closeClick.emit();
    }

}
