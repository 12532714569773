<div class="folder">

    <div class="folder__info">

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="24 / file / folder">
                <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                      d="M21 5C22.1046 5 23 5.89543 23 7V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3H9C10.12 3 10.8329 3.47545 11.549 4.37885C11.5688 4.40385 11.6171 4.46608 11.6706 4.535L11.6707 4.53515C11.7461 4.63236 11.8318 4.74283 11.8625 4.78082C11.8935 4.81925 11.9196 4.85103 11.9412 4.8773L11.9412 4.87732C12.0292 4.98428 12.0419 4.99978 12.0018 5H21ZM21 9V7L11.9946 6.99999C11.2765 6.99614 10.8087 6.65991 10.3058 6.03654C10.2603 5.98006 10.1496 5.83737 10.0692 5.73376L10.0692 5.73375L10.0691 5.73357C10.0271 5.67944 9.99336 5.636 9.9816 5.62116C9.60702 5.14857 9.38424 5 9 5H3V9H21ZM21 11H3V19H21V11Z"
                      fill="inherit"/>
            </g>
        </svg>

        <div class="folder__title">{{ title }}</div>
    </div>

    <div class="folder__tools">

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g id="24 / arrows / chevron-right">
                <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                      d="M14.5858 12L7.29291 4.70711L8.70712 3.29289L17.4142 12L8.70712 20.7071L7.29291 19.2929L14.5858 12Z"
                      fill="inherit"/>
            </g>
        </svg>

    </div>


</div>
