import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';


const log = new Logger('DemoService');

@Injectable({
    providedIn: 'root',
})
export class DemoService {

    constructor(
        private http: HttpClient,
    ) { }

    getDemoCodes(): Observable<schemas.DemoCode[]> {
        const endpoint = 'assets/json/demo-codes.json';

        return this.http.get<schemas.DemoCode[]>(endpoint);
    }

}
