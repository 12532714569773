<div class="location-slim br-5 d-flex align-items-center justify-content-between">

    <div (click)="onLocationClick()" class="br-5 d-flex align-items-center justify-content-start">
        <img src="{{ location?.icon }}" alt="location preview" class="location-slim__image br-3">

        <div class="d-flex flex-column justify-content-start">
            <div class="location-slim__title">{{ location?.title }}</div>
            <div class="location-slim__info">
                {{ locationTags }}
                <span [hidden]="!widgetsTotal">
                    <span [hidden]="!locationTags"> • </span>
                    {{ widgetsTotal }} виджет(а/ов)
                </span>
            </div>
        </div>
    </div>

    <ng-content></ng-content>

</div>
