import {
    createReducer,
    on,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import {
    Authorization,
    User,
} from '../../schemas/auth.schemas';
import * as authActions from '../actions/auth.actions';


export const DEFAULT_AUTH_FEATURE_NAME = 'auth';

export interface AuthReducerState {
    isInit: boolean;
    isLoading: boolean;
    authorization: Authorization | null;
    error: schemas.ResponseError | null;
    user: User | null;
}

const initialState: AuthReducerState = {
    isInit: false,
    isLoading: false,
    authorization: null,
    error: null,
    user: null,
};

export const authReducer = createReducer(
    initialState,
    on(
        authActions.signUpAction,
        authActions.signInAction,
        authActions.signOutAction,
        state => ({
            ...state,
            isLoading: true,
        }),
    ),
    on(
        authActions.signUpSuccessAction,
        authActions.signInSuccessAction,
        (state, {authorization}) => ({
            ...state,
            isInit: true,
            isLoading: false,
            authorization,
        }),
    ),
    on(
        authActions.signUpFailureAction,
        authActions.signInFailureAction,
        (state, {error}) => ({
            ...state,
            isInit: true,
            isLoading: false,
            authorization: null,
            user: null,
            error,
        }),
    ),
    on(
        authActions.signOutSuccessAction,
        authActions.signOutFailureAction,
        () => ({
            ...initialState,
            isInit: true,
        }),
    ),
);
