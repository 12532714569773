import { Injectable } from '@angular/core';
import {
    Logger,
    utils,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    map,
    tap,
} from 'rxjs/operators';

import { Authorization } from '../../../schemas/auth.schemas';
import { ApiService } from '../api.service';


const logger = new Logger('DeviceAuthApi');

@Injectable({
    providedIn: 'root',
})
export class DeviceAuthApi {

    constructor(
        private apiService: ApiService,
    ) { }

    authorize(): Observable<Authorization> {
        return this.apiService.post<Authorization>(
            '/authentication/device/authorize',
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(authorization => logger.debug('authorize (authorization)', authorization)),
        );
    }

}
