import {
    Component,
    OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { schemas } from '@scatch/ngx-app-lib';

import { ScannerFacade } from '../../../facades/scanner.facade';


@Component({
    selector: 'app-connect-get-started',
    templateUrl: './connect-get-started.component.html',
    styleUrls: ['./connect-get-started.component.scss'],
})
export class ConnectGetStartedComponent implements OnInit {

    demoCodes$: Observable<schemas.DemoCode[]> = this.scannerFacade.demoCodes$;

    constructor(private scannerFacade: ScannerFacade) { }

    ngOnInit(): void {}

    onClickSliderItem(item: any): void {
        this.scannerFacade.parse(item.data);
    }

}
