import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-widget-tile',
    templateUrl: './widget-tile.component.html',
    styleUrls: ['./widget-tile.component.scss'],
})
export class WidgetTileComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
