import {
    Component,
    OnInit,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { AuthFacade } from '../../../facades/auth.facade';
import { PhoneFacade } from '../../../facades/phone.facade';


@Component({
    selector: 'app-auth-forgot-password-step-second',
    templateUrl: './auth-forgot-password-step-second.component.html',
    styleUrls: ['./auth-forgot-password-step-second.component.scss'],
})
export class AuthForgotPasswordStepSecondComponent implements OnInit {

    isLoading$: Observable<boolean> = this.authFacade.isLoading$;
    authError$: Observable<schemas.ResponseError | null> = this.authFacade.error$;
    phoneError$: Observable<schemas.ResponseError | null> = this.phoneFacade.error$;
    codePhone$: Observable<string | undefined> = this.phoneFacade.codePhone$;
    cannotResendCode$: Observable<boolean> = this.phoneFacade.cannotSendCode$;
    codeResendCountdown$: Observable<number> = this.phoneFacade.codeResendCountdown$;

    constructor(
        private authFacade: AuthFacade,
        private phoneFacade: PhoneFacade,
    ) { }

    ngOnInit(): void {
    }

    onFormSubmit(form: { code: string }): void {
        this.codePhone$.pipe(
            first(),
        ).subscribe(phone => {
            if (phone) {
                this.authFacade.signInByPasswordResetCode({
                    phone,
                    code: form.code,
                });
            }
        }).unsubscribe();
    }

    onResendCode(): void {
        this.codePhone$.pipe(
            first(),
        ).subscribe(phone => {
            if (phone) {
                this.phoneFacade.sendCode(phone);
            }
        }).unsubscribe();
    }

}
