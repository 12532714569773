<app-map>
    <app-location-float-list
            *ngIf="selectedLocationIds?.length"
            [style.visibility]="selectedLocationId ? 'hidden': 'visible'"
            [locationIds]="selectedLocationIds"
            (closeClick)="onCloseLocationList()"
            (locationClick)="onLocationClick($event)"
    ></app-location-float-list>
    <app-location-info
            *ngIf="selectedLocationId"
            [isExpanded]="!!selectedLocationIds.length"
            [locationId]="selectedLocationId"
            (closeClick)="onCloseLocationInfo()"
    ></app-location-info>
</app-map>
