import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    BehaviorSubject,
    Observable,
} from 'rxjs';

import {
    Card,
    Contact,
    Widget,
} from '../schemas/about.schemas';
import { AboutService } from '../services/about/about.service';


@Injectable({
    providedIn: 'root',
})
export class AboutFacade {

    private cardSubject = new BehaviorSubject<Card>({images: [], likes: 0, views: 0, text: '', title: ''});
    private card: Card = {images: [], likes: 0, views: 0, text: '', title: ''};

    private contactsSubject = new BehaviorSubject<Contact[]>([]);
    private contacts: Contact[] = [];

    private widgetsSubject = new BehaviorSubject<Widget[]>([]);
    private widgets: Widget[] = [];

    private imagesSubject = new BehaviorSubject<string[]>([]);

    constructor(private store$: Store, private aboutService: AboutService) {
    }

    get card$(): Observable<Card> {
        return this.cardSubject.asObservable();
    }

    get contacts$(): Observable<Contact[]> {
        return this.contactsSubject.asObservable();
    }

    get widgets$(): Observable<Widget[]> {
        return this.widgetsSubject.asObservable();
    }

    get images$(): Observable<string[]> {
        return this.imagesSubject.asObservable();
    }


    loadAboutInfo(locationId: number | undefined): void {

        if (locationId) {
            this.aboutService.getAboutData(locationId).subscribe(
                data => {
                    this.card = data;
                    this.cardSubject.next(this.card);
                    this.imagesSubject.next(data?.images);
                },
            );
        }

    }

    loadContacts(locationId: number | undefined): void {

        if (locationId) {
            this.aboutService.getContactsData(locationId).subscribe(
                data => {
                    this.contacts = data;
                    this.contactsSubject.next(this.contacts);
                },
            );
        }

    }

    loadWidgets(locationId: number | undefined): void {

        if (locationId) {
            this.aboutService.getWidgetsData(locationId).subscribe(
                data => {
                    this.widgets = data;
                    this.widgetsSubject.next(this.widgets);
                },
            );
        }

    }

    fetchLocationCard(locationId: number): Observable<Card> {
        return this.aboutService.getAboutData(locationId);
    }

}
