import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


// *******************************************************************************
// Libs
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidenavModule } from 'vendor/libs/sidenav/sidenav.module';


// *******************************************************************************
// Layouts
import { LayoutBlankComponent } from './layout-blank/layout-blank.component';
import { LayoutIterraFooterComponent } from './layout-iterra-footer/layout-iterra-footer.component';
import { LayoutIterraNavbarBackComponent } from './layout-iterra-navbar-back/layout-iterra-navbar-back.component';
import { LayoutIterraNavbarComponent } from './layout-iterra-navbar/layout-iterra-navbar.component';
import { LayoutIterraSidenavComponent } from './layout-iterra-sidenav/layout-iterra-sidenav.component';
import { LayoutIterraWithBackComponent } from './layout-iterra-with-back/layout-iterra-with-back.component';
import { LayoutIterraWithoutNavbarComponent } from './layout-iterra-without-navbar/layout-iterra-without-navbar.component';
import { LayoutIterraComponent } from './layout-iterra/layout-iterra.component';
import { LayoutVendorStyleNavbarComponent } from './layout-vendor-style-navbar/layout-vendor-style-navbar.component';
import { LayoutVendorStyleComponent } from './layout-vendor-style/layout-vendor-style.component';


// *******************************************************************************
// Services
import { LayoutService } from './layout.service';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        SidenavModule,
    ],
    declarations: [
        LayoutBlankComponent,
        LayoutVendorStyleComponent,
        LayoutVendorStyleNavbarComponent,
        LayoutIterraComponent,
        LayoutIterraFooterComponent,
        LayoutIterraNavbarComponent,
        LayoutIterraSidenavComponent,
        LayoutIterraWithoutNavbarComponent,
        LayoutIterraNavbarBackComponent,
        LayoutIterraWithBackComponent,
    ],
    providers: [
        LayoutService,
    ],
})
export class LayoutModule {}
