import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialItemComponent } from './social-item/social-item.component';


@NgModule({
    declarations: [
        SocialItemComponent,
    ],
    exports: [
        SocialItemComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class SocialUiModule {}
