import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    BrowserModule,
    Title,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    IConfig,
    NgxMaskModule,
} from 'ngx-mask';
import { ModalDialogModule } from 'ngx-modal-dialog';

import {
    SWIPER_CONFIG,
    SwiperConfigInterface,
    SwiperModule,
} from 'ngx-swiper-wrapper';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { ErrorHandlerInterceptorProvider } from './interceptors/error-handler.interceptor';
import { LayoutModule } from './layout/layout.module';
import { AccountModule } from './modules/account/account.module';
import { AuthModule } from './modules/auth/auth.module';
import { CapsuleModule } from './modules/capsule/capsule.module';
import { CardsModule } from './modules/cards/cards.module';
import { ConnectModule } from './modules/connect/connect.module';
import { ConnectionListModule } from './modules/connection/connection-list/connection-list.module';
import { ContactsModule } from './modules/contacts/contacts.module';
import { MapModule } from './modules/map/map.module';
import { NotificationModule } from './modules/notification/notification.module';
import { WidgetModule } from './modules/widget/widget.module';
import { WidgetsModule } from './modules/widgets/widgets.module';
import { AppRoutingModule } from './routing/routing.module';
import { MapBoxServiceProvider } from './services/map/map-box.service';
import {
    effects,
    metaReducers,
    reducers,
} from './store';
import { AppStoreModule } from './store/store.module';


export const options: Partial<IConfig> | (() => Partial<IConfig>) | null = null;


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    pagination: true,
};


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
        RouterModule.forRoot([]),
        EffectsModule.forRoot(effects),
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        ToastrModule.forRoot({
            tapToDismiss: true,
            progressBar: true,
            progressAnimation: 'decreasing',
            positionClass: 'toast-top-center',
        }),
        NgxMaskModule.forRoot(),
        NgbModule,
        AppStoreModule,
        AppRoutingModule,
        AuthModule,
        AccountModule,
        NotificationModule,
        CapsuleModule,
        WidgetModule,
        CardsModule,
        ContactsModule,
        WidgetsModule,
        LayoutModule,
        ConnectModule,
        MapModule,
        ModalDialogModule,
        SwiperModule,
        ConnectionListModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production || true,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        AppService,
        Title,
        ErrorHandlerInterceptorProvider,
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG,
        },
        MapBoxServiceProvider,
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {}
