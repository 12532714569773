import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwipableModule } from '../../../directives/swipable/swipable.module';
import { FavoriteLocationComponent } from './favorite-location.component';


const components = [
    FavoriteLocationComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        SwipableModule,
    ],
    exports: [
        ...components,
    ],
})
export class FavoriteLocationModule {}
