import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { Widget } from '../../../schemas/widget.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-widget-simple',
    templateUrl: './widget-simple.component.html',
    styleUrls: ['./widget-simple.component.scss'],
})
export class WidgetSimpleComponent implements OnInit {

    @Input() widget: Widget | undefined;

    @Output() clickWidget = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

    onClickWidget(): void {
        this.clickWidget.emit();
    }

}
