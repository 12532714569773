import {
    createEntityAdapter,
    EntityAdapter,
    EntityState,
} from '@ngrx/entity';
import {
    createReducer,
    on,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import { Capsule } from '../../schemas/capsule.schemas';
import * as capsuleActions from '../actions/capsule.actions';


export const DEFAULT_CAPSULE_FEATURE_NAME = 'capsule';

export interface CapsuleReducerState extends EntityState<Capsule> {
    isLoading: boolean;
    error: schemas.ResponseError | null;
    currentCapsuleId: number | null;
}

export function selectCapsuleId(capsule: Capsule): number {
    return capsule.id;
}

export function sortByTitle(a: Capsule, b: Capsule): number {
    return a.title.localeCompare(b.title);
}

export const capsuleAdapter: EntityAdapter<Capsule> = createEntityAdapter<Capsule>({
    selectId: selectCapsuleId,
    sortComparer: sortByTitle,
});

export const initialState: CapsuleReducerState = capsuleAdapter.getInitialState({
    isLoading: false,
    error: null,
    currentCapsuleId: null,
});

export const capsuleReducer = createReducer(
    initialState,
    on(
        capsuleActions.addCapsule,
        (state, {capsule}) => capsuleAdapter.addOne(capsule, state),
    ),
    on(
        capsuleActions.setCapsule,
        (state, {capsule}) => capsuleAdapter.setOne(capsule, state),
    ),
    on(
        capsuleActions.upsertCapsule,
        (state, {capsule}) => capsuleAdapter.upsertOne(capsule, state),
    ),
    on(
        capsuleActions.addCapsules,
        (state, {capsules}) => capsuleAdapter.addMany(capsules, state),
    ),
    on(
        capsuleActions.upsertCapsules,
        (state, {capsules}) => capsuleAdapter.upsertMany(capsules, state),
    ),
    on(
        capsuleActions.updateCapsule,
        (state, {update}) => capsuleAdapter.updateOne(update, state),
    ),
    on(
        capsuleActions.updateCapsules,
        (state, {updates}) => capsuleAdapter.updateMany(updates, state),
    ),
    on(
        capsuleActions.mapCapsule,
        (state, {entityMap}) => capsuleAdapter.mapOne(entityMap, state),
    ),
    on(
        capsuleActions.mapCapsules,
        (state, {entityMap}) => capsuleAdapter.map(entityMap, state),
    ),
    on(
        capsuleActions.deleteCapsule,
        (state, {id}) => capsuleAdapter.removeOne(id, state),
    ),
    on(
        capsuleActions.deleteCapsules,
        (state, {ids}) => capsuleAdapter.removeMany(ids, state),
    ),
    on(
        capsuleActions.deleteCapsulesByPredicate,
        (state, {predicate}) => capsuleAdapter.removeMany(predicate, state),
    ),
    on(
        capsuleActions.setCapsules,
        (state, {capsules}) => capsuleAdapter.setAll(capsules, state),
    ),
    on(
        capsuleActions.clearCapsules,
        state => capsuleAdapter.removeAll(state),
    ),
    on(
        capsuleActions.loadCapsuleAction,
        state => ({
            ...state,
            isLoading: true,
        }),
    ),
    on(
        capsuleActions.loadCapsuleSuccessAction,
        (state, {capsule}) => capsuleAdapter.setOne(
            capsule,
            {
                ...state,
                isLoading: false,
            },
        ),
    ),
    on(
        capsuleActions.loadCapsuleFailureAction,
        (state, {error}) => ({
            ...state,
            isLoading: false,
            error,
        }),
    ),
    on(
        capsuleActions.setCurrentCapsuleId,
        (state, {capsuleId}) => ({
            ...state,
            currentCapsuleId: capsuleId,
        }),
    ),
    on(
        capsuleActions.unsetCurrentCapsuleId,
        state => ({
            ...state,
            currentCapsuleId: null,
        }),
    ),
);
