import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import {
    select,
    Store,
} from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    filter,
    first,
    map,
    tap,
} from 'rxjs/operators';

import * as capsuleSelectors from '../store/selectors/capsule.selectors';


const logger = new Logger('WidgetsGuard');

@Injectable({
    providedIn: 'root',
})
export class WidgetsGuard implements CanActivate {

    constructor(
        private router: Router,
        private store$: Store,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store$.pipe(
            select(capsuleSelectors.selectCurrentCapsule),
            tap(capsule => {
                if (!capsule) {
                    this.router.navigate(['/scanner'])
                        .catch(reason => logger.error('Navigate failed', reason));
                }
            }),
            filter(capsule => !!capsule?.widgetIds),
            tap(capsule => logger.debug('(capsule)', capsule)),
            map(capsule => {
                const canActivate = !!capsule?.widgetIds?.length;
                if (!canActivate) {
                    this.router.navigate(['/scanner'])
                        .catch(reason => logger.error('Navigate failed', reason));
                }
                return canActivate;
            }),
            first(),
        );
    }

}
