import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';


const logger = new Logger('LayoutEffects');

@Injectable()
export class LayoutEffects {

    constructor() {}


}
