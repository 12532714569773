import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SlidersUiModule } from '@scatch/ngx-app-lib';
import { ConnectUiModule } from '../../ui/connect/connect.module';
import { ConnectGetStartedComponent } from './connect-get-started/connect-get-started.component';


const components = [
    ConnectGetStartedComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        CommonModule,
        ConnectUiModule,
        SlidersUiModule,
    ],
    exports: [...components],
})
export class ConnectModule {}
