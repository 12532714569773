import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { LocationFacade } from '../../../facades/location.facade';
import { LocationTagList } from '../../../schemas/location.schemas';
import { constants } from '../../../app.constants';

const logger = new Logger('LocationFloatListComponent');

@Component({
    selector: 'app-location-float-list',
    templateUrl: './location-float-list.component.html',
    styleUrls: ['./location-float-list.component.scss'],
})
export class LocationFloatListComponent implements OnInit, OnChanges, OnDestroy {

    @Input() locationIds: number[] = [];

    @Output() locationClick = new EventEmitter<schemas.Location>();
    @Output() closeClick = new EventEmitter<void>();

    typeFilter: 'location' | 'space' = 'location';

    locations: schemas.Location[] = [];
    locationsTags: schemas.Tag[] = [];
    locationsTagList?: LocationTagList;

    spaces: schemas.Location[] = [];
    spacesTags: schemas.Tag[] = [];
    spacesTagList?: LocationTagList;

    selectedTag?: schemas.Tag;

    moreTag: schemas.Tag = constants.tagMore;

    private subscriptions: Subscription[] = [];

    constructor(
        private locationFacade: LocationFacade,
    ) { }

    ngOnInit(): void {
        logger.debug('ngOnInit');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.locationIds) {
            const locationIds = changes.locationIds.currentValue;
            this.unsubscribeAll();
            this.loadLocations(locationIds);
            this.loadLocationsTagList(locationIds);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
    }

    private unsubscribeAll(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.locations = [];
            this.locationsTags = [];
            this.spaces = [];
            this.spacesTags = [];
            this.subscriptions = [];
        }
    }

    loadLocations(locationIds: number[]): void {
        logger.debug('loadLocations', locationIds);

        this.subscriptions.push(
            this.locationFacade.fetchLocations({locationIds})
                .subscribe(this.processLocations.bind(this)),
        );
    }

    loadLocationsTagList(locationIds: number[]): void {
        logger.debug('loadLocationsTagList', locationIds);

        this.subscriptions.push(
            this.locationFacade
                .fetchLocationsTagList(locationIds)
                .subscribe(this.processLocationsTagList.bind(this)),
        );
    }

    loadSpaces(locationIds: number[]): void {
        logger.debug('loadSpaces', locationIds);

        this.subscriptions.push(
            this.locationFacade.fetchLocations({locationIds})
                .subscribe(this.processSpaces.bind(this)),
        );
    }

    loadSpacesTagList(locationIds: number[]): void {
        logger.debug('loadSpacesTagList', locationIds);

        this.subscriptions.push(
            this.locationFacade
                .fetchLocationsTagList(locationIds)
                .subscribe(this.processSpacesTagList.bind(this)),
        );
    }

    processLocations(locations: schemas.Location[]): void {
        logger.debug('processLocations', locations);

        const spaceIds: number[] = [];

        locations.forEach(location => {
            const spaceId = Number(location.path.split(',')[0]);
            if (!spaceIds.includes(spaceId)) {
                spaceIds.push(spaceId);
            }
        });

        this.locations = locations;
        this.loadSpaces(spaceIds);
        this.loadSpacesTagList(spaceIds);
    }

    processSpaces(locations: schemas.Location[]): void {
        logger.debug('processSpaces', locations);

        this.spaces = locations;
    }

    processLocationsTagList(tagList: LocationTagList): void {
        logger.debug('processLocationsTagList', tagList);

        this.locationsTagList = tagList;
        this.locationsTags = this.extractTags(tagList);
    }

    processSpacesTagList(tagList: LocationTagList): void {
        logger.debug('processSpacesTagList', tagList);

        this.spacesTagList = tagList;
        this.spacesTags = this.extractTags(tagList);
    }

    extractTags(tagList: LocationTagList): schemas.Tag[] {
        const locationsTags: schemas.Tag[] = [];

        for (const locationId in tagList) {
            if (tagList.hasOwnProperty(locationId)) {
                const tags = tagList[locationId];
                for (const tag of tags) {
                    const exists = locationsTags.find(item => item.id === tag.id);
                    if (!exists) {
                        locationsTags.push(tag);
                    }
                }
            }
        }

        return locationsTags;
    }

    getFilteredLocations(): schemas.Location[] {
        return this.locationsTagList
            ? this.filterLocationsBySelectedTag(this.locations, this.locationsTagList)
            : [];
    }

    getFilteredSpaces(): schemas.Location[] {
        return this.spacesTagList
            ? this.filterLocationsBySelectedTag(this.spaces, this.spacesTagList)
            : [];
    }

    filterLocationsBySelectedTag(
        locations: schemas.Location[],
        tagList: LocationTagList,
    ): schemas.Location[] {
        if (!this.selectedTag) {
            return locations;
        }

        return locations.filter(location => {
            const tags = tagList[location.id];

            return tags && tags.find(tag => tag.id === this.selectedTag?.id);
        });
    }

    onTabSelect({tabId}: { tabId: number }): void {
        logger.debug('onTabSelect', tabId);

        this.typeFilter = tabId === 1
            ? 'location'
            : 'space';
    }

    onTagSelect(tag?: schemas.Tag): void {
        logger.debug('onTagSelect', tag);

        this.selectedTag = this.selectedTag?.id === tag?.id
            ? undefined
            : tag;
    }

    onLocationClick(location: schemas.Location): void {
        logger.debug('onLocationClick', location);

        this.locationClick.emit(location);
    }

    close(): void {
        logger.debug('close');

        this.closeClick.emit();
    }

}
