<div class="location">

    <div class="location__info">

        <i [hidden]="!folder"
           (click)="deleteLocationFromFolder(location?.id)"
           class="location__delete">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect width="24" height="24" rx="12" fill="#44474D"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 13V11H18V13H6Z" fill="white"/>
            </svg>
        </i>

        <div class="location__extra" (click)="goToLocation(location)">
            <div class="location__content">
                <div class="location__icon" [ngStyle]="{'background-image': 'url('+ location?.icon +')'}"></div>
                <div class="location__title">{{ location?.title }}</div>
            </div>
            <div class="location__time">{{ lastVisitDate |  date:'HH:mm' }}</div>
        </div>
    </div>

    <div class="location__tools">

        <!--        <i [hidden]="!capsule?.isHomeCapsule" class="capsule__home" [routerLink]="['/capsule/home']">-->
        <!--            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">-->
        <!--                <g id="24 / basic / settings">-->
        <!--                    <path id="icon" fill-rule="evenodd" clip-rule="evenodd"-->
        <!--                          d="M18.6562 20.897L20.8733 18.6798L20.0925 15.843L20.4327 15.0305L23 13.5818V10.4464L20.44 8.99173L20.1055 8.18067L20.8961 5.34235L18.6774 3.12683L15.8403 3.90748L15.0296 3.56758L13.5808 1H10.4454L8.99072 3.56004L8.17985 3.89446L5.34198 3.10281L3.1267 5.31809L3.90748 8.15567L3.56758 8.96634L1 10.4151V13.5496L3.55774 15.0076L3.89252 15.8193L3.10197 18.6572L5.31809 20.8733L8.15567 20.0925L8.96644 20.4325L10.4153 22.999H13.5498L15.0067 20.4412L15.8183 20.1065L18.6562 20.897ZM18.8527 13.6256L17.9809 15.7078L18.6362 18.0886L18.0678 18.657L15.692 17.9951L13.609 18.8542L12.3873 20.999H11.5829L10.3714 18.8529L8.29155 17.9808L5.90947 18.6362L5.34203 18.0688L6.00385 15.693L5.14482 13.6101L3 12.3876V11.583L5.1471 10.3715L6.0192 8.29155L5.36375 5.90947L5.93001 5.34321L8.30576 6.00595L10.3895 5.14655L11.6093 3H12.4129L13.6245 5.1471L15.7044 6.0192L18.087 5.36362L18.6558 5.93166L17.9941 8.30696L18.8534 10.3906L21 11.6103V12.4139L18.8527 13.6256ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"-->
        <!--                          fill="#8C8E91"/>-->
        <!--                </g>-->
        <!--            </svg>-->
        <!--        </i>-->

        <div class="location__social">
            <i [hidden]="!location?.isFavorite"
               (click)="toggleFavoriteLocation(location)"
               class="location__social-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit">
                    <g id="24 / basic / star">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                              d="M12 19.1298L4.79403 22.9182L6.17025 14.8942L0.340485 9.21159L8.39702 8.04091L12 0.740448L15.603 8.04091L23.6595 9.21159L17.8298 14.8942L19.206 22.9182L12 19.1298Z"
                              fill="inherit"/>
                    </g>
                </svg>
            </i>

            <i [hidden]="location?.isFavorite"
               (click)="toggleFavoriteLocation(location)"
               class="location__social-item location__social-item--favorite">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit">
                    <g id="24 / basic / star">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                              d="M6.17025 14.8942L4.79403 22.9182L12 19.1298L19.206 22.9182L17.8298 14.8942L23.6595 9.21159L15.603 8.04091L12 0.740448L8.39702 8.04091L0.340485 9.21159L6.17025 14.8942ZM15.6808 14.196L16.5497 19.2622L12 16.8702L7.45029 19.2622L8.31921 14.196L4.63841 10.6081L9.72515 9.86892L12 5.25955L14.2749 9.86892L19.3616 10.6081L15.6808 14.196Z"
                              fill="inherit"/>
                    </g>
                </svg>
            </i>
        </div>


    </div>

</div>
