import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Location } from '../../../schemas/location.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {

    @Input() location: Location | undefined;
    @Input() folder: boolean | undefined = false;
    @Input() lastVisitDate: number | undefined;

    @Output() deletingLocationFromFolder = new EventEmitter<number>();
    @Output() toggleLocationFavorite = new EventEmitter<Location>();
    @Output() goToLocationFavorite = new EventEmitter<Location>();

    constructor() { }

    ngOnInit(): void {
    }


    toggleFavoriteLocation(location: Location | undefined): void {
        if (location) {
            this.toggleLocationFavorite.emit(location);
        }
    }

    deleteLocationFromFolder(id: number | undefined): void {
        if (id) {
            this.deletingLocationFromFolder.emit(id);
        }
    }

    goToLocation(location: Location | undefined): void {
        if (location) {
            this.goToLocationFavorite.emit(location);
        }
    }

}
