import {
    createAction,
    props,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import { Code } from '../../schemas/phone.schemas';


export const sendCodeAction = createAction(
    '[Phone] Send Code',
    props<{ phone: string }>(),
);

export const sendCodeSuccessAction = createAction(
    '[Phone] Send Code Success',
    props<Code>(),
);

export const sendCodeFailureAction = createAction(
    '[Phone] Send Code Failure',
    props<{ error: schemas.ResponseError }>(),
);


export const sendSignUpCodeAction = createAction(
    '[Phone] Send Sign Up Code',
    props<{ phone: string }>(),
);

export const sendSignUpCodeSuccessAction = createAction(
    '[Phone] Send Sign Up Code Success',
    props<Code>(),
);

export const sendSignUpCodeFailureAction = createAction(
    '[Phone] Send Sign Up Code Failure',
    props<{ error: schemas.ResponseError }>(),
);


export const sendSignInCodeAction = createAction(
    '[Phone] Send Sign In Code',
    props<{ phone: string }>(),
);

export const sendSignInCodeSuccessAction = createAction(
    '[Phone] Send Sign In Code Success',
    props<Code>(),
);

export const sendSignInCodeFailureAction = createAction(
    '[Phone] Send Sign In Code Failure',
    props<{ error: schemas.ResponseError }>(),
);


export const sendPasswordResetCodeAction = createAction(
    '[Phone] Send Password Reset Code',
    props<{ phone: string }>(),
);

export const sendPasswordResetCodeSuccessAction = createAction(
    '[Phone] Send Password Reset Code Success',
    props<Code>(),
);

export const sendPasswordResetCodeFailureAction = createAction(
    '[Phone] Send Password Reset Code Failure',
    props<{ error: schemas.ResponseError; }>(),
);
