import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagComponent } from './tag.component';


const components = [
    TagComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ...components,
    ],
})
export class TagModule {}
