import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { List } from '../../../schemas/about.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-list-simple',
    templateUrl: './list-simple.component.html',
    styleUrls: ['./list-simple.component.scss'],
})
export class ListSimpleComponent implements OnInit {

    @Input() list: List | undefined;

    constructor() { }

    ngOnInit(): void {
    }

}
