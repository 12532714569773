import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
