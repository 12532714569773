import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    Logger,
    schemas,
    utils,
    WidgetApi,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    catchError,
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import * as widgetActions from '../actions/widget.actions';


const logger = new Logger('WidgetEffects');

@Injectable()
export class WidgetEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private widgetApi: WidgetApi,
    ) {}

    loadWidgetsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(widgetActions.loadWidgetsAction),
            tap(({ids}) => logger.debug('loadWidgetsEffect (ids)', ids)),
            switchMap(({ids}) => {
                return this.widgetApi.findWidgets({ids}).pipe(
                    tap(widgets => logger.debug('loadWidgetsEffect (widgets)', widgets)),
                    map((widgets: schemas.Widget[]) =>
                        widgetActions.loadWidgetsSuccessAction({
                            widgets,
                        }),
                    ),
                    catchError(response => {
                        logger.debug('response', response);
                        return of(widgetActions.loadWidgetsFailureAction({
                            error: utils.formatErrors(response),
                        }));
                    }),
                    first(),
                );
            }),
        ),
    );

    loadWidgetsSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(widgetActions.loadWidgetsSuccessAction),
            tap(({widgets}) => logger.debug('loadWidgetsSuccessEffect (widgets)', widgets)),
        ),
        {dispatch: false},
    );

}
