import { Routes } from '@angular/router';

import { AuthForgotPasswordStepFirstComponent } from '../modules/auth/auth-forgot-password-step-first/auth-forgot-password-step-first.component';
import { AuthForgotPasswordStepSecondComponent } from '../modules/auth/auth-forgot-password-step-second/auth-forgot-password-step-second.component';
import { AuthForgotPasswordStepThirdComponent } from '../modules/auth/auth-forgot-password-step-third/auth-forgot-password-step-third.component';
import { AuthPhoneSignInComponent } from '../modules/auth/auth-phone-sign-in/auth-phone-sign-in.component';
import { AuthPhoneSignUpStepFirstComponent } from '../modules/auth/auth-phone-sign-up-step-first/auth-phone-sign-up-step-first.component';
// tslint:disable-next-line:max-line-length
import { AuthPhoneSignUpStepSecondComponent } from '../modules/auth/auth-phone-sign-up-step-second/auth-phone-sign-up-step-second.component';
import { AuthStartComponent } from '../modules/auth/auth-start/auth-start.component';


export const authRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: AuthStartComponent,
    },
    {
        path: 'phone',
        children: [
            {path: 'sign-in', component: AuthPhoneSignInComponent},
            {path: 'sign-up', component: AuthPhoneSignUpStepFirstComponent},
            {path: 'sign-up/confirm', component: AuthPhoneSignUpStepSecondComponent},
        ],
    },
    {
        path: 'password',
        children: [
            {path: 'forgot', component: AuthForgotPasswordStepFirstComponent},
            {path: 'forgot/confirm', component: AuthForgotPasswordStepSecondComponent},
            {path: 'forgot/reset', component: AuthForgotPasswordStepThirdComponent},
        ],
    },
];
