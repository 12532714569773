import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';

import { AuthPhoneSignInFormComponent } from './auth-phone-sign-in-form/auth-phone-sign-in-form.component';
import { AuthPhoneSignupStepFirstFormComponent } from './auth-phone-signup-step-first-form/auth-phone-signup-step-first-form.component';
import { AuthPhoneForgotPasswordStepThirdComponent } from './auth-phone-forgot-password-step-third/auth-phone-forgot-password-step-third.component';

import { UiModule } from '../ui.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../pipes/pipes.module';
import { NgxMaskModule } from 'ngx-mask';


@NgModule({
    declarations: [
        AuthPhoneSignInFormComponent,
        AuthPhoneSignupStepFirstFormComponent,
        AuthPhoneForgotPasswordStepThirdComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UiModule,
        RouterModule,
        PipesModule,
        NgxMaskModule,
    ],
    exports: [
        AuthPhoneSignInFormComponent,
        AuthPhoneSignupStepFirstFormComponent,
        AuthPhoneForgotPasswordStepThirdComponent,
    ],
})
export class AuthUIModule {}
