import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MapFacade } from '../../../facades/map.facade';
import { MapService } from '../../../services/map/map.service';


@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];

    @Output() clickMap = new EventEmitter<any>();

    constructor(
        private readonly mapFacade: MapFacade,
        private readonly mapService: MapService,
    ) { }

    ngOnInit(): void {
        this.mapService.init({
            container: 'map',
        });

        this.subscriptions.push(
            this.mapService.mapClick$.subscribe(data => {
                this.clickMap.emit(data);
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

}
