import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    first,
    mergeMap,
} from 'rxjs/operators';

import * as fromAuthSelectors from '../store/auth/auth.selectors';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private store$: Store) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return this.store$.pipe(
            select(fromAuthSelectors.accessTokenSelector),
            first(),
            mergeMap(accessToken => {
                const authRequest = accessToken
                    ? request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    })
                    : request;

                return next.handle(authRequest);
            }),
        );
    }
}

export const AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
};
