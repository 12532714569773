import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ListsUiModule } from '../../ui/lists/lists.module';
import { WidgetsUiModule } from '../../ui/widgets/widgets.module';
import { WidgetListSimpleComponent } from './widget-list-simple/widget-list-simple.component';


@NgModule({
    declarations: [
        WidgetListSimpleComponent,
    ],
    imports: [
        CommonModule,
        ListsUiModule,
        WidgetsUiModule,
    ],
    exports: [
        WidgetListSimpleComponent,
    ],
})
export class WidgetsModule {}
