import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import {
    AUTH_FEATURE_NAME,
    AuthState,
} from './auth.reducer';


const authStateSelector = createFeatureSelector<AuthState>(AUTH_FEATURE_NAME);

export const isLoadingSelector = createSelector(
    authStateSelector,
    (state: AuthState) => state.loading,
);

export const isLoadedSelector = createSelector(
    authStateSelector,
    (state: AuthState) => state.loaded,
);

export const errorSelector = createSelector(
    authStateSelector,
    (state: AuthState) => state.error,
);

export const authorizationSelector = createSelector(
    authStateSelector,
    (state: AuthState) => state.authorization,
);

export const accessTokenSelector = createSelector(
    authorizationSelector,
    authorization => authorization?.accessToken,
);

export const isAuthSelector = createSelector(
    accessTokenSelector,
    accessToken => !!accessToken,
);

export const isLoggedInSelector = createSelector(
    authStateSelector,
    (state: AuthState): boolean => !!state.user?.id,
);

export const isGuestSelector = createSelector(
    authStateSelector,
    (state: AuthState): boolean => !state.user?.id,
);

export const codePhoneSelector = createSelector(
    authStateSelector,
    (state: AuthState) => state.codePhone || '',
);

export const codeSentAtSelector = createSelector(
    authStateSelector,
    (state: AuthState) => state.codeSentAt || 0,
);
