<ui-tab-list-two-items class="folders">

    <span slot="title-1">Избранное</span>

    <div slot="tab-1">

        <form [formGroup]="editFolderForm">
            <div class="folder-group">
                <input type="text" formControlName="title" class="form-control"
                       [ngClass]="{'readonly': !displayUpdateTools}" [readOnly]="!displayUpdateTools">

                <button class="btn btn-primary" (click)="updateTitleFolder()" [hidden]="!displayUpdateTools">Изменить
                </button>

                <div ngbDropdown [placement]="'bottom-right'" [hidden]="displayUpdateTools">
                    <button class="btn btn-default" ngbDropdownToggle>

                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M0 2C0 3.10457 0.89543 4 2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2ZM2 11C0.89543 11 0 10.1046 0 9C0 7.89543 0.89543 7 2 7C3.10457 7 4 7.89543 4 9C4 10.1046 3.10457 11 2 11ZM2 18C0.89543 18 0 17.1046 0 16C0 14.8954 0.89543 14 2 14C3.10457 14 4 14.8954 4 16C4 17.1046 3.10457 18 2 18Z"
                                  fill="#44474D"/>
                        </svg>

                    </button>

                    <div ngbDropdownMenu>
                        <a class="dropdown-item" (click)="openUngroupDialog()">Разгруппировать</a>
                        <a class="dropdown-item" (click)="updateTitleFolder()">Изменить название</a>
                    </div>

                </div>

            </div>
        </form>

        <div *ngFor="let location of locations">
            <ui-location [location]="location"
                         [folder]="true"
                         (goToLocationFavorite)="goToLocation($event)"
                         (deletingLocationFromFolder)="deleteFavoriteLocationFromFolder($event)"
                         (toggleLocationFavorite)="onToggleLocationFavorite($event)">
            </ui-location>
        </div>

    </div>

    <span slot="title-2">История</span>
    <app-history-list slot="tab-2"></app-history-list>

</ui-tab-list-two-items>

