import {
    Component,
    HostBinding,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../../app.service';


@Component({
    selector: 'app-layout-iterra-footer',
    templateUrl: './layout-iterra-footer.component.html',
    styles: [':host { display: block; }'],
    styleUrls: ['./layout-iterra-footer.component.scss'],
})
export class LayoutIterraFooterComponent {
    @HostBinding('class.layout-footer') hostClassMain = true;
    public activeRouteUrl: string;

    constructor(private appService: AppService, private router: Router) {
        this.activeRouteUrl = this.router.url;
    }

    currentBg(): string {
        return `bg-${this.appService.layoutFooterBg}`;
    }
}
