<nav class="navbar navbar-expand-lg align-items-lg-center container-p-x" [ngClass]="currentBg()">

    <!-- Sidenav toggle -->
    <div class="navbar-nav align-items-lg-center mr-auto mr-lg-4" *ngIf="sidenavToggle">

        <a class="nav-item nav-link px-0 ml-2" href="javascript:void(0)" (click)="toggleSidenav()">
            <i class="ion ion-md-menu text-large align-middle"></i>
        </a>

        <span class="navbar__title">{{ title }}</span>

        <a routerLink="/connections" [class.navbar__link--active]="isConnected$ | async">
            <i>
                <svg width="35" height="29" viewBox="0 0 35 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M5.88965 28.9764C6.51723 28.6228 7.12068 28.1512 7.74827 27.7504L14.3379 23.3179C14.6759 23.1293 14.9655 22.8699 15.3034 22.6813C15.6172 22.4927 15.9069 22.2333 16.2207 22.0683C17.1138 21.5496 19.1414 20.0407 20.0103 19.5455C20.3241 19.3569 20.6621 19.0976 20.9276 18.9089L13.5896 21.1724L17.9345 7.23821C18.1517 7.45041 24.669 22.7285 25.3207 24.2374C25.6586 25.0154 26.0448 26.0528 26.3828 26.6894L21.9655 13.3211C20.5414 8.86504 19.0207 4.40894 17.5483 0C17.3793 0.235772 16.4379 3.23008 16.269 3.77236L13.7103 11.3642C13.3 12.6374 12.8414 13.9106 12.4552 15.1602C11.7552 17.3764 10.9345 19.5927 10.2586 21.7854C10.0655 22.3748 9.92068 22.2333 8.5931 22.6813C7.91723 22.9171 7.98965 22.7992 7.84482 23.2C7.48275 24.2374 6.05861 28.3163 5.88965 28.9764Z"
                              fill="#F00908"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M2.58276 11.2227H11.8759L12.4069 9.59584L0 9.57227L1.85862 10.8454C2.07586 10.9869 2.2931 11.2227 2.58276 11.2227Z"
                              fill="#F00908"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M23.269 11.2227H31.669C33.1897 11.2227 32.6586 11.1284 34.0586 10.256L35.0483 9.59584L22.6655 9.57227C22.6897 9.83162 22.8345 10.1617 22.9069 10.3975C22.9552 10.5389 23.0035 10.6804 23.0517 10.8219C23.1241 11.0576 23.1 11.1284 23.269 11.2227Z"
                              fill="#F00908"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M23.9448 13.4628C25.3931 13.5571 27.1793 13.4628 28.6517 13.4628C29.4724 13.4628 29.4724 13.3449 30.269 12.779C30.5345 12.5904 30.7276 12.4961 30.969 12.3311C31.1862 12.166 31.5241 11.9774 31.669 11.8359H23.4138L23.9448 13.4628Z"
                              fill="#F00908"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M11.1034 13.4624L11.6345 11.812H3.35516C3.5724 11.9535 3.76551 12.1185 4.00689 12.2836C4.24827 12.4486 4.46551 12.5665 4.70689 12.7315C5.57585 13.321 5.55172 13.4624 6.34827 13.4624C7.94137 13.4624 9.53447 13.486 11.1034 13.4624Z"
                              fill="#F00908"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10.1379 16.3625L10.8379 14.2642H7C7.36207 14.5942 8.08621 15.0186 8.52069 15.3251L9.31724 15.8438C9.58276 16.0325 9.87241 16.2682 10.1379 16.3625Z"
                              fill="#F00908"/>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M24.9103 16.3625L27.2758 14.83C27.3965 14.7357 27.4931 14.665 27.6621 14.5707L27.9759 14.3585C28.0241 14.3113 28.0241 14.3113 28.0724 14.2642H24.2586L24.9103 16.3625Z"
                              fill="#F00908"/>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="35" height="29" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </i>
        </a>

    </div>

</nav>
