import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// components
import { TabListTwoItemsComponent } from './tab-list-two-items/tab-list-two-items.component';


@NgModule({
    declarations: [
        TabListTwoItemsComponent,
    ],
    exports: [
        TabListTwoItemsComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
    ],
})

export class TabsModule {}
