import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    filter,
    switchMap,
} from 'rxjs/operators';

import { Capsule } from '../schemas/capsule.schemas';
import { Widget } from '../schemas/widget.schemas';
import * as capsuleSelectors from '../store/selectors/capsule.selectors';
import * as widgetSelectors from '../store/selectors/widget.selectors';


const logger = new Logger('CapsuleFacade');

@Injectable({
    providedIn: 'root',
})
export class CapsuleFacade {

    constructor(
        private store$: Store,
    ) { }

    get isLoading$(): Observable<boolean> {
        return this.store$.pipe(
            select(capsuleSelectors.selectIsLoading),
        );
    }

    get currentCapsule$(): Observable<Capsule | null> {
        return this.store$.pipe(
            select(capsuleSelectors.selectCurrentCapsule),
        );
    }

    get widgets$(): Observable<Widget[]> {
        return this.currentCapsule$.pipe(
            filter(capsule => !!capsule?.widgetIds?.length),
            switchMap(capsule =>
                this.store$.pipe(
                    select(widgetSelectors.selectWidgets, {ids: capsule?.widgetIds || []}),
                ),
            ),
        );
    }

}
