<div class="widget-audio-player">

  <div class="widget-audio-player__content">
    <div>Автор</div>

    <div class="widget-audio-player__tools">

      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M18.4 21.8L6.39999 12.8C5.86666 12.4 5.86666 11.6 6.39999 11.2L18.4 2.2C19.0592 1.70557 20 2.17595 20 3V21C20 21.824 19.0592 22.2944 18.4 21.8ZM18 5L8.66667 12L18 19V5ZM3.99998 21.9995H5.99998V1.99955H3.99998V21.9995Z"
              fill="white"/>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M9.00002 2H7.00002C5.89545 2 5.00002 2.89543 5.00002 4V20C5.00002 21.1046 5.89545 22 7.00002 22H9.00002C10.1046 22 11 21.1046 11 20V4C11 2.89543 10.1046 2 9.00002 2ZM17 2H15C13.8955 2 13 2.89543 13 4V20C13 21.1046 13.8955 22 15 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2ZM7.00002 4V20H9.00003V4H7.00002ZM15 20V4H17V20H15Z"
              fill="white"/>
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.00002 21V3C4.00002 2.17595 4.94078 1.70557 5.60002 2.2L17.6 11.2C17.8647 11.3985 17.998 11.6956 18 11.9934V2H20V22H18V12.0066C17.998 12.3044 17.8647 12.6015 17.6 12.8L5.60002 21.8C4.94078 22.2944 4.00002 21.824 4.00002 21ZM5.99999 19L15.3333 12L5.99999 5V19Z"
              fill="white"/>
      </svg>

    </div>

    <div class="widget-audio-player__text">Название трека Название трека</div>
  </div>

  <div class="widget-audio-player__title">Аудиогид</div>

</div>
