import {
    Component,
    OnInit,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';

import { PhoneFacade } from '../../../facades/phone.facade';


@Component({
    selector: 'app-auth-forgot-password-step-first',
    templateUrl: './auth-forgot-password-step-first.component.html',
    styleUrls: ['./auth-forgot-password-step-first.component.scss'],
})
export class AuthForgotPasswordStepFirstComponent implements OnInit {

    isLoading$: Observable<boolean> = this.phoneFacade.isLoading$;
    error$: Observable<schemas.ResponseError | null> = this.phoneFacade.error$;

    constructor(
        private phoneFacade: PhoneFacade,
    ) { }

    ngOnInit(): void {
    }

    onFormSubmit(form: { phone: string }): void {
        this.phoneFacade.sendPasswordResetCode(form.phone);
    }

}
