import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AboutFacade } from '../../../facades/about.facade';
import { FavoriteFacade } from '../../../facades/favorite.facade';
import { Card } from '../../../schemas/about.schemas';
import { Location } from '../../../schemas/location.schemas';


@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {

    @Input() location: Observable<Location | null> | undefined;

    card$: Observable<Card> = this.aboutFacade.card$;
    images$: Observable<string[]> = this.aboutFacade.images$;

    constructor(private aboutFacade: AboutFacade,
                private favoriteFacade: FavoriteFacade,
    ) {}

    ngOnInit(): void {}

    onToggleLocationFavorite(location: Location): void {
        this.favoriteFacade.toggleLocationFavorite(location);
    }

}
