<div class="float-panel"
     (swipeStart)="onSwipeStart($event)"
     (swipeMove)="onSwipeMove($event)"
     (swipeEnd)="onSwipeEnd()"
     swipable #panel>
    <div class="float-panel__header">
        <div *ngIf="canSwipe" class="float-panel__pull"></div>
        <ng-content select="[slot=header]"></ng-content>
    </div>
    <div class="float-panel__content">
        <ng-content select="[slot=content]"></ng-content>
    </div>
</div>
