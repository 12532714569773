import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    selector: 'app-account-external-services',
    templateUrl: './account-external-services.component.html',
    styleUrls: ['./account-external-services.component.scss'],
})
export class AccountExternalServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
