import {
    createAction,
    props,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';
import { Location } from '../../schemas/location.schemas';


export const initAction = createAction(
    '[Favorite] Init',
);

export const restoreAction = createAction(
    '[Favorite] Restore',
    props<{ locations: schemas.FavoriteLocation[], folders: schemas.Folder[] }>(),
);

export const syncAction = createAction(
    '[Favorite] Sync',
);

export const toggleFavoriteLocationAction = createAction(
    '[Favorite] Toggle Location To Favorites',
    props<{ locationId: number }>(),
);

export const createFolderAction = createAction(
    '[Favorite] Create Folder',
    props<{ locationLeft: Location, locationRight: Location }>(),
);

export const createFolderSuccessAction = createAction(
    '[Favorite] Create Folder Success',
    props<{ locations: number[], folderId: number }>(),
);

export const createFolderFailureAction = createAction(
    '[Favorite] Create Folder Failure',
    props<{ error: schemas.ResponseError }>(),
);

export const updateFolderTitleAction = createAction(
    '[Favorite] Update Folder Title',
    props<{ folderId: number | undefined, newValue: string | undefined }>(),
);

export const ungroupLocationsAction = createAction(
    '[Favorite] Ungroup Favorite Locations',
    props<{ folderId: number | undefined }>(),
);

export const deleteFavoriteLocationFromFolderAction = createAction(
    '[Favorite] Delete Favorite Location From Folder Action',
    props<{ locationId: number | undefined }>(),
);

export const addFavoriteLocationInFolderAction = createAction(
    '[Favorite] Add Favorite Location In Group Action',
    props<{ folderId: number | undefined, locationId: number | undefined }>(),
);
