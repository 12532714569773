import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { schemas } from '@scatch/ngx-app-lib';


function matchValues(matchTo: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        // @ts-ignore
        return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
            ? null
            : {isMatching: false};
    };
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-auth-phone-forgot-password-step-third',
    templateUrl: './auth-phone-forgot-password-step-third.component.html',
    styleUrls: ['./auth-phone-forgot-password-step-third.component.scss'],
})
export class AuthPhoneForgotPasswordStepThirdComponent implements OnInit {

    forgotCreatePasswordForm: FormGroup;

    @Input() formError: schemas.ResponseError | null = null;
    @Input() cannotSubmit: boolean | null = null;
    @Output() formSubmit = new EventEmitter();

    constructor(
        private router: Router,
    ) {

        this.forgotCreatePasswordForm = new FormGroup({

            password: new FormControl('', [
                Validators.required,
                Validators.pattern(/^[A-z0-9]{6,}$/),
            ]),

            passwordRepeat: new FormControl('', [
                Validators.required,
                Validators.pattern(/^[A-z0-9]{6,}$/),
                matchValues('password'),
            ]),

        });
    }

    ngOnInit(): void {
    }

    onFormChange(): void {
        this.formError = null;
    }

    onSubmit(): void {
        this.formSubmit.emit(this.forgotCreatePasswordForm.value);
    }

}
