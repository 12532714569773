<div class="scan">

    <div class="scan__switcher">
        <span class="switcher-label">Демо-режим</span>
        <label class="switcher">
            <input type="checkbox" class="switcher-input" checked disabled>
            <span class="switcher-indicator">
        <span class="switcher-yes"></span>
        <span class="switcher-no"></span>
      </span>
        </label>
    </div>

    <div class="scan-slider-wrapper">
        <div class="scan__slider">
            <ui-slider-simple [images]="demoCodes$ | async"
                              (clickSliderItem)="onClickSliderItem($event)">
            </ui-slider-simple>
        </div>
    </div>

    <i>
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="90" viewBox="0 0 92 90" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 11.1667C0 5.24395 4.95709 0.5 11 0.5H26C26.5523 0.5 27 0.947715 27 1.5C27 2.05228 26.5523 2.5 26 2.5H11C5.99721 2.5 2 6.41188 2 11.1667V25.6667C2 26.219 1.55228 26.6667 1 26.6667C0.447715 26.6667 0 26.219 0 25.6667V11.1667ZM65 1.5C65 0.947715 65.4477 0.5 66 0.5H81C87.0429 0.5 92 5.24394 92 11.1667V25.6667C92 26.219 91.5523 26.6667 91 26.6667C90.4477 26.6667 90 26.219 90 25.6667V11.1667C90 6.41188 86.0028 2.5 81 2.5H66C65.4477 2.5 65 2.05228 65 1.5ZM1 63.3333C1.55228 63.3333 2 63.7811 2 64.3333V78.8333C2 83.5881 5.99721 87.5 11 87.5H26C26.5523 87.5 27 87.9477 27 88.5C27 89.0523 26.5523 89.5 26 89.5H11C4.95709 89.5 0 84.7561 0 78.8333V64.3333C0 63.7811 0.447715 63.3333 1 63.3333ZM91 63.3333C91.5523 63.3333 92 63.7811 92 64.3333V78.8333C92 84.7561 87.0429 89.5 81 89.5H66C65.4477 89.5 65 89.0523 65 88.5C65 87.9477 65.4477 87.5 66 87.5H81C86.0028 87.5 90 83.5881 90 78.8333V64.3333C90 63.7811 90.4477 63.3333 91 63.3333Z"
                  fill="#f00908"/>
        </svg>
    </i>

    <div class="scan__text">Выберите QR-код для начала работы</div>

</div>
