import {
    ApplicationRef,
    Injectable,
} from '@angular/core';
import {
    SwUpdate,
    UpdateActivatedEvent,
    UpdateAvailableEvent,
} from '@angular/service-worker';
import { Logger } from '@scatch/ngx-app-lib';
import { ToastrService } from 'ngx-toastr';
import {
    concat,
    interval,
} from 'rxjs';
import { first } from 'rxjs/operators';


const logger = new Logger('UpdateService');

@Injectable({
    providedIn: 'root',
})
export class UpdateService {

    constructor(
        private appRef: ApplicationRef,
        private toastrService: ToastrService,
        private updates: SwUpdate,
    ) { }

    listen(): void {
        if (this.updates.isEnabled) {
            this.logUpdate();
            this.notifyUpdate();
            this.checkForUpdate();
            this.handleUnrecoverable();
        }
    }

    private logUpdate(): void {
        this.updates.available.subscribe((event: UpdateAvailableEvent) => {
            logger.info('available: current version is', event.current);
            logger.info('available: available version is', event.available);
        });
        this.updates.activated.subscribe((event: UpdateActivatedEvent) => {
            logger.info('activated: old version was', event.previous);
            logger.info('activated: new version is', event.current);
        });
    }

    private checkForUpdate(): void {
        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const isStableApp$ = this.appRef.isStable.pipe(
            first(isStable => isStable),
        );
        const everyOneHour$ = interval(60 * 60 * 1000);
        const everyOneHourOnceAppIsStable$ = concat(isStableApp$, everyOneHour$);

        everyOneHourOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
    }

    private notifyUpdate(): void {
        this.updates.available.subscribe(() => {
            this.notifyUser(() => {
                logger.info('notifyUpdate: updating to new version');
                this.updates.activateUpdate()
                    .then(() => this.reloadApp());
            });
        });
    }

    private notifyUser(onTap: () => void): void {
        const toast = this.toastrService.warning(
            'Кликните, чтобы обновить',
            'Нужно обновиться!',
            {
                disableTimeOut: true,
            },
        );
        toast.onTap.subscribe(onTap);
    }

    reloadApp(): void {
        document.location.reload();
        logger.info('reloadApp: The app is updating right now');
    }

    private handleUnrecoverable(): void {
        this.updates.unrecoverable.subscribe(() => this.notifyUser(
            () => this.reloadApp(),
        ));
    }

}
