import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import {
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { ConnectionFacade } from '../../../facades/connection.facade';
import { FavoriteFacade } from '../../../facades/favorite.facade';
import {
    Folder,
    Location,
} from '../../../schemas/location.schemas';


const logger = new Logger('FavoriteListComponent');

@Component({
    selector: 'app-favorite-list',
    templateUrl: './favorite-list.component.html',
    styleUrls: ['./favorite-list.component.scss'],
})
export class FavoriteListComponent implements OnInit, OnDestroy {

    locations: Location[] = [];
    folders: Folder[] = [];

    subscriptions: Subscription[] = [];
    swipedLocation?: Location;
    currentConnectedLocationId?: number;

    constructor(
        private favoriteFacade: FavoriteFacade,
        private connectionFacade: ConnectionFacade,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.paramMap.pipe(
                tap(params => logger.debug('params', params, this.route)),
                map(params => Number(params.get('id'))),
                tap(id => logger.debug('route(id)', id)),
                switchMap(id => {
                    return this.favoriteFacade.fetchFavoriteLocations(id);
                }),
            ).subscribe(data => this.locations = data),
        );

        this.subscriptions.push(
            this.favoriteFacade.folders$.subscribe(data => this.folders = data),
        );

        this.subscriptions.push(
            this.connectionFacade.currentLocationId$.subscribe(locationId => {
                this.currentConnectedLocationId = locationId;
            }),
        );
    }

    dropCapsule(event: CdkDragDrop<Location[] | any>): boolean {
        if (event.previousIndex !== event.currentIndex) {

            this.favoriteFacade.createNewFolder(
                event.previousContainer.data[event.previousIndex],
                event.previousContainer.data[event.currentIndex],
            );
        }

        return true;
    }

    drop(event: CdkDragDrop<Folder[] | any>): void {
        const folderId = event.container.data[event.currentIndex].id;
        const locationId = event.previousContainer.data[event.previousIndex].id;

        if (folderId && locationId) {
            this.favoriteFacade.addFavoriteLocationInGroup(folderId, locationId);
        }
    }

    deleteCapsuleFavourites(id: number): void {
        this.favoriteFacade.deleteFavoriteLocationFromFolder(id);
    }

    onLocationConnect(location: Location): void {
        if (location) {
            this.connectionFacade.connect(location);
        }
    }

    onLocationSwipe(location: Location): void {
        logger.debug('onLocationSwipe');
        this.swipedLocation = location;
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

}
