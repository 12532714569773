import {
    createReducer,
    on,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import * as favoriteActions from '../actions/favorite.actions';


export const DEFAULT_FAVORITE_FEATURE_NAME = 'favorite';

export interface FavoriteReducerState {
    locations: schemas.FavoriteLocation[];
    folders: schemas.Folder[];
}

const initialState: FavoriteReducerState = {
    locations: [],
    folders: [],
};

export const favoriteReducer = createReducer(
    initialState,
    on(
        favoriteActions.restoreAction,
        (state, {locations, folders}) => ({
            ...state,
            locations,
            folders,
        }),
    ),
);
