import {
    Component,
    OnInit,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';

import { AuthFacade } from '../../../facades/auth.facade';


@Component({
    selector: 'app-auth-forgot-password-step-third',
    templateUrl: './auth-forgot-password-step-third.component.html',
    styleUrls: ['./auth-forgot-password-step-third.component.scss'],
})
export class AuthForgotPasswordStepThirdComponent implements OnInit {

    isLoading$: Observable<boolean> = this.authFacade.isLoading$;
    authError$: Observable<schemas.ResponseError | null> = this.authFacade.error$;

    constructor(
        private authFacade: AuthFacade,
    ) { }

    ngOnInit(): void {
    }

    onFormSubmit(form: { password: string }): void {
        this.authFacade.resetPassword(form.password);
    }

}
