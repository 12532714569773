import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsModule } from '../../ui/tabs/tabs.module';
import { UiModule } from '../../ui/ui.module';
import { CardsModule } from '../cards/cards.module';
import { ContactsModule } from '../contacts/contacts.module';
import { LocationsModule } from '../locations/locations.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { MapDefaultComponent } from './map-default/map-default.component';
import { MapComponent } from './map/map.component';


const exports = [
    MapComponent,
    MapDefaultComponent,
];

@NgModule({
    declarations: [
        ...exports,
    ],
    imports: [
        CommonModule,
        UiModule,
        TabsModule,
        LocationsModule,
        CardsModule,
        ContactsModule,
        WidgetsModule,
    ],
    exports,
})
export class MapModule {}
