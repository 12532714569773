import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import {
    DEFAULT_FAVORITE_FEATURE_NAME,
    FavoriteReducerState,
} from '../reducers/favorite.reducer';


export const selectFavorite = createFeatureSelector<FavoriteReducerState>(DEFAULT_FAVORITE_FEATURE_NAME);

export const selectLocations = createSelector(
    selectFavorite,
    state => state.locations,
);

export const selectFolders = createSelector(
    selectFavorite,
    state => state.folders,
);

export const selectFavoriteLocation = createSelector(
    selectLocations,
    (locations: schemas.FavoriteLocation[], props: { locationId: number }) => {
        return locations.findIndex(item => item.locationId === props.locationId) > -1;
    },
);
