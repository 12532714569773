<form [formGroup]="forgotForm" (input)="onFormChange()" (submit)="onSubmit()" novalidate autocomplete="off">

  <div class="form-group">
    <div class="form-group__text">Не удается войти? Введите номер телефона, привязанный к учётной записи iTerra.</div>
  </div>

  <div class="form-group">

    <div class="title-form-group">Номер телефона</div>

    <div class="input-group">

      <input data-test-id="form-phone" type="tel" formControlName="phone" mask='(000) 000-00-00' prefix="+7 " class="form-control" tabindex="1" placeholder="+7 012 345 67 89" required>
      <span data-test-id="form-phone-error" class="invalid-feedback" *ngIf="forgotForm.controls['phone'].invalid && forgotForm.controls['phone'].touched">
          Некорректно заполненное поле
      </span>

    </div>

  </div>

  <div class="form-group">
    <button data-test-id="form-phone-submit" type="submit" tabindex="2" class="btn btn-primary sc-wide" [disabled]="cannotSubmit || forgotForm.invalid || !!formError">Продолжить</button>
  </div>

  <div class="form-group">
    <a data-test-id="form-phone-back" routerLink="/auth" tabindex="3">Вернуться ко входу</a>
  </div>

</form>
