import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    Logger,
    utils,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    catchError,
    first,
    map,
    switchMap,
} from 'rxjs/operators';

import { AuthPhoneApi } from '../../services/api/auth/auth-phone.api';
import * as phoneActions from '../actions/phone.actions';


const log = new Logger('PhoneEffects');

@Injectable()
export class PhoneEffects {

    constructor(
        private actions$: Actions,
        private authPhoneApi: AuthPhoneApi,
    ) {}

    sendCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(phoneActions.sendCodeAction),
            switchMap(({phone}) => this.authPhoneApi.sendCode(phone).pipe(
                map(() => phoneActions.sendCodeSuccessAction({
                    sentAt: Date.now(),
                    phone,
                })),
                catchError(response => of(phoneActions.sendCodeFailureAction({
                    error: utils.formatErrors(response),
                }))),
                first(),
            )),
        ),
    );

    sendSignUpCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(phoneActions.sendSignUpCodeAction),
            switchMap(({phone}) => this.authPhoneApi.sendCode(phone).pipe(
                map(() => phoneActions.sendSignUpCodeSuccessAction({
                    sentAt: Date.now(),
                    phone,
                })),
                catchError(response => of(phoneActions.sendSignUpCodeFailureAction({
                    error: utils.formatErrors(response),
                }))),
                first(),
            )),
        ),
    );

    sendSignInCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(phoneActions.sendSignInCodeAction),
            switchMap(({phone}) => this.authPhoneApi.sendCode(phone).pipe(
                map(() => phoneActions.sendSignInCodeSuccessAction({
                    sentAt: Date.now(),
                    phone,
                })),
                catchError(response => of(phoneActions.sendSignInCodeFailureAction({
                    error: utils.formatErrors(response),
                }))),
                first(),
            )),
        ),
    );

    sendPasswordResetCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(phoneActions.sendPasswordResetCodeAction),
            switchMap(({phone}) => this.authPhoneApi.sendCode(phone).pipe(
                map(() => phoneActions.sendPasswordResetCodeSuccessAction({
                    sentAt: Date.now(),
                    phone,
                })),
                catchError(response => of(phoneActions.sendPasswordResetCodeFailureAction({
                    error: utils.formatErrors(response),
                }))),
                first(),
            )),
        ),
    );

}
