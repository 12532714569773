import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    Card,
    Contact,
    Widget,
} from '../../schemas/about.schemas';


const log = new Logger('AboutService');

@Injectable({
    providedIn: 'root',
})
export class AboutService {

    constructor(
        private http: HttpClient,
    ) { }

    getAboutData(id: number | null): Observable<Card> {
        const fileName = 'assets/json/about/' + id + '/card.json';
        return this.http.get<Card>(fileName);
    }

    getContactsData(id: number | null): Observable<Contact[]> {
        const fileName = 'assets/json/about/' + id + '/contacts.json';
        return this.http.get<Contact[]>(fileName);
    }

    getWidgetsData(id: number | null): Observable<Widget[]> {
        const fileName = 'assets/json/about/' + id + '/widgets.json';
        return this.http.get<Widget[]>(fileName);
    }

}
