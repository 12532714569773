import { NgModule } from '@angular/core';
import { PhoneFormatPipe } from './phone-format.pipe';


@NgModule({
    declarations: [
        PhoneFormatPipe,
    ],
    imports: [],
    exports: [
        PhoneFormatPipe,
    ],
})
export class PipesModule {}
