import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    Logger,
    utils,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    catchError,
    first,
    map,
    switchMap,
} from 'rxjs/operators';

import { Authorization } from '../../schemas/auth.schemas';
import { AuthPasswordApi } from '../../services/api/auth/auth-password.api';
import * as passwordActions from '../actions/password.actions';


const log = new Logger('PasswordEffects');

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class PasswordEffects {

    constructor(
        private actions$: Actions,
        private router: Router,
        private authPasswordApi: AuthPasswordApi,
    ) {}

    // noinspection JSUnusedGlobalSymbols
    resetPasswordEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(passwordActions.resetAction),
            switchMap(({password}) => this.authPasswordApi.reset(password).pipe(
                map((authorization: Authorization) => passwordActions.resetSuccessAction({
                    authorization,
                })),
                catchError(response => of(passwordActions.resetFailureAction({
                    error: utils.formatErrors(response),
                }))),
                first(),
            )),
        ),
    );

}
