import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardSimpleComponent } from './card-simple/card-simple.component';
import { SlidersUiModule } from '@scatch/ngx-app-lib';
import {SocialUiModule} from '../social/social.module';


@NgModule({
    declarations: [
        CardSimpleComponent,
    ],
    exports: [
        CardSimpleComponent,
    ],
    imports: [
        CommonModule,
        SlidersUiModule,
        SocialUiModule,
    ],
})
export class CardsUiModule { }
