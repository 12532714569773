import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import {
    Folder,
    Location,
} from '../schemas/location.schemas';
import { StoreService } from '../services/store.service';
import * as favoriteActions from '../store/actions/favorite.actions';
import * as favoriteSelectors from '../store/selectors/favorite.selectors';


const logger = new Logger('FavoriteFacade');

@Injectable({
    providedIn: 'root',
})
export class FavoriteFacade {

    constructor(private store$: Store,
                private storeService: StoreService) {}

    get isLoading$(): Observable<boolean> {
        return of(false);
    }

    get folders$(): Observable<Folder[]> {
        return this.store$.pipe(
            select(favoriteSelectors.selectFolders),
            tap(folders => logger.debug('folders$ (folders)', folders)),
        );
    }

    fetchFavoriteLocation(location: Location): Observable<boolean> {
        return this.store$.pipe(
            select(favoriteSelectors.selectFavoriteLocation, {locationId: location.id}),
        );
    }

    fetchFavoriteLocations(folderId: number): Observable<Location[]> {
        return this.store$.pipe(
            select(favoriteSelectors.selectLocations),
            tap(favoriteLocations => logger.debug('locations$ (locations)', favoriteLocations)),
            map(favoriteLocations => {
                return favoriteLocations.filter(favoriteLocation => !isNaN(folderId) ? favoriteLocation.folderId === folderId : false);
            }),
            tap(favoriteLocations => logger.debug('favoriteLocations(id)', favoriteLocations)),
            switchMap((favoriteLocations) => {
                const ids = favoriteLocations.map(favoriteLocation => favoriteLocation.locationId);
                return this.storeService.fetchLocations(ids).pipe(
                    tap(locations => logger.debug('locations$ (locations)', locations)),
                );
            }),
        );
    }

    createNewFolder(locationLeft: Location, locationRight: Location): void {
        if (locationLeft && locationRight) {
            this.store$.dispatch(favoriteActions.createFolderAction({locationLeft, locationRight}));
        }
    }

    deleteFavoriteLocationFromFolder(locationId: number | undefined): void {
        if (locationId) {
            this.store$.dispatch(favoriteActions.deleteFavoriteLocationFromFolderAction({locationId}));
        }
    }

    updateTitleFolder(folderId: number | undefined, newValue: string | undefined): void {
        if (folderId && newValue) {
            this.store$.dispatch(favoriteActions.updateFolderTitleAction({folderId, newValue}));
        }
    }

    unGroupFolder(folderId: number | undefined): void {
        if (folderId) {
            this.store$.dispatch(favoriteActions.ungroupLocationsAction({folderId}));
        }
    }

    addFavoriteLocationInGroup(folderId: number, locationId: number): void {
        if (folderId && locationId) {
            this.store$.dispatch(favoriteActions.addFavoriteLocationInFolderAction({folderId, locationId}));
        }
    }

    toggleLocationFavorite(location: Location): void {
        if (location.id) {
            this.store$.dispatch(favoriteActions.toggleFavoriteLocationAction({locationId: location.id}));
        }
    }

}
