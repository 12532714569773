<div class="connect-capsule"
     [ngClass]="{'connect-capsule--nesting': capsule?.parent_id, 'connect-capsule--active': capsule?.active}">

    <div>
        <div class="connect-capsule__icon" [ngStyle]="{'background-image': 'url('+ capsule?.icon +')'}"></div>
        <div class="connect-capsule__text">{{ capsule?.title }}</div>
    </div>

    <div class="connect-capsule__tools">

        <div>

            <i [hidden]="capsule?.dynamic_qr_code">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="24 / basic / link">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                              d="M10 15V17H6C3.23858 17 1 14.7614 1 12C1 9.23858 3.23858 7 6 7H10V9H6C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15H10ZM14 9V7H18C20.7614 7 23 9.23858 23 12C23 14.7614 20.7614 17 18 17H14V15H18C19.6569 15 21 13.6569 21 12C21 10.3431 19.6569 9 18 9H14ZM17 11H7V13H17V11Z"
                              fill="#66686D"/>
                    </g>
                </svg>
            </i>

            <i [hidden]="!capsule?.dynamic_qr_code">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="24 / basic / scan">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                              d="M16 2H20.1818C21.186 2 22 2.89543 22 4V8H20V4H16V2ZM2 13V11H22V13H2ZM16 22V20H20V16H22V20C22 21.1046 21.186 22 20.1818 22H16ZM3.81818 22H8V20H4V16H2V20C2 21.1046 2.81403 22 3.81818 22ZM8 4V2H3.81818C2.81403 2 2 2.89543 2 4V8H4V4H8Z"
                              fill="#66686D"/>
                    </g>
                </svg>
            </i>

        </div>

        <div [hidden]="capsule?.dynamic_qr_code" class="connect__favorites">

            <i [hidden]="capsule?.isFavorites">
                <ui-social-item [social]="{image: '/assets/images/social/star.svg'}"
                                (click)="setCapsuleFavourites(capsule?.id)"></ui-social-item>
            </i>

            <i [hidden]="!capsule?.isFavorites">
                <ui-social-item [social]="{image: '/assets/images/social/star-full.svg'}"
                                (click)="deleteCapsuleFavourites(capsule?.id)"></ui-social-item>
            </i>

        </div>

        <div [hidden]="!capsule?.isConnect">
            <i (click)="deleteLocation(capsule?.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="24 / basic / plus">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                              d="M12 10.5858L18.3639 4.22183L19.7782 5.63604L13.4142 12L19.7782 18.364L18.3639 19.7782L12 13.4142L5.63602 19.7782L4.22181 18.364L10.5858 12L4.22181 5.63604L5.63602 4.22183L12 10.5858Z"
                              fill="white"/>
                    </g>
                </svg>
            </i>
        </div>

    </div>

</div>
