import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { AccountProfileFormComponent } from './account-profile-form/account-profile-form.component';
import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { AccountSecurityFormComponent } from './account-security-form/account-security-form.component';
import { AccountExternalServicesFormComponent } from './account-external-services-form/account-external-services-form.component';


@NgModule({
    declarations: [
        AccountProfileFormComponent,
        AccountSecurityFormComponent,
        AccountExternalServicesFormComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
    ],
    exports: [
        AccountProfileFormComponent,
        AccountExternalServicesFormComponent,
        AccountSecurityFormComponent,
    ],
})

export class AccountUiModule {}
