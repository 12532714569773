import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    select,
    Store,
} from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { Widget } from '../schemas/widget.schemas';
import * as widgetActions from '../store/actions/widget.actions';
import * as capsuleSelectors from '../store/selectors/capsule.selectors';
import * as widgetSelectors from '../store/selectors/widget.selectors';


const logger = new Logger('WidgetFacade');

@Injectable({
    providedIn: 'root',
})
export class WidgetFacade {

    constructor(
        private store$: Store,
        private router: Router,
    ) { }

    get isLoading$(): Observable<boolean> {
        return this.store$.pipe(
            select(widgetSelectors.selectIsLoading),
        );
    }

    get currentWidget$(): Observable<Widget | null> {
        return this.store$.pipe(
            select(widgetSelectors.selectCurrentWidget),
        );
    }

    openWidget(widget: Widget): void {
        logger.debug('openWidget', widget);
        // todo: move to another place
        this.store$.pipe(
            select(capsuleSelectors.selectCurrentCapsule),
            first(),
        ).subscribe(capsule => {
            if (capsule) {
                const widgetUri = widget.manifest.uri.startsWith('widgets')
                    ? [widget.manifest.uri, capsule.id]
                    : ['capsules', capsule.id, 'widgets', widget.id];

                this.router.navigate(widgetUri)
                    .catch(reason => logger.error('Navigate failed', reason));
            }
        });
    }

    fetchWidgetStore$(name: string): Observable<any | undefined> {
        return this.store$.pipe(
            select(widgetSelectors.selectWidgetStore, {name}),
        );
    }

    upsertWidgetStore(name: string, content: any): void {
        this.store$.dispatch(
            widgetActions.setWidgetStore({name, content}),
        );
    }

}
