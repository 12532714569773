import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    first,
    map,
} from 'rxjs/operators';

import { AuthFacade } from '../facades/auth.facade';


const log = new Logger('AuthGuard');

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {

    constructor(
        private router: Router,
        private authFacade: AuthFacade,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isAuth().pipe(
            map(isAuth => {
                if (!isAuth) {
                    // not logged in so redirect to login page with the return url
                    this.router.navigate(['/auth'], {queryParams: {'redirect_uri': state.url}})
                        .catch(reason => log.error('Navigate failed', reason));
                }
                return isAuth;
            }),
        );
    }

    canLoad(
        route: Route,
        segments: UrlSegment[],
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.isAuth();
    }

    private isAuth(): Observable<boolean> {
        return this.authFacade.isAuth$.pipe(first());
    }

}
