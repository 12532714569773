import {
    createAction,
    props,
} from '@ngrx/store';

import { WorkTableStyle } from '../../schemas/layout.schemas';


export const setLayoutTitleAction = createAction(
    '[Layout] Set Title',
    props<{ title: string }>(),
);

export const setLayoutBackgroundAction = createAction(
    '[Layout] Set Background',
    props<{ background: string }>(),
);

export const upsertWorkTableStyleAction = createAction(
    '[Layout] Upsert WorkTableStyle',
    props<{ workTableStyle: WorkTableStyle }>(),
);
