import {
    Component,
    OnInit,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';

import { PhoneFacade } from '../../../facades/phone.facade';


@Component({
    selector: 'app-auth-phone-sign-up-step-first',
    templateUrl: './auth-phone-sign-up-step-first.component.html',
    styleUrls: ['./auth-phone-sign-up-step-first.component.scss'],
})
export class AuthPhoneSignUpStepFirstComponent implements OnInit {

    isLoading$: Observable<boolean> = this.phoneFacade.isLoading$;
    error$: Observable<schemas.ResponseError | null> = this.phoneFacade.error$;

    constructor(
        private phoneFacade: PhoneFacade,
    ) { }

    ngOnInit(): void { }

    onFormSubmit(form: { phone: string }): void {
        this.phoneFacade.sendSignUpCode(form.phone);
    }

}
