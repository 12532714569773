<div class="location-card">

    <div class="card border-0 shadow-none">

        <ui-slider-info [images]="images" class="card-img-top"></ui-slider-info>

        <div class="card-body location-card__body">
            <h4 class="card-title location-card__title">{{ card?.title }}</h4>
            <p class="card-text location-card__text">{{ card?.text }}</p>
        </div>

    </div>

</div>
