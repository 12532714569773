import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    of,
} from 'rxjs';

import { Notification } from '../schemas/notification.schemas';


@Injectable({
    providedIn: 'root',
})
export class NotificationsFacade {

    private notificationsSubject = new BehaviorSubject<Array<Notification>>([]);
    private notifications: Array<Notification> = [];

    private capsuleNotificationsSubject = new BehaviorSubject<Array<Notification>>([]);
    private capsuleNotifications: Array<Notification> = [];

    private titleForCapsule: string | undefined;

    constructor() { }

    get isLoading$(): Observable<boolean> {
        return of(false);
    }

    get locations$(): Observable<Array<Notification>> {
        return this.notificationsSubject.asObservable();
    }

    get capsuleNotifications$(): Observable<Array<Notification>> {
        return this.capsuleNotificationsSubject.asObservable();
    }

    get capsuleTitle(): string | undefined {
        return this.titleForCapsule;
    }

    loadNotifications(): void {

        this.notifications =
            [
                {
                    variant: 'bg-dark',
                    icon: '/assets/images/dosaaf/driving-school.png',
                    title: 'Автошкола',
                    time: 1619518017827,
                    amount: 2,
                    message: 'Не пропусти первое занятие в классе!',
                    capsule_id: 100,
                },
                {
                    variant: 'bg-dark',
                    icon: '/assets/images/dosaaf/irkutsk-dosaaf.png',
                    title: 'ДОСААФ Иркутск',
                    time: 1619518017827,
                    amount: 1,
                    message: 'Узнайте о самых важных героях страны!',
                    capsule_id: 101,
                },
            ];

        this.notificationsSubject.next(this.notifications);

    }

    loadCapsuleNotifications(id: string | null): void {

        if (id === '100') {

            this.titleForCapsule = 'Автошкола';

            this.capsuleNotifications =
                [
                    {
                        variant: 'bg-dark',
                        icon: '/assets/images/dosaaf/driving-school.png',
                        title: 'Расписание',
                        time: 1619518017827,
                        message: 'Не пропусти первое занятие в классе! Ждём 5 ноября, в 18:00 по адресу ул. Поленова, 18А',
                    },
                    {
                        variant: 'bg-dark',
                        icon: '/assets/images/dosaaf/driving-school.png',
                        title: 'Лента',
                        time: 1619518017827,
                        message: 'У вас один новый комментарий.',
                    },
                ];
        }

        if (id === '101') {
            this.titleForCapsule = 'ДОСААФ Иркутск';

            this.capsuleNotifications =
                [
                    {
                        variant: 'bg-dark',
                        icon: '/assets/images/dosaaf/irkutsk-dosaaf.png',
                        title: 'Лента',
                        time: 1619518017827,
                        message: 'У вас один новый комментарий.',
                    },
                ];
        }

        this.capsuleNotificationsSubject.next(this.capsuleNotifications);

    }

}
