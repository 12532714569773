import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ConnectionFacade } from '../../../facades/connection.facade';
import { FavoriteFacade } from '../../../facades/favorite.facade';
import {
    Location,
    LocationHistoryGroup,
} from '../../../schemas/location.schemas';


@Component({
    selector: 'app-history-list',
    templateUrl: './history-list.component.html',
    styleUrls: ['./history-list.component.scss'],
})
export class HistoryListComponent implements OnInit, OnDestroy {

    history: LocationHistoryGroup[] = [];
    swipedLocation?: Location;
    currentConnectedLocationId?: number;

    subscriptions: Subscription[] = [];

    constructor(private favoriteFacade: FavoriteFacade,
                private connectionFacade: ConnectionFacade,
    ) { }

    ngOnInit(): void {
        this.subscriptions.push(
            this.connectionFacade.fetchHistory().subscribe(data => {
                this.history = data;
            }),
        );

        this.subscriptions.push(
            this.connectionFacade.currentLocationId$.subscribe(locationId => {
                this.currentConnectedLocationId = locationId;
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onLocationConnect(location: Location): void {
        if (location) {
            this.connectionFacade.connect(location);
        }
    }

    onLocationSwipe(location: Location): void {
        this.swipedLocation = location;
    }
}
