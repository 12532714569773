import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    filter,
    map,
    switchMap,
} from 'rxjs/operators';

import {
    PhoneCode,
    PhoneCredentials,
} from '../schemas/auth.schemas';
import * as authActions from '../store/actions/auth.actions';
import * as passwordActions from '../store/actions/password.actions';
import * as authSelectors from '../store/selectors/auth.selectors';


const logger = new Logger('AuthFacade');

@Injectable({
    providedIn: 'root',
})
export class AuthFacade {

    constructor(
        private store$: Store,
    ) { }

    get isAuth$(): Observable<boolean> {
        return this.store$.pipe(
            select(authSelectors.selectIsInit),
            filter(isInit => isInit),
            switchMap(() => this.store$.pipe(
                select(authSelectors.selectIsAuth),
            )),
        );
    }

    get isGuest$(): Observable<boolean> {
        return this.isAuth$.pipe(
            map(isAuth => !isAuth),
        );
    }

    get isLoading$(): Observable<boolean> {
        return this.store$.pipe(
            select(authSelectors.selectIsLoading),
        );
    }

    get error$(): Observable<schemas.ResponseError | null> {
        return this.store$.pipe(
            select(authSelectors.selectError),
        );
    }

    signInAsGuest(): void {
        this.store$.dispatch(authActions.signInByDeviceAction());
    }

    signUpByPhoneCode(phoneCode: PhoneCode): void {
        this.store$.dispatch(authActions.signUpByPhoneCodeAction({phoneCode}));
    }

    signInByPhoneCode(phoneCode: PhoneCode): void {
        this.store$.dispatch(authActions.signInByPhoneCodeAction({phoneCode}));
    }

    signInByPhoneCredentials(credentials: PhoneCredentials): void {
        this.store$.dispatch(authActions.signInByPhoneCredentialsAction({credentials}));
    }

    signInByPasswordResetCode(phoneCode: PhoneCode): void {
        this.store$.dispatch(authActions.signInByPasswordResetCodeAction({phoneCode}));
    }

    resetPassword(password: string): void {
        this.store$.dispatch(passwordActions.resetAction({password}));
    }

    signOut(): void {
        this.store$.dispatch(authActions.signOutAction());
    }

}
