import {
    createReducer,
    on,
} from '@ngrx/store';

import { User } from '../../models/user.model';
import { Authorization } from '../../schemas/auth.schemas';
import {
    sendCodeToPhoneAction,
    sendCodeToPhoneFailureAction,
    sendCodeToPhoneSuccessAction,
    signInByPhoneCredentialsAction,
    signInFailureAction,
    signInSuccessAction,
    signOutSuccessAction,
    signUpByPhoneCodeAction,
    signUpFailureAction,
    signUpSuccessAction,
} from './auth.actions';


export const AUTH_FEATURE_NAME = 'auth';

export interface AuthState {
    loaded: boolean;
    loading: boolean;
    error: string;
    authorization?: Authorization | null;
    user?: User | null;
    codePhone?: string;
    codeSentAt?: number;
}

const initialState: AuthState = {
    loaded: true,
    loading: false,
    error: '',
};

export const authReducer = createReducer(
    initialState,
    on(signUpByPhoneCodeAction, state => ({
        ...state,
        loading: true,
    })),
    on(signInByPhoneCredentialsAction, state => ({
        ...state,
        loading: true,
    })),
    on(signInSuccessAction, signUpSuccessAction, (state, {authorization}) => ({
        ...state,
        authorization,
        loading: false,
    })),
    on(signInFailureAction, signUpFailureAction, (state, {error}) => ({
        ...state,
        loading: false,
        authorization: null,
        user: null,
        error,
    })),
    on(signOutSuccessAction, () => ({
        ...initialState,
        authorization: null,
        user: null,
    })),
    on(sendCodeToPhoneAction, (state, {phone}) => ({
        ...state,
        loading: true,
        codePhone: phone,
    })),
    on(sendCodeToPhoneSuccessAction, (state, {sentAt}) => ({
        ...state,
        loading: false,
        codeSentAt: sentAt,
    })),
    on(sendCodeToPhoneFailureAction, (state, {error}) => ({
        ...state,
        loading: false,
        error,
    })),
);
