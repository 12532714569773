import {
    Component,
    Input,
    OnInit,
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-capsule-home',
    templateUrl: './capsule-home.component.html',
    styleUrls: ['./capsule-home.component.scss'],
})
export class CapsuleHomeComponent implements OnInit {

    @Input() title: any;

    constructor() { }

    ngOnInit(): void {
    }

}
