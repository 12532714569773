import { ConnectGetStartedComponent } from '../modules/connect/connect-get-started/connect-get-started.component';
import { DemoCodesResolver } from '../resolvers/demo-codes.resolver';


export const scannerRoutes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/scanner/demo',
    },
    {
        path: 'demo',
        component: ConnectGetStartedComponent,
        resolve: {
            demoCodes: DemoCodesResolver,
        },
    },
];
