import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { LocationFacade } from '../../../facades/location.facade';


const logger = new Logger('LocationSlimComponent');

@Component({
    selector: 'app-location-slim',
    templateUrl: './location-slim.component.html',
    styleUrls: ['./location-slim.component.scss'],
})
export class LocationSlimComponent implements OnInit, OnChanges, OnDestroy {

    @Input() location?: schemas.Location;

    @Output() locationClick = new EventEmitter<schemas.Location>();

    locationTags?: string;
    widgetsTotal?: number;

    private subscriptions: Subscription[] = [];

    constructor(
        private locationFacade: LocationFacade,
    ) { }

    ngOnInit(): void {
        logger.debug('ngOnInit');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.location) {
            const location = changes.location.currentValue;
            this.unsubscribeAll();
            if (location) {
                this.loadTagsByLocationId(location.id);
                this.loadWorkTablesWidgets(location.capsuleId);
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
    }

    private unsubscribeAll(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    loadWorkTablesWidgets(workTableId: number): void {
        logger.debug('loadWorkTablesWidgets', workTableId);

        this.subscriptions.push(
            this.locationFacade.fetchWorkTablesWidgets([workTableId])
                .subscribe(this.processWorkTablesWidgets.bind(this)),
        );
    }

    processWorkTablesWidgets(workTablesWidgets: schemas.CapsuleWidget[]): void {
        logger.debug('processWorkTablesWidgets', workTablesWidgets);

        this.widgetsTotal = workTablesWidgets.length;
    }

    loadTagsByLocationId(locationId: number): void {
        logger.debug('loadLocationTags', locationId);

        this.subscriptions.push(
            this.locationFacade.fetchTagsByLocationIds([locationId])
                .subscribe(this.processTags.bind(this)),
        );
    }

    processTags(tags: schemas.Tag[]): void {
        logger.debug('processTags', tags);

        this.locationTags = tags.map(tag => tag.title).join(', ');
    }

    onLocationClick(): void {
        logger.debug('onLocationClick');

        this.locationClick.emit(this.location);
    }

}
