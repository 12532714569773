import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';
import {
    NotFoundComponent,
    NotFoundModule,
} from '@scatch/ngx-app-lib';
import { AppComponent } from '../app.component';

import { AuthGuard } from '../guards/auth.guard';
import { GuestGuard } from '../guards/guest.guard';

import { LayoutBlankComponent } from '../layout/layout-blank/layout-blank.component';
import { LayoutIterraWithBackComponent } from '../layout/layout-iterra-with-back/layout-iterra-with-back.component';
import { LayoutIterraWithoutNavbarComponent } from '../layout/layout-iterra-without-navbar/layout-iterra-without-navbar.component';
import { LayoutIterraComponent } from '../layout/layout-iterra/layout-iterra.component';
import { AccountSettingsComponent } from '../modules/account/account-settings/account-settings.component';
import { CapsuleHomeComponent } from '../modules/capsule/capsule-home/capsule-home.component';
import { CapsuleInfoComponent } from '../modules/capsule/capsule-info/capsule-info.component';

import { ConnectGetStartedComponent } from '../modules/connect/connect-get-started/connect-get-started.component';
import { FavoriteFolderComponent } from '../modules/favorite/favorite-folder/favorite-folder.component';
import { FavoriteListComponent } from '../modules/favorite/favorite-list/favorite-list.component';
import { LocationStructureComponent } from '../modules/locations/location-structure/location-structure.component';
import { MapDefaultComponent } from '../modules/map/map-default/map-default.component';
import { CapsuleNotificationListComponent } from '../modules/notification/capsule-notification-list/capsule-notification-list.component';
import { NotificationListComponent } from '../modules/notification/notification-list/notification-list.component';
import { authRoutes } from './auth.routes';
import { capsulesRoutes } from './capsules.routes';
import { connectionsRoutes } from './connections.routes';
import { scannerRoutes } from './scanner.routes';
import { widgetsRoutes } from './widgets.routes';


const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/scanner/demo',
    },
    {
        path: 'auth',
        component: LayoutBlankComponent,
        children: [...authRoutes],
        canActivate: [GuestGuard],
    },
    {
        path: 'connections',
        component: LayoutIterraComponent,
        children: [...connectionsRoutes],
        canActivate: [AuthGuard],
    },
    {
        path: 'scanner',
        component: LayoutIterraComponent,
        children: [...scannerRoutes],
        canActivate: [AuthGuard],
    },
    {
        path: 'account',
        component: LayoutIterraComponent,
        children: [
            {
                path: 'settings',
                component: AccountSettingsComponent,
            },
        ],
    },

    {
        path: 'map',
        component: LayoutIterraWithoutNavbarComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: MapDefaultComponent,
            },
        ],
    },
    {
        path: 'notification',
        component: LayoutIterraComponent,
        children: [
            {
                path: 'list',
                component: NotificationListComponent,
            },
            {
                path: 'capsule/:id',
                component: CapsuleNotificationListComponent,
            },
        ],
    },
    {
        path: 'favorites',
        component: LayoutIterraComponent,
        children: [
            {
                path: 'locations',
                pathMatch: 'full',
                component: FavoriteListComponent,
            },
        ],
    },
    {
        path: 'capsule',
        component: LayoutIterraComponent,
        children: [
            {
                path: 'info',
                component: CapsuleInfoComponent,
            },
            {
                path: 'folder',
                children: [
                    {
                        path: ':id',
                        component: FavoriteFolderComponent,
                    },
                ],
            },
            {
                path: 'home',
                component: CapsuleHomeComponent,
            },
        ],
    },
    {
        path: 'capsules',
        children: [...capsulesRoutes],
        canActivate: [AuthGuard],
    },
    {
        path: 'widgets',
        children: [...widgetsRoutes],
        canActivate: [AuthGuard],
    },
    {
        path: 'connect',
        component: LayoutIterraComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: AppComponent,
            },
            {
                path: 'get-started',
                component: ConnectGetStartedComponent,
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'locations',
        component: LayoutIterraWithBackComponent,
        children: [
            {
                path: ':id',
                children: [
                    {
                        path: 'structure',
                        component: LocationStructureComponent,
                    },
                ],
            },
        ],
        canActivate: [AuthGuard],
    },
    // 404 Not Found page
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [
        NotFoundModule,
        RouterModule.forChild(routes),
    ],
})
export class AppRoutingModule {}
