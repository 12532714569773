<div class="sc-auth">

  <ui-logo></ui-logo>

  <ui-auth-phone-sign-in-form
    [cannotSubmit]="isLoading$ | async"
    [formError]="error$ | async"
    (formSubmit)="onFormSubmit($event)">
  </ui-auth-phone-sign-in-form>

</div>
