import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Logger } from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import * as connectionActions from '../actions/connection.actions';


const logger = new Logger('HistoryEffects');

@Injectable()
export class HistoryEffects {

    constructor(
        private actions$: Actions,
    ) {}

    addHistoryEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                connectionActions.connectSuccessAction,
                connectionActions.setCurrentConnectionAction,
            ),
            tap((connection) => logger.debug('addHistoryEffect (connection)', connection)),
            switchMap((data) => of(localStorage.getItem('history')).pipe(
                map(history => {
                    let newHistory = [];
                    const currentConnection = {
                        locationId: data.connection.location.id,
                        date: new Date().getTime(),
                    };

                    if (history) {
                        newHistory = JSON.parse(history);
                    }
                    logger.debug('addHistoryEffect (currentConnection)', currentConnection);

                    newHistory.unshift(currentConnection);
                    localStorage.setItem('history', JSON.stringify(newHistory));
                }),
                first(),
            )),
        ),
        {dispatch: false},
    );

}
