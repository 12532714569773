import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { SwiperOptions } from 'swiper';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-slider-simple',
    templateUrl: './slider-simple.component.html',
    styleUrls: ['./slider-simple.component.css'],
})
export class SliderSimpleComponent implements OnInit {

    @Input() height: number | undefined = 250;
    @Input() images: any;

    @Output() clickSliderItem = new EventEmitter<number>();

    public config: SwiperOptions = {
        keyboard: true,
        pagination: false,
        loop: false,
    };

    constructor() {
    }

    ngOnInit(): void {
    }

    onClick(item: any): void {
        this.clickSliderItem.emit(item);
    }

}
