import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SocialUiModule } from '../social/social.module';
import { CapsuleHomeComponent } from './capsule-home/capsule-home.component';


@NgModule({
    declarations: [
        CapsuleHomeComponent,
    ],
    exports: [
        CapsuleHomeComponent,
    ],
    imports: [
        CommonModule,
        SocialUiModule,
        RouterModule,
    ],
})
export class CapsuleUiModule {}
