<div
     [class.panel]="panel"
     [class.point]="panel === false"
     [class.panel--small]="panel && currentStage === 312"
     [class.panel--middle]="panel && currentStage === 1"
     [class.panel--full]="panel && currentStage === 2"
     [class.point--small]="currentPointStage === 1"
     [class.point--middle]="currentPointStage === 2"
     [class.point--height]="currentPointStage === 3"
>
  <ng-content></ng-content>
</div>
