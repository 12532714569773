import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    selector: 'app-capsule-home',
    templateUrl: './capsule-home.component.html',
    styleUrls: ['./capsule-home.component.scss'],
})
export class CapsuleHomeComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
