import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    LocationApi,
    Logger,
    schemas,
    utils,
} from '@scatch/ngx-app-lib';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import * as locationActions from '../store/actions/location.actions';
import * as connectionSelectors from '../store/selectors/connection.selectors';


const logger = new Logger('ConnectionsGuard');

@Injectable({
    providedIn: 'root',
})
export class ConnectionsGuard implements CanActivate {

    constructor(
        private router: Router,
        private store$: Store,
        private locationApi: LocationApi,
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.store$.pipe(
            select(connectionSelectors.selectAllLocationsIds),
            tap(locationIds => logger.debug('canActivate (ids)', locationIds)),
            tap(locationIds => {
                if (!locationIds) {
                    this.router.navigate(['/scanner'])
                        .catch(reason => logger.error('Navigate failed', reason));
                }
            }),
            filter(locationIds => !!locationIds),
            switchMap(locationIds => this.locationApi.fetchLocations({locationIds}).pipe(
                tap(locations => logger.debug('canActivate (locations)', locations)),
                tap((locations: schemas.Location[]) => {
                    if (locations.length) {
                        this.store$.dispatch(locationActions.loadLocationsSuccessAction({
                            locations: locations.map(location => ({
                                ...location,
                                level: location.path.split(',').length,
                            })),
                        }));
                    } else {
                        this.router.navigate(['/scanner'])
                            .catch(reason => logger.error('Navigate failed', reason));
                    }
                }),
                map(locations => !!locations.length),
                catchError(response => {
                    logger.debug('canActivate (response)', response);
                    this.store$.dispatch(locationActions.loadLocationsFailureAction({
                        error: utils.formatErrors(response),
                    }));
                    return of(false);
                }),
            )),
            first(),
        );
    }

}
