import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FavoriteFacade } from '../../../facades/favorite.facade';
import { Location } from '../../../schemas/location.schemas';


@Component({
    selector: 'app-favorite-location',
    templateUrl: './favorite-location.component.html',
    styleUrls: ['./favorite-location.component.scss'],
})
export class FavoriteLocationComponent implements OnInit, OnDestroy {

    isFavorite = false;
    subscriptions: Subscription[] = [];

    @Input() location: Location | undefined;
    @Output() locationFavoriteToggle = new EventEmitter<Location>();

    constructor(private favoriteFacade: FavoriteFacade) {
    }

    ngOnInit(): void {

        if (this.location) {
            this.subscriptions.push(
                this.favoriteFacade.fetchFavoriteLocation(this.location).subscribe(data => this.isFavorite = data),
            );
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    toggleFavorite(location: Location | undefined): void {
        if (location) {
            this.favoriteFacade.toggleLocationFavorite(location);
            this.locationFavoriteToggle.emit(location);
        }
    }

}
