export const constants = {
    toastr: {
        positionClass: 'toast-top-full-width',
    },
    tagMore: {
        id: 0,
        icon: 'assets/images/panel-category-icons/icon-more.svg',
        title: 'Еще',
        rating: 0,
    },
};

export const treeColors: string[] = [
    // service generation tones #example
    // https://www.shadegenerator.com/7DA9C0
    'rgba(142, 142, 124, 1)',
    'rgba(142, 142, 142, 0.5)',
    'rgba(142, 142, 142, 0.3)',
];

export const MapboxStyles = {
    mapInitOptions: {
        container: 'map',
        zoom: 12,
        center: {
            lng: 104.298752,
            lat: 52.265131,
        },
        attributionControl: false,
        maxZoom: 15,
        dragRotate: false,
    },
    flyTo: {
        padding: 30,
        // zoom: 15,
        essential: true,
    },
    circles: {
        'circle-radius': 8,
        // circle color from property in geojson
        // 'circle-color': {
        //     property: 'color',
        //     type: 'identity',
        // },
        'circle-color': ['case',
            ['boolean', ['feature-state', 'clicked'], false],
            '#ffffff', // clicked color
            '#52565F', // default color
        ],
        'circle-stroke-width': 2,
        'circle-stroke-color': ['case',
            ['boolean', ['feature-state', 'clicked'], false],
            '#f4f4f4', // clicked stroke color
            '#f4f4f4', // default stroke color
        ],
    },
    points: {
        'circle-radius': 8,
        // circle color from property in geojson
        // 'circle-color': {
        //     property: 'color',
        //     type: 'identity',
        // },
        'circle-color': ['case',
            ['boolean', ['feature-state', 'selected'], false],
            '#ffffff', // selected color
            '#52565F', // default color
        ],
        'circle-stroke-width': 2,
        'circle-stroke-color': ['case',
            ['boolean', ['feature-state', 'selected'], false],
            '#ffffff', // selected stroke color
            '#52565F', // default stroke color
        ],
    },
    circlesText: {
        'text-field': '{title}',
        'text-font': ['Open Sans Regular'],
        'text-offset': [0, 0.6],
        'text-anchor': 'top',
        'text-size': 14,
        'text-allow-overlap': true,
    },
    clusters: {
        'circle-radius': [
            'step',
            ['zoom'],
            30,
            10,
            20,
            13,
            15,
            15,
            10,
        ],
        'circle-color': [
            'step',
            ['get', 'point_count'],
            'transparent',
            2,
            '#A6ACB7',
            100,
            '#A6ACB7',
            750,
            '#A6ACB7',
        ],
        'circle-stroke-width': 2,
        'circle-stroke-color': '#ffffff',
    }
};

