import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    Widget,
    WidgetsFindParams,
} from '../../schemas';
import * as utils from '../../utils';
import { Logger } from '../logger.service';


const logger = new Logger('WidgetApi');

@Injectable({
    providedIn: 'root',
})
export class WidgetApi {

    constructor(
        private http: HttpClient,
    ) { }

    findWidgets(params: WidgetsFindParams): Observable<Widget[]> {
        return this.http.get<Widget[]>(
            'assets/json/widgets.json',
            utils.convertKeysToSnake(params),
        ).pipe(
            map(utils.convertKeysToCamel),
            map(widgets => widgets.filter(
                (widget: Widget) => params.ids?.includes(widget.id)
                    || (params.title && widget.title.includes(params.title)),
            )),
        );
    }

}
