<div class="layout-wrapper layout-2" [ngClass]="{'layout-loading': !initialized}">

    <div class="layout-iterra-inner">
        <app-layout-iterra-sidenav></app-layout-iterra-sidenav>

        <div class="layout-container">

            <app-layout-iterra-navbar-back></app-layout-iterra-navbar-back>

            <div class="iterra-container router-transitions flex-grow-1">
                <router-outlet></router-outlet>
            </div>

            <app-layout-iterra-footer [class.ios]="ios"></app-layout-iterra-footer>

        </div>
    </div>

</div>

<div class="layout-overlay" (click)="closeSidenav()"></div>
