import { Dictionary } from '@ngrx/entity/src/models';
import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import { Capsule } from '../../schemas/capsule.schemas';
import {
    capsuleAdapter,
    CapsuleReducerState,
    DEFAULT_CAPSULE_FEATURE_NAME,
} from '../reducers/capsule.reducer';


const selectCapsuleState = createFeatureSelector<CapsuleReducerState>(DEFAULT_CAPSULE_FEATURE_NAME);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = capsuleAdapter.getSelectors(selectCapsuleState);

// select the array of capsule ids
export const selectCapsuleIds = selectIds;

// select the dictionary of capsule entities
export const selectCapsuleEntities = selectEntities;

// select the array of capsules
export const selectAllCapsules = selectAll;

// select the total capsule count
export const selectCapsuleTotal = selectTotal;

export const selectIsLoading = createSelector(
    selectCapsuleState,
    state => state.isLoading,
);

export const selectCapsule = createSelector(
    selectCapsuleEntities,
    (entities: Dictionary<Capsule>, props: { id: number }) =>
        entities[props.id] || null,
);

export const selectCapsules = createSelector(
    selectCapsuleEntities,
    (entities: Dictionary<Capsule>, props: { ids: number[] }) => {
        const capsules = [];

        for (const id of props.ids) {
            const capsule = entities[id];
            if (capsule) {
                capsules.push(capsule);
            }
        }

        return capsules;
    },
);

export const selectCurrentCapsuleId = createSelector(
    selectCapsuleState,
    state => state.currentCapsuleId,
);

export const selectCurrentCapsule = createSelector(
    selectCapsuleEntities,
    selectCurrentCapsuleId,
    (entities: Dictionary<Capsule>, id: number | null) =>
        id ? (entities[id] || null) : null,
);
