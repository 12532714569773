import { Dictionary } from '@ngrx/entity/src/models';
import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import { Widget } from '../../schemas/widget.schemas';
import {
    DEFAULT_WIDGET_FEATURE_NAME,
    widgetAdapter,
    WidgetReducerState,
} from '../reducers/widget.reducer';


const selectWidgetState = createFeatureSelector<WidgetReducerState>(DEFAULT_WIDGET_FEATURE_NAME);

const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = widgetAdapter.getSelectors(selectWidgetState);

// select the array of widget ids
export const selectWidgetIds = selectIds;

// select the dictionary of widget entities
export const selectWidgetEntities = selectEntities;

// select the array of widgets
export const selectAllWidgets = selectAll;

// select the total widget count
export const selectWidgetTotal = selectTotal;

export const selectIsLoading = createSelector(
    selectWidgetState,
    state => state.isLoading,
);

export const selectWidget = createSelector(
    selectWidgetEntities,
    (entities: Dictionary<Widget>, props: { id: number }) =>
        entities[props.id] || null,
);

export const selectWidgets = createSelector(
    selectWidgetEntities,
    (entities: Dictionary<Widget>, props: { ids: number[] }) => {
        const widgets = [];

        for (const id of props.ids) {
            const widget = entities[id];
            if (widget) {
                widgets.push(widget);
            }
        }

        return widgets;
    },
);

export const selectCurrentWidgetId = createSelector(
    selectWidgetState,
    state => state.currentWidgetId,
);

export const selectCurrentWidget = createSelector(
    selectWidgetEntities,
    selectCurrentWidgetId,
    (entities: Dictionary<Widget>, id: number | null) =>
        id ? (entities[id] || null) : null,
);

export const selectWidgetStores = createSelector(
    selectWidgetState,
    state => state.widgetStores,
);

export const selectWidgetStore = createSelector(
    selectWidgetStores,
    (stores: Dictionary<any | undefined>, props: { name: string }) => stores[props.name],
);
