<form [formGroup]="signInForm" (input)="onFormChange()" (ngSubmit)="onFormSubmit()" autocomplete="off">

  <div class="form-group">

    <div class="title-form-group">Номер телефона</div>

    <div class="input-group">

      <input data-test-id="sign-in-phone" type="tel"  mask='(000) 000-00-00' prefix="+7 " formControlName="phone" class="form-control" tabindex="1" placeholder="+7 012 345 67 89" required>
      <span data-test-id="sign-in-phone-error" class="invalid-feedback" *ngIf="signInForm.controls['phone'].invalid && signInForm.controls['phone'].touched">
          Номер телефона введен неверно
      </span>

    </div>

  </div>

  <div class="form-group">

    <div class="password-wrapper">
      <div class="title-form-group">Пароль</div>
      <a data-test-id="sign-in-link-forgot-password" routerLink="/auth/password/forgot">Забыли пароль?</a>
    </div>

    <div class="input-group">

      <input data-test-id="sign-in-password" type="password" formControlName="password" class="form-control" tabindex="2" placeholder="Ваш пароль" required>
      <span data-test-id="sign-in-password-error" class="invalid-feedback" *ngIf="signInForm.controls['password'].invalid && signInForm.controls['password'].touched">
        Некорректно заполненное поле
      </span>

    </div>

  </div>

  <div class="form-group">

    <button data-test-id="sign-in-submit" class="btn btn-primary sc-wide" [disabled]="cannotSubmit || signInForm.invalid || !!formError">Войти</button>
<!--    <div data-test-id="sign-in-form-error" *ngIf="formError">{{ formError.message }}</div>-->

  </div>

</form>
