import {
    EntityMap,
    EntityMapOne,
    Predicate,
    Update,
} from '@ngrx/entity';
import {
    createAction,
    props,
} from '@ngrx/store';
import { schemas } from '@scatch/ngx-app-lib';

import { Widget } from '../../schemas/widget.schemas';


export const setWidgets = createAction(
    '[Widget] Set Widgets',
    props<{ widgets: Widget[] }>(),
);

export const addWidget = createAction(
    '[Widget] Add Widget',
    props<{ widget: Widget }>(),
);

export const setWidget = createAction(
    '[Widget] Set Widget',
    props<{ widget: Widget }>(),
);

export const upsertWidget = createAction(
    '[Widget] Upsert Widget',
    props<{ widget: Widget }>(),
);

export const addWidgets = createAction(
    '[Widget] Add Widgets',
    props<{ widgets: Widget[] }>(),
);

export const upsertWidgets = createAction(
    '[Widget] Upsert Widgets',
    props<{ widgets: Widget[] }>(),
);

export const updateWidget = createAction(
    '[Widget] Update Widget',
    props<{ update: Update<Widget> }>(),
);

export const updateWidgets = createAction(
    '[Widget] Update Widgets',
    props<{ updates: Update<Widget>[] }>(),
);

export const mapWidget = createAction(
    '[Widget] Map Widget',
    props<{ entityMap: EntityMapOne<Widget> }>(),
);

export const mapWidgets = createAction(
    '[Widget] Map Widgets',
    props<{ entityMap: EntityMap<Widget> }>(),
);

export const deleteWidget = createAction(
    '[Widget] Delete Widget',
    props<{ id: number }>(),
);

export const deleteWidgets = createAction(
    '[Widget] Delete Widgets',
    props<{ ids: number[] }>(),
);

export const deleteWidgetsByPredicate = createAction(
    '[Widget] Delete Widgets By Predicate',
    props<{ predicate: Predicate<Widget> }>(),
);

export const clearWidgets = createAction(
    '[Widget] Clear Widgets',
);

export const loadWidgetsAction = createAction(
    '[Widget] Load Widgets',
    props<{ ids: number[] }>(),
);

export const loadWidgetsSuccessAction = createAction(
    '[Widget] Load Widgets Success',
    props<{ widgets: Widget[] }>(),
);

export const loadWidgetsFailureAction = createAction(
    '[Widget] Load Widgets Failure',
    props<{ error: schemas.ResponseError }>(),
);

export const setCurrentWidgetId = createAction(
    '[Widget] Set Current Widget Id',
    props<{ widgetId: number }>(),
);

export const unsetCurrentWidgetId = createAction(
    '[Widget] Unset Current Widget Id',
);

export const setWidgetStore = createAction(
    '[Widget] Set Widget Store',
    props<{ name: string, content: any }>(),
);
