<div class="nav-tabs-top">

    <ul ngbNav #navTabsTop="ngbNav" class="nav-tabs">

        <li ngbNavItem (click)="selectTab(1)">

            <a ngbNavLink>
                <ng-content select="[slot=title-1]"></ng-content>
            </a>

            <ng-template ngbNavContent>
                <div class="card-body">
                    <ng-content select="[slot=tab-1]"></ng-content>
                </div>
            </ng-template>

        </li>

        <li ngbNavItem (click)="selectTab(2)">

            <a ngbNavLink>
                <ng-content select="[slot=title-2]"></ng-content>
            </a>

            <ng-template ngbNavContent>
                <div class="card-body">
                    <ng-content select="[slot=tab-2]"></ng-content>
                </div>
            </ng-template>

        </li>

    </ul>

    <div [ngbNavOutlet]="navTabsTop"></div>
</div>
