import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { Location } from '../../../schemas/location.schemas';
import { treeColors } from '../../../app.constants';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-connection-location-item',
    templateUrl: './connection-location-item.component.html',
    styleUrls: ['./connection-location-item.component.scss'],
})
export class ConnectionLocationItemComponent implements OnInit {

    @Input() location: Location | undefined;
    @Input() isConnected: boolean | undefined;
    @Input() isActive: boolean | undefined;

    @Output() connectLocation = new EventEmitter<Location>();
    @Output() disconnectLocation = new EventEmitter<Location>();
    @Output() toggleLocationFavorite = new EventEmitter<Location>();

    constructor() {
    }

    ngOnInit(): void {
    }

    connect(location: Location | undefined): void {
        if (location) {
            this.connectLocation.emit(location);
        }
    }

    disconnect(location: Location | undefined): void {
        if (location) {
            this.disconnectLocation.emit(location);
        }
    }

    toggleFavorite(location: Location | undefined): void {
        if (location) {
            this.toggleLocationFavorite.emit(location);
        }
    }

    getColor(): string {
        return this.location?.level
            ? treeColors[this.location.level - 1] || treeColors[treeColors.length - 1]
            : treeColors[treeColors.length - 1];
    }

}
