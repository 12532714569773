import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthInterceptorProvider } from '../../interceptors/auth.interceptor';
import { DeviceHashInterceptorProvider } from '../../interceptors/device-hash.interceptor';
import { AuthUIModule } from '../../ui/auth/auth.module';
import { UiModule } from '../../ui/ui.module';
import { AuthForgotPasswordStepFirstComponent } from './auth-forgot-password-step-first/auth-forgot-password-step-first.component';
import { AuthForgotPasswordStepSecondComponent } from './auth-forgot-password-step-second/auth-forgot-password-step-second.component';
import { AuthForgotPasswordStepThirdComponent } from './auth-forgot-password-step-third/auth-forgot-password-step-third.component';
import { AuthPhoneSignInComponent } from './auth-phone-sign-in/auth-phone-sign-in.component';
import { AuthPhoneSignUpStepFirstComponent } from './auth-phone-sign-up-step-first/auth-phone-sign-up-step-first.component';
import { AuthPhoneSignUpStepSecondComponent } from './auth-phone-sign-up-step-second/auth-phone-sign-up-step-second.component';
import { AuthStartComponent } from './auth-start/auth-start.component';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        AuthPhoneSignInComponent,
        AuthForgotPasswordStepFirstComponent,
        AuthForgotPasswordStepSecondComponent,
        AuthForgotPasswordStepThirdComponent,
        AuthPhoneSignUpStepFirstComponent,
        AuthPhoneSignUpStepSecondComponent,
        AuthStartComponent,
    ],
    imports: [
        CommonModule,
        AuthUIModule,
        UiModule,
        RouterModule,
    ],
    providers: [
        DeviceHashInterceptorProvider,
        AuthInterceptorProvider,
    ],
    exports: [
        AuthPhoneSignInComponent,
        AuthForgotPasswordStepFirstComponent,
        AuthForgotPasswordStepSecondComponent,
        AuthPhoneSignUpStepFirstComponent,
        AuthPhoneSignUpStepSecondComponent,
    ],
})
export class AuthModule {}
