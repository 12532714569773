import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-widget-frame',
    templateUrl: './widget-frame.component.html',
    styleUrls: ['./widget-frame.component.scss'],
})
export class WidgetFrameComponent implements OnInit, OnDestroy {

    // capsuleId: number;
    widgetId: number | undefined;
    frameUri: string | undefined;

    constructor(
        private route: ActivatedRoute,
    ) {
        console.log('constructor');
        console.log(route);
        route.params.subscribe(p => {
            console.log('params', p);
            console.log(route);
            switch (Number(p.widgetId)) {
                case 1: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/location-feed/' + p.capsuleId + '?theme=dosaaf';
                    break;
                }
                case 2: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/location-feed-admin/?theme=dosaaf';
                    break;
                }
                case 3: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/about/' + p.capsuleId + '?theme=dosaaf';
                    break;
                }
                case 4: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/audioguide/?theme=dosaaf';
                    break;
                }
                case 5: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/menu/?theme=dosaaf';
                    break;
                }
                case 6: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/calendar/?theme=dosaaf';
                    break;
                }
                case 7: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/chat/?theme=dosaaf';
                    break;
                }
                case 8: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/traffic-rules/';
                    break;
                }
                default: {
                    this.frameUri = 'https://dev.multi-widget.iterra.world/example/';
                    break;
                }
            }
        });
    }

    ngOnInit(): void {
        console.log('ngOnInit');
        console.log(this.route);
    }

    ngOnDestroy(): void {
        console.log('ngOnDestroy');
    }

}
