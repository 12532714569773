import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    Observable,
    Subscription,
} from 'rxjs';
import { tap } from 'rxjs/operators';

import { ConnectionFacade } from '../../../facades/connection.facade';
import { FavoriteFacade } from '../../../facades/favorite.facade';
import { Location } from '../../../schemas/location.schemas';


const logger = new Logger('ConnectionListComponent');

@Component({
    selector: 'app-connection-list',
    templateUrl: './connection-list.component.html',
    styleUrls: ['./connection-list.component.scss'],
})
export class ConnectionListComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    swipedLocation?: Location;
    currentConnectedLocationId?: number;
    connectedLocationsIds: number[] = [];


    locations$: Observable<schemas.Location[]> = this.connectionFacade.locations$.pipe(
        tap(locations => logger.debug('locations', locations)),
    );

    constructor(
        private connectionFacade: ConnectionFacade,
        private favoriteFacade: FavoriteFacade,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.connectionFacade.currentLocationId$.subscribe(locationId => {
                this.currentConnectedLocationId = locationId;
            }),
        );
        this.subscriptions.push(
            this.connectionFacade.connectedLocationsIds$.subscribe(locationIds => {
                this.connectedLocationsIds = locationIds;
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onLocationConnect(location: Location): void {
        this.connectionFacade.connect(location);
    }

    onToggleLocationFavorite(location: Location): void {
        this.favoriteFacade.toggleLocationFavorite(location);
    }

    onLocationSwipe(location: Location): void {
        this.swipedLocation = location;
    }

}
