import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { treeColors } from '../../../app.constants';
import { Location } from '../../../schemas/location.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-location-info',
    templateUrl: './location-info.component.html',
    styles: [':host { width: 100% }'],
    styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent implements OnInit {

    @Input() location: Location | undefined;
    @Input() level: number | undefined;
    @Input() isActive: boolean | undefined;

    @Output() locationConnect = new EventEmitter<Location>();

    levels: number[] = [];

    constructor() { }

    ngOnInit(): void {
        if (this.level) {
            this.levels = new Array(this.level > 3 ? 3 : this.level - 1);
        }
    }

    getColor(index: number): string {
        return index < 3
            ? treeColors[index] || treeColors[treeColors.length - 1]
            : treeColors[treeColors.length - 1];
    }

    connect(location: Location | undefined): void {
        if (location) {
            this.locationConnect.emit(location);
        }
    }

}
