import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SocialUiModule } from '../social/social.module';
import { LocationCloseComponent } from './location-close/location-close.component';
import { LocationFolderComponent } from './location-folder/location-folder.component';
import { LocationComponent } from './location/location.component';


@NgModule({
    declarations: [
        LocationComponent,
        LocationFolderComponent,
        LocationCloseComponent,
    ],
    imports: [
        CommonModule,
        SocialUiModule,
    ],
    exports: [
        LocationComponent,
        LocationFolderComponent,
        LocationCloseComponent,
    ],
})
export class LocationsUiModule {}
