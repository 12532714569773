import {
    Component,
    OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AboutFacade } from '../../../facades/about.facade';
import {
    List,
    Widget,
} from '../../../schemas/about.schemas';


@Component({
    selector: 'app-widget-list-simple',
    templateUrl: './widget-list-simple.component.html',
    styleUrls: ['./widget-list-simple.component.scss'],
})
export class WidgetListSimpleComponent implements OnInit {

    widgets$: Observable<Widget[]> = this.aboutFacade.widgets$;
    widgetsList: List | undefined;

    constructor(private aboutFacade: AboutFacade) { }

    ngOnInit(): void {
        this.widgetsList = {
            title: 'Виджеты локации',
        };
    }

}
