import {
    Component,
    OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AboutFacade } from '../../../facades/about.facade';
import {
    Contact,
    List,
} from '../../../schemas/about.schemas';


@Component({
    selector: 'app-contact-list-simple',
    templateUrl: './contact-list-simple.component.html',
    styleUrls: ['./contact-list-simple.component.scss'],
})
export class ContactListSimpleComponent implements OnInit {

    contacts$: Observable<Contact[]> = this.aboutFacade.contacts$;
    contactsList: List | undefined;

    constructor(private aboutFacade: AboutFacade) { }

    ngOnInit(): void {
        this.contactsList = {
            title: 'Контакты',
        };
    }
}
