import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactsUiModule } from '../../ui/contacts/contacts.module';
import { ListsUiModule } from '../../ui/lists/lists.module';
import { ContactListSimpleComponent } from './contact-list-simple/contact-list-simple.component';


@NgModule({
    declarations: [
        ContactListSimpleComponent,
    ],
    imports: [
        CommonModule,
        ListsUiModule,
        ContactsUiModule,
    ],
    exports: [
        ContactListSimpleComponent,
    ],
})
export class ContactsModule {}
