import {
    Component,
    Input,
    OnInit,
    EventEmitter,
    Output,
} from '@angular/core';

import { Notification } from '../../../schemas/notification.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-notification',
    templateUrl: './notification-item.component.html',
    styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {

    @Input() notification: Notification | undefined;
    @Output() openNotifications = new EventEmitter<number>();

    constructor() {}

    ngOnInit(): void {
    }

    openNotification(id: number | undefined): void {
        this.openNotifications.emit(id);
    }
}




