import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocationSwipeModule } from '../../../ui/interactives/swipe-block/swipe-block.module';
import { LocationInfoModule } from '../../../ui/locations/location-info/location-info.module';
import { LocationsUiModule } from '../../../ui/locations/locations.module';
import { FavoriteLocationModule } from '../../favorite/favorite-location/favorite-location.module';
import { LocationIconStructureModule } from '../../locations/location-icon-structure/location-icon-structure.module';
import { HistoryListComponent } from './history-list.component';


@NgModule({
    declarations: [
        HistoryListComponent,
    ],
    imports: [
        CommonModule,
        LocationsUiModule,
        LocationSwipeModule,
        FavoriteLocationModule,
        LocationIconStructureModule,
        RouterModule,
        LocationInfoModule,
    ],
    exports: [
        HistoryListComponent,
    ],
})
export class HistoryListModule {}
