import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import {
    BehaviorSubject,
    Subscription,
} from 'rxjs';
import { AboutFacade } from '../../../facades/about.facade';
import { ConnectionFacade } from '../../../facades/connection.facade';
import { FavoriteFacade } from '../../../facades/favorite.facade';
import { LocationFacade } from '../../../facades/location.facade';
import { Card } from '../../../schemas/about.schemas';
import { Location } from '../../../schemas/location.schemas';


const logger = new Logger('LocationInfoComponent');

@Component({
    selector: 'app-location-info',
    templateUrl: './location-info.component.html',
    styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent implements OnInit, OnChanges, OnDestroy {

    @Input() locationId?: number;
    @Input() isExpanded = false;

    @Output() closeClick = new EventEmitter<void>();

    location?: schemas.Location;
    level = 20;
    canViewContent = false;
    card?: Card;
    imagesSubject = new BehaviorSubject<string[]>([]);

    swipedLocation?: Location;
    currentConnectedLocationId?: number;

    private subscriptions: Subscription[] = [];

    constructor(
        private aboutFacade: AboutFacade,
        private connectionFacade: ConnectionFacade,
        private favoriteFacade: FavoriteFacade,
        private locationFacade: LocationFacade,
    ) { }

    ngOnInit(): void {
        logger.debug('ngOnInit');

        this.subscriptions.push(
            this.connectionFacade.currentLocationId$.subscribe(locationId => {
                this.currentConnectedLocationId = locationId;
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.locationId) {
            this.unsubscribeAll();
            this.level = 20;
            this.canViewContent = false;
            this.imagesSubject.next([]);
            this.loadLocation(changes.locationId.currentValue);
            this.loadContent(changes.locationId.currentValue);
        }
        if (changes.isExpanded) {
            if (changes.isExpanded.currentValue) {
                this.level = 70;
                this.canViewContent = true;
            } else {
                this.level = 20;
                this.canViewContent = false;
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll();
    }

    onLocationSwipe(location: Location | undefined): void {
        if (location) {
            logger.debug('onLocationSwipe');
            this.swipedLocation = location;
        }
    }

    private unsubscribeAll(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    loadLocation(locationId: number): void {
        this.subscriptions.push(
            this.locationFacade.fetchLocation(locationId)
                .subscribe(this.processLocation.bind(this)),
        );
    }

    processLocation(location: schemas.Location | null): void {
        logger.debug('processLocation', location);

        if (location) {
            this.location = location;
        }
    }

    loadContent(locationId: number): void {
        this.subscriptions.push(
            this.aboutFacade.fetchLocationCard(locationId)
                .subscribe((card: Card) => {
                    this.card = card;
                    this.imagesSubject.next(this.card.images);
                }),
        );
        this.aboutFacade.loadAboutInfo(locationId);
        this.aboutFacade.loadContacts(locationId);
        this.aboutFacade.loadWidgets(locationId);
    }

    close(): void {
        this.closeClick.emit();
    }

    showMore(): void {
        this.level = 70;
        this.canViewContent = true;
    }

    onLevelChange(level: number): void {
        if (level === 70 && this.level === 20) {
            this.showMore();
        }
    }

    onToggleLocationFavorite(location: Location): void {
        this.favoriteFacade.toggleLocationFavorite(location);
    }

    connect(): void {
        if (this.location) {
            this.connectionFacade.connect(this.location);
        }
    }

}
