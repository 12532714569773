import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import {
    Location,
    LocationHistoryGroup,
} from '../schemas/location.schemas';
import { ConnectionService } from '../services/connection.service';
import { StoreService } from '../services/store.service';
import * as connectionActions from '../store/actions/connection.actions';
import * as connectionSelectors from '../store/selectors/connection.selectors';


const logger = new Logger('ConnectionFacade');

@Injectable({
    providedIn: 'root',
})
export class ConnectionFacade {

    constructor(
        private store$: Store,
        private connectionService: ConnectionService,
        private storeService: StoreService,
    ) { }

    get connections$(): Observable<schemas.Connection[]> {
        return this.store$.pipe(
            select(connectionSelectors.selectConnections),
        );
    }

    get connectedLocationsIds$(): Observable<number[]> {
        return this.store$.pipe(
            select(connectionSelectors.selectConnectedLocationsIds),
        );
    }

    get isConnected$(): Observable<boolean> {
        return this.store$.pipe(
            select(connectionSelectors.selectIsConnected),
        );
    }

    get currentConnection$(): Observable<schemas.Connection | null> {
        return this.store$.pipe(
            select(connectionSelectors.selectCurrentConnection),
        );
    }

    get currentLocation$(): Observable<Location | null> {
        return this.currentConnection$.pipe(
            map(connection => connection?.location || null),
        );
    }

    get currentLocationId$(): Observable<number | undefined> {
        return this.currentLocation$.pipe(
            map(location => location?.id),
        );
    }

    get locations$(): Observable<schemas.Location[]> {
        return this.store$.pipe(
            select(connectionSelectors.selectConnectedLocations),
        );
    }

    connect(location: Location): void {
        this.store$.dispatch(connectionActions.connectToLocationAction({location}));
    }

    disconnect(location: Location): void {
        this.store$.dispatch(connectionActions.disconnectFromLocationAction({location}));
    }

    fetchHistory(): Observable<any> {
        return this.connectionService.fetchHistory().pipe(
            tap((history) => logger.debug('fetchHistory(history)', history)),
            switchMap((history) => {

                const pipe = new DatePipe('en-US');
                const uniqueIds: number[] = [];

                const storage: { [key: string]: any } = {};
                const dateIdKeys: { [key: string]: number[] } = {};
                const dateKeys: string[] = [];

                history.forEach(item => {
                    const date = pipe.transform(item.date, 'dd.MM.yy') as string;
                    if (!dateKeys.includes(date)) {
                        dateKeys.push(date);
                    }

                    if (!(date in storage)) {
                        storage[date] = {};
                    }

                    if (!(date in dateIdKeys)) {
                        dateIdKeys[date] = [];
                    }

                    const existItem = storage[date][item.locationId];

                    if (!existItem || existItem.date < item.date) {
                        storage[date][item.locationId] = item;
                    }

                    if (!dateIdKeys[date].includes(item.locationId)) {
                        dateIdKeys[date].push(item.locationId);
                    }

                    if (!uniqueIds.includes(item.locationId)) {
                        uniqueIds.push(item.locationId);
                    }

                });

                return this.storeService.fetchLocations(uniqueIds).pipe(
                    tap(locations => logger.debug('history (locations)', locations)),
                    map(locations => {

                        const result: LocationHistoryGroup[] = [];

                        dateKeys.forEach(date => {
                            const items: { location: Location, lastVisitDate: number }[] = [];

                            dateIdKeys[date].forEach(dateIdKey => {
                                const item = storage[date][dateIdKey];
                                const location = locations.find(data => data.id === item.locationId);
                                if (location) {
                                    items.push({location, lastVisitDate: item.date});
                                }

                            });

                            if (items.length) {
                                result.push({date, items});
                            }
                        });

                        return result;
                    }),
                );

            }),
        );
    }

}
