<form [formGroup]="signUpForm" (submit)="onFormSubmit()" novalidate autocomplete="off">

  <div class="form-group">

    <div class="title-form-group">Номер телефона</div>

    <div class="input-group">
      <input data-test-id="sign-up-phone" type="tel" mask='(000) 000-00-00' prefix="+7 " formControlName="phone" class="form-control" tabindex="1" placeholder="+7 012 345 67 89" required>
      <span data-test-id="sign-up-phone-error" class="invalid-feedback"
            *ngIf="signUpForm.controls['phone']?.invalid && signUpForm.controls['phone']?.touched">
        Некорректно заполненное поле
      </span>

    </div>

  </div>

  <div class="form-group">

    <button data-test-id="sign-up-submit" type="submit" [disabled]="cannotSubmit || signUpForm.invalid || !!formError" tabindex="2" class="btn btn-primary sc-wide">
      Регистрация
    </button>

  </div>


  <div class="form-group">
    <a data-test-id="sign-up-already-registered" routerLink="/auth/phone/sign-in" tabindex="3">Уже зарегистрированы?</a>
  </div>

</form>
