import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    LocationApi,
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import * as GeoJSON from 'geojson';
import { Observable } from 'rxjs';
import {
    first,
    map,
    tap,
} from 'rxjs/operators';
import { MapService } from '../services/map/map.service';


const logger = new Logger('MapFacade');

@Injectable({
    providedIn: 'root',
})
export class MapFacade {

    constructor(
        private store$: Store,
        private mapService: MapService,
        private locationApi: LocationApi,
    ) {}

    makeFeatureCollection(locationGeoList: schemas.LocationGeo[]): GeoJSON.FeatureCollection {
        const features: GeoJSON.Feature[] = [];
        const featureCollection: GeoJSON.FeatureCollection = {
            type: 'FeatureCollection',
            features,
        };

        if (locationGeoList && locationGeoList.length) {
            locationGeoList.forEach(locationGeo => {
                features.push(
                    {
                        type: 'Feature',
                        id: locationGeo.id,
                        properties: {
                            locationId: locationGeo.locationId,
                            iconSize: [24, 24],
                            color: '#0080ff',
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [
                                locationGeo.lan,
                                locationGeo.lon,
                            ],
                        },
                    },
                );
            });
        }

        return featureCollection;
    }

    fetchLocationGeoFeatureCollection(bounds: schemas.MapBounds): Observable<GeoJSON.FeatureCollection> {
        return this.locationApi.fetchBoundedLocationGeoList(bounds).pipe(
            tap(locationGeoList => logger.debug('locationGeoList', locationGeoList)),
            map(this.makeFeatureCollection.bind(this)),
            first(),
        );
    }

}
