import {
    Component,
    Input,
    OnInit,
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-widget-list',
    templateUrl: './widget-list.component.html',
    styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent implements OnInit {

    @Input() title: string | undefined;

    constructor() { }

    ngOnInit(): void {
    }

}
