import { NgModule } from '@angular/core';
import { Store } from '@ngrx/store';

import * as appActions from './actions/app.actions';
import * as authActions from './actions/auth.actions';


@NgModule({
    imports: [],
})
export class AppStoreModule {

    constructor(store$: Store) {
        store$.dispatch(appActions.initAction());
        store$.dispatch(authActions.initAction());
    }

}
