import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    CapsuleApi,
    LocationApi,
    LocationTagApi,
    Logger,
    schemas,
    utils,
} from '@scatch/ngx-app-lib';
import {
    EMPTY,
    of,
} from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    first,
    map,
    tap,
} from 'rxjs/operators';

import { Location } from '../../schemas/location.schemas';
import * as locationActions from '../actions/location.actions';


const logger = new Logger('LocationEffects');

@Injectable()
export class LocationEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private locationApi: LocationApi,
        private locationTagApi: LocationTagApi,
        private capsuleApi: CapsuleApi,
    ) {}

    loadLocationsSuccessEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsSuccessAction),
            tap(({locations}) => logger.debug('loadLocationsSuccessEffect (locations)', locations)),
            map(({locations}) => locationActions.upsertLocations({locations})),
        ),
    );

    loadLocationsPointsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsSuccessAction),
            tap(({locations}) => logger.debug('loadLocationsPointsEffect (locations)', locations)),
            concatMap(({locations}) => {
                const locationIds = locations.map(location => location.id);

                return this.locationApi.fetchPointsByLocationIds(locationIds).pipe(
                    tap(points => logger.debug('loadLocationsPointsEffect (points)', points)),
                    map(points => {
                        const updatedLocations: Location[] = [];

                        for (const location of locations) {
                            for (const point of points) {
                                if (location.id === point.locationId) {
                                    updatedLocations.push({
                                        ...location,
                                        isDynamic: point.isDynamic,
                                    });
                                    break;
                                }
                            }
                        }

                        return locationActions.upsertLocations({
                            locations: updatedLocations,
                        });
                    }),
                    first(),
                );
            }),
        ),
    );

    loadLocationsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsAction),
            tap(({locationIds}) => logger.debug('loadLocationsEffect (locationIds)', locationIds)),
            filter(({locationIds}) => !!locationIds?.length),
            concatMap((params: { locationIds?: number[], workTableId?: number }) => {
                return this.locationApi.fetchLocations(params).pipe(
                    tap(locations => logger.debug('loadLocationsEffect (locations)', locations)),
                    tap((locations: schemas.Location[]) => {
                        if (locations.length) {
                            this.store$.dispatch(locationActions.loadLocationsSuccessAction({
                                locations: locations.map(location => ({
                                    ...location,
                                    level: location.path.split(',').length,
                                })),
                            }));
                        }
                    }),
                    catchError(response => {
                        logger.debug('loadLocationsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadLocationsFailureAction({
                            error: utils.formatErrors(response),
                        }));
                        return of(false);
                    }),
                );
            }),
        ),
        {dispatch: false},
    );

    loadLocationsTagsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsTagsAction),
            filter(({locationIds}) => !!locationIds?.length),
            tap(({locationIds}) => logger.debug('loadLocationsTagsEffect (locationIds)', locationIds)),
            concatMap(({locationIds}) => {
                return this.locationTagApi.fetchTagsByLocationIds(locationIds).pipe(
                    tap(locationTags => logger.debug('loadLocationsTagsEffect (locationTags)', locationTags)),
                    map((locationTags: schemas.LocationTag[]) =>
                        locationActions.loadLocationsTagsSuccessAction({
                            locationTags,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadLocationsTagsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadLocationsTagsFailureAction({
                            error: utils.formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

    loadTagsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadTagsAction),
            filter(({tagIds}) => !!tagIds?.length),
            tap(({tagIds}) => logger.debug('loadTagsEffect (tagIds)', tagIds)),
            concatMap(({tagIds}) => {
                return this.locationTagApi.fetchTags(tagIds).pipe(
                    tap(tags => logger.debug('loadTagsEffect (tags)', tags)),
                    map((tags: schemas.Tag[]) =>
                        locationActions.loadTagsSuccessAction({
                            tags,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadTagsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadTagsFailureAction({
                            error: utils.formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

    loadLocationsGeosEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadLocationsGeosAction),
            filter(({locationIds}) => !!locationIds?.length),
            tap(({locationIds}) => logger.debug('loadLocationsGeosEffect (locationIds)', locationIds)),
            concatMap(({locationIds}) => {
                return this.locationApi.fetchGeoByLocationIds(locationIds).pipe(
                    tap(locationGeos => logger.debug('loadLocationsGeosEffect (locationGeos)', locationGeos)),
                    map((locationGeos: schemas.LocationGeo[]) =>
                        locationActions.loadLocationsGeosSuccessAction({
                            locationGeos,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadLocationsGeosEffect (response)', response);
                        this.store$.dispatch(locationActions.loadLocationsGeosFailureAction({
                            error: utils.formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

    loadWorkTablesEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadWorkTablesAction),
            filter(({workTableIds}) => !!workTableIds?.length),
            tap(({workTableIds}) => logger.debug('loadWorkTablesEffect (workTableIds)', workTableIds)),
            concatMap(({workTableIds}) => {
                return this.capsuleApi.findCapsules({ids: workTableIds}).pipe(
                    tap(workTables => logger.debug('loadWorkTablesEffect (workTables)', workTables)),
                    map((workTables: schemas.Capsule[]) =>
                        locationActions.loadWorkTablesSuccessAction({
                            workTables,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadWorkTablesEffect (response)', response);
                        this.store$.dispatch(locationActions.loadWorkTablesFailureAction({
                            error: utils.formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

    loadWorkTableWidgetsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(locationActions.loadWorkTableWidgetsAction),
            filter(({workTableIds}) => !!workTableIds?.length),
            tap(({workTableIds}) => logger.debug('loadWorkTableWidgetsEffect (workTableIds)', workTableIds)),
            concatMap(({workTableIds}) => {
                logger.debug('loadWorkTableWidgetsEffect (RUN)', workTableIds);

                return this.capsuleApi.findCapsuleWidgets({capsuleIds: workTableIds}).pipe(
                    tap(workTableWidgets => logger.debug('loadWorkTableWidgetsEffect (workTableWidgets)', workTableWidgets)),
                    map((workTableWidgets: schemas.CapsuleWidget[]) =>
                        locationActions.loadWorkTableWidgetsSuccessAction({
                            workTableWidgets,
                        }),
                    ),
                    first(),
                    catchError(response => {
                        logger.debug('loadWorkTableWidgetsEffect (response)', response);
                        this.store$.dispatch(locationActions.loadWorkTableWidgetsFailureAction({
                            error: utils.formatErrors(response),
                        }));
                        return EMPTY;
                    }),
                );
            }),
            filter(action => !!action),
        ),
    );

}
