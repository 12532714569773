import {
    Component,
    OnInit,
} from '@angular/core';
import { AuthFacade } from '../../../facades/auth.facade';


@Component({
    selector: 'app-auth-start',
    templateUrl: './auth-start.component.html',
    styleUrls: ['./auth-start.component.scss'],
})
export class AuthStartComponent implements OnInit {

    constructor(
        private authFacade: AuthFacade,
    ) { }

    ngOnInit(): void {
    }

    signInAsGuest(): void {
        this.authFacade.signInAsGuest();
    }

}
