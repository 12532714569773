import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    map,
    tap,
} from 'rxjs/operators';

import {
    Capsule,
    CapsulesFindParams,
    CapsuleWidget,
    CapsuleWidgetsCount,
    CapsuleWidgetsFindParams,
} from '../../schemas';
import * as utils from '../../utils';
import { Logger } from '../logger.service';


const logger = new Logger('CapsuleApi');

@Injectable({
    providedIn: 'root',
})
export class CapsuleApi {

    constructor(
        private http: HttpClient,
    ) { }

    findCapsules(params: CapsulesFindParams): Observable<Capsule[]> {
        return this.http.get<Capsule[]>(
            'assets/json/capsules.json',
            {
                params: utils.convertKeysToSnake(params),
            },
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(capsules => logger.debug('findCapsules (capsules)', capsules)),
            map(capsules => capsules.filter(
                (capsule: Capsule) => params.ids?.includes(capsule.id)
                    || (params.title && capsule.title.includes(params.title)),
            )),
        );
    }

    fetchCapsule(id: number): Observable<Capsule> {
        return this.http.get<Capsule>(
            'assets/json/capsules.json',
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(capsules => logger.debug('fetchCapsule (capsules)', capsules)),
            map(capsules => {
                for (const capsule of capsules) {
                    if (capsule.id === id) {
                        return capsule;
                    }
                }
                return null;
            }),
        );
    }

    findCapsuleWidgets(params: CapsuleWidgetsFindParams): Observable<CapsuleWidget[]> {
        return this.http.get<CapsuleWidget[]>(
            'assets/json/capsules/widgets.json',
            {
                params: utils.convertKeysToSnake(params),
            },
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(capsuleWidgets => logger.debug('findCapsuleWidgets (capsuleWidgets)', capsuleWidgets)),
            map(capsuleWidgets => capsuleWidgets.filter(
                (capsuleWidget: CapsuleWidget) =>
                    params.ids?.includes(capsuleWidget.id)
                    || (params.capsuleId && params.capsuleId === capsuleWidget.capsuleId)
                    || (params.capsuleIds && params.capsuleIds.includes(capsuleWidget.capsuleId))
                    || (params.widgetId && params.widgetId === capsuleWidget.widgetId),
            )),
        );
    }

    fetchCapsulesWidgetsCount(capsuleIds: number[]): Observable<CapsuleWidgetsCount[]> {
        return this.http.get<CapsuleWidget[]>(
            'assets/json/capsules/widgets.json',
        ).pipe(
            map(utils.convertKeysToCamel),
            tap(capsuleWidgets => logger.debug('countLocationsWidgets (capsuleWidgets)', capsuleWidgets)),
            map(capsuleWidgets => capsuleWidgets.filter(
                (capsuleWidget: CapsuleWidget) =>
                    capsuleIds.includes(capsuleWidget.capsuleId),
            )),
            map(capsuleWidgets => {
                return capsuleIds.map(
                    capsuleId => ({
                        capsuleId,
                        total: capsuleWidgets.reduce(
                            (init: number, capsuleWidget: CapsuleWidget) =>
                                capsuleWidget.capsuleId === capsuleId ? init + 1 : init, 0),
                    }),
                );

            }),
        );
    }

}
