<div class="swipe-block">

    <div class="swipe-block__content">

        <div class="swipe-block__wrapper">

            <div (swipeStart)="onSwipeStart($event)"
                 (swipeMove)="onSwipeMove($event)"
                 (swipeEnd)="onSwipeEnd($event)"
                 (click)="onClickSwipeBlock()"
                 swipable #swipeBlock class="swipe-block__interactive">

                <ng-content select="[slot=swipe_layer]"></ng-content>

                <i class="swipe-block__pull">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="inherit">
                        <rect x="12" width="2" height="24" rx="1" fill="inherit"/>
                        <rect x="6" width="2" height="24" rx="1" fill="inherit"/>
                        <rect width="2" height="24" rx="1" fill="inherit"/>
                    </svg>
                </i>

            </div>

            <div class="swipe-block__tools">
                <ng-content select="[slot=hidden_layer]"></ng-content>
            </div>
        </div>
    </div>

    <div class="swipe-block__show">
        <ng-content select="[slot=visible_layer]"></ng-content>
    </div>

</div>
