<ui-float-panel [canSwipe]="true" [level]="70" [levels]="[70]" (panelClose)="close()">
    <div slot="header" class="header">

        <ui-tab-list-two-items (tabSelect)="onTabSelect($event)">


            <span slot="title-1">Локации</span>

            <div class="wrapper" slot="tab-1">
                <ui-tag-selector
                        [tags]="locationsTags"
                        [selectedTag]="selectedTag"
                        [tagMore]="moreTag"
                        (tagSelect)="onTagSelect($event)">
                </ui-tag-selector>

                <div class="location-float-list">

                    <app-location-slim-list [locations]="getFilteredLocations()"
                                            (locationClick)="onLocationClick($event)">
                    </app-location-slim-list>

                </div>
            </div>


            <span slot="title-2">Пространства</span>

            <div class="wrapper" slot="tab-2">
                <ui-tag-selector
                        [tags]="spacesTags"
                        [selectedTag]="selectedTag"
                        [tagMore]="moreTag"
                        (tagSelect)="onTagSelect($event)">
                </ui-tag-selector>

                <div class="location-float-list">

                    <app-location-slim-list [locations]="getFilteredSpaces()"
                                            (locationClick)="onLocationClick($event)">
                    </app-location-slim-list>

                </div>
            </div>


        </ui-tab-list-two-items>
    </div>

</ui-float-panel>
