import {
    Component,
    ComponentRef,
} from '@angular/core';
import {
    IModalDialog,
    IModalDialogOptions,
} from 'ngx-modal-dialog';
import { Subject } from 'rxjs';
import { FavoriteFacade } from '../../../facades/favorite.facade';


@Component({
    selector: 'app-dialog-ungroup',
    templateUrl: './dialog-ungroup.component.html',
    styleUrls: ['./dialog-ungroup.component.scss'],
})
export class DialogUngroupComponent implements IModalDialog {

    closeSubject: Subject<void> | undefined;
    id: number | undefined;

    constructor(private capsulesFacade: FavoriteFacade) {}

    dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<string>>): void {
        this.closeSubject = options.closeDialogSubject || new Subject();
        this.id = Number(options.data);
    }

    ungroupFolder(): void {
        this.capsulesFacade.unGroupFolder(this.id);
        this.closeSubject?.next();
    }

    close(): void {
        this.closeSubject?.next();
    }
}

