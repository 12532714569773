import { LayoutIterraWithoutNavbarComponent } from '../layout/layout-iterra-without-navbar/layout-iterra-without-navbar.component';
import { WidgetFrameComponent } from '../modules/widget/widget-frame/widget-frame.component';


export const capsulesRoutes = [
    {
        path: ':capsuleId',
        children: [
            {
                path: 'widgets',
                children: [
                    {
                        path: ':widgetId',
                        component: LayoutIterraWithoutNavbarComponent,
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                component: WidgetFrameComponent,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
