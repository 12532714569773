<div class="notification bs4-toast toast show" [ngClass]="notification?.variant!"
     [routerLink]="['/notification/capsule', notification?.capsule_id]">
    <div class="toast-header">
        <div class="icon" [ngStyle]="{'background-image': 'url(' + notification?.icon + ')'}"></div>
        <div class="toast-title font-weight-bold mr-auto">{{ notification?.title }}</div>
        <div class="toast-time d-block small">{{ notification?.time | date:'dd.mm.yyyy hh:mm'}}</div>
        <div class="amount-unread-messages" [hidden]="!notification?.amount">{{ notification?.amount }}</div>
    </div>
    <div class="toast-body">
        {{ notification?.message }}
    </div>
</div>
