<div class="structure" (click)="goToStructure(location?.id)">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit">
        <circle cx="5" cy="19" r="2" transform="rotate(180 5 19)" fill="inherit"/>
        <circle cx="12" cy="19" r="2" transform="rotate(180 12 19)" fill="inherit"/>
        <circle cx="19" cy="19" r="2" transform="rotate(180 19 19)" fill="inherit"/>
        <circle cx="5" cy="12" r="2" transform="rotate(180 5 12)" fill="inherit"/>
        <circle cx="12" cy="12" r="2" transform="rotate(180 12 12)" fill="inherit"/>
        <circle cx="5" cy="5" r="2" transform="rotate(180 5 5)" fill="inherit"/>
    </svg>
</div>
