import {
    Component,
    OnInit,
    ViewContainerRef,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@scatch/ngx-app-lib';
import { ModalDialogService } from 'ngx-modal-dialog';
import { Subject } from 'rxjs';
import {
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { ConnectionFacade } from '../../../facades/connection.facade';
import { FavoriteFacade } from '../../../facades/favorite.facade';
import {
    Folder,
    Location,
} from '../../../schemas/location.schemas';
import { DialogUngroupComponent } from '../../dialogs/dialog-ungroup/dialog-ungroup.component';


const logger = new Logger('FavoriteFolderComponent');

@Component({
    selector: 'app-favorite-folder',
    templateUrl: './favorite-folder.component.html',
    styleUrls: ['./favorite-folder.component.scss'],
})
export class FavoriteFolderComponent implements OnInit {

    folderId: number | undefined;
    newValue: string | undefined;
    folder: Folder | undefined;
    locations: Location[] = [];

    editFolderForm: FormGroup;
    displayUpdateTools: boolean | undefined = false;

    constructor(private modalService: ModalDialogService,
                private viewRef: ViewContainerRef,
                private favoriteFacade: FavoriteFacade,
                private connectionFacade: ConnectionFacade,
                private route: ActivatedRoute) {

        this.editFolderForm = new FormGroup({

            title: new FormControl('', [
                Validators.required,
            ]),

        });
    }

    ngOnInit(): void {

        this.route.paramMap.pipe(
            tap(params => logger.debug('params', params, this.route)),
            map(params => Number(params.get('id'))),
            tap(id => logger.debug('route(id)', id)),
            switchMap(id => {
                return this.favoriteFacade.fetchFavoriteLocations(id);
            }),
        ).subscribe(data => this.locations = data);

        this.folderId = Number(this.route.snapshot.paramMap.get('id'));

        this.favoriteFacade.folders$.subscribe(data => {
            this.folder = data.filter(folder => folder.id === this.folderId)[0];
        });

        this.editFolderForm.controls.title.setValue(this.folder?.title);
    }

    updateTitleFolder(): void {
        this.newValue = this.editFolderForm.controls.title.value;
        if (this.folderId) {
            this.favoriteFacade.updateTitleFolder(this.folderId, this.newValue);
        }
        this.displayUpdateTools = !this.displayUpdateTools;
    }

    deleteFavoriteLocationFromFolder(locationId: number): void {
        if (locationId) {
            this.favoriteFacade.deleteFavoriteLocationFromFolder(locationId);
        }
    }

    onToggleLocationFavorite(location: Location): void {
        if (location) {
            this.favoriteFacade.toggleLocationFavorite(location);
        }
    }

    openUngroupDialog(): void {
        this.modalService.openDialog(this.viewRef, {
            childComponent: DialogUngroupComponent,
            closeDialogSubject: new Subject(),
            data: this.folderId,
        });
    }

    goToLocation(location: Location): void {
        if (location) {
            this.connectionFacade.connect(location);
        }
    }


}
