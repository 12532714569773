import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    CapsuleApi,
    Logger,
    schemas,
    utils,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import * as capsuleActions from '../actions/capsule.actions';
import * as layoutActions from '../actions/layout.actions';
import * as widgetActions from '../actions/widget.actions';
import * as capsuleSelectors from '../selectors/capsule.selectors';


const logger = new Logger('CapsuleEffects');

@Injectable()
export class CapsuleEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private capsuleApi: CapsuleApi,
    ) {}

    loadCapsuleEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(capsuleActions.loadCapsuleAction),
            tap(capsuleId => logger.debug(' loadCapsuleEffect (capsuleId)', capsuleId)),
            switchMap(({capsuleId}) =>
                this.capsuleApi.fetchCapsule(capsuleId).pipe(
                    tap(capsule => logger.debug('loadCapsuleEffect (capsuleId, capsule)', capsuleId, capsule)),
                    map((capsule: schemas.Capsule) =>
                        capsuleActions.loadCapsuleSuccessAction({
                            capsule,
                        }),
                    ),
                    catchError(response => {
                        logger.debug('loadCapsuleEffect (response)', response);
                        return of(capsuleActions.loadCapsuleFailureAction({
                            error: utils.formatErrors(response),
                        }));
                    }),
                    first(),
                ),
            ),
        ),
    );

    loadCapsuleWidgetIdsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(capsuleActions.loadCapsuleSuccessAction),
            tap(({capsule}) => logger.debug('loadCapsuleWidgetIdsEffect (capsule)', capsule)),
            switchMap(({capsule}) =>
                this.capsuleApi.findCapsuleWidgets({capsuleId: capsule.id}).pipe(
                    tap(capsuleWidgets => logger.debug('loadCapsuleWidgetIdsEffect (capsuleWidgets)', capsuleWidgets)),
                    map((capsuleWidgets: schemas.CapsuleWidget[]) =>
                        capsuleActions.upsertCapsule({
                            capsule: {
                                ...capsule,
                                widgetIds: capsuleWidgets.map(capsuleWidget => capsuleWidget.widgetId),
                            },
                        }),
                    ),
                    catchError(response => {
                        logger.debug('loadCapsuleWidgetIdsEffect (response)', response);
                        return of(capsuleActions.loadCapsuleFailureAction({
                            error: utils.formatErrors(response),
                        }));
                    }),
                    first(),
                ),
            ),
        ),
    );

    loadCurrentCapsuleWidgetsEffect$ = createEffect(
        () => this.store$.pipe(
            select(capsuleSelectors.selectCurrentCapsule),
            filter(capsule => !!capsule?.widgetIds?.length),
            tap(capsule => logger.debug('loadCurrentCapsuleWidgetsEffect (capsule)', capsule)),
            map(capsule => widgetActions.loadWidgetsAction({
                ids: capsule?.widgetIds || [],
            })),
        ),
    );

    showCurrentCapsuleWidgetsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(widgetActions.loadWidgetsSuccessAction),
            switchMap(({widgets}) => this.store$.pipe(
                select(capsuleSelectors.selectCurrentCapsule),
                filter(capsule => !!capsule?.widgetIds?.length),
                tap(capsule => logger.debug('showCurrentCapsuleWidgetsEffect (capsule)', capsule)),
                map(capsule => widgets.filter(widget => (capsule?.widgetIds || []).includes(widget.id))),
                filter(currentCapsuleWidgets => !!currentCapsuleWidgets.length),
                map(() => capsuleActions.loadCurrentCapsuleWidgetsSuccessAction()),
                first(),
            )),
        ),
    );

    upsertWorkTableStyleEffect$ = createEffect(
        () => this.store$.pipe(
            select(capsuleSelectors.selectCurrentCapsule),
            tap(capsule => logger.debug('upsertWorkTableStyle (workTableStyle)', capsule)),
            map(capsule => layoutActions.upsertWorkTableStyleAction(
                {
                    workTableStyle: {
                        title: capsule?.manifest.title,
                        background: capsule?.manifest.bgImage,
                    },
                },
            )),
        ),
    );

}
