import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import { Logger } from '@scatch/ngx-app-lib';
import {
    distinctUntilChanged,
    filter,
    first,
    map,
    skip,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import * as authActions from '../actions/auth.actions';
import * as capsuleActions from '../actions/capsule.actions';
import * as connectionActions from '../actions/connection.actions';
import * as passwordActions from '../actions/password.actions';
import * as phoneActions from '../actions/phone.actions';
import * as routerActions from '../actions/router.actions';
import * as authSelectors from '../selectors/auth.selectors';
import * as connectionSelectors from '../selectors/connection.selectors';
import * as routerSelectors from '../selectors/router.selectors';


const logger = new Logger('RouterEffects');


@Injectable()
export class RouterEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private router: Router,
    ) {}

    listenAuthorizationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(authActions.initAction),
            switchMap(() => this.store$.pipe(
                select(authSelectors.selectIsInit),
                filter(isInit => isInit),
            )),
            switchMap(() => this.store$.pipe(
                select(authSelectors.selectIsAuth),
            )),
            skip(1),
            distinctUntilChanged(),
            withLatestFrom(this.store$.pipe(
                select(routerSelectors.selectQueryParam('redirect_uri')),
            )),
            tap(([isAuth, redirectUri]) => {
                logger.debug('[isAuth, redirectUri]', [isAuth, redirectUri]);
                // ToDo: add redirect_uri to sign in page after sign out
                const uri = isAuth
                    ? (redirectUri || '/scanner')
                    : '/auth';
                this.router.navigateByUrl(uri)
                    .catch(reason => logger.error('Navigate failed', reason));
            }),
        ),
        {dispatch: false},
    );

    goWithCurrentRedirectUriEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(routerActions.goWithCurrentRedirectUriAction),
            switchMap(({commands, extras}) => this.store$.pipe(
                select(routerSelectors.selectQueryParam('redirect_uri')),
                map(redirectUri => {
                    logger.debug('goWithCurrentRedirectUri:', commands, extras, redirectUri);

                    if (!extras) {
                        extras = {};
                    }
                    if (!extras.queryParams) {
                        extras.queryParams = {};
                    }
                    extras.queryParams.redirect_uri = redirectUri;

                    return {commands, extras};
                }),
                first(),
            )),
            tap(({commands, extras}) =>
                this.router.navigate(commands, extras)
                    .catch(reason => logger.error('Navigate failed', reason)),
            ),
        ),
        {dispatch: false},
    );

    goConfirmSignUpCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(phoneActions.sendSignUpCodeSuccessAction),
            tap(() => {
                this.router.navigate(
                    ['/auth/phone/sign-up/confirm'],
                    {queryParams: {redirect_uri: '/auth/password/forgot/reset'}},
                ).catch(reason => logger.error('Navigate failed', reason));
            }),
        ),
        {dispatch: false},
    );

    goConfirmSignInCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(phoneActions.sendSignInCodeSuccessAction),
            map(() => routerActions.goWithCurrentRedirectUriAction({
                commands: ['/auth/phone/sign-in/confirm'],
            })),
        ),
    );

    goConfirmPasswordResetCodeEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(phoneActions.sendPasswordResetCodeSuccessAction),
            tap(() => {
                this.router.navigate(
                    ['/auth/password/forgot/confirm'],
                    {queryParams: {redirect_uri: '/auth/password/forgot/reset'}},
                ).catch(reason => logger.error('Navigate failed', reason));
            }),
        ),
        {dispatch: false},
    );

    goConnect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    passwordActions.resetSuccessAction,
                ),
                tap(() => this.router.navigate(['/scanner'])),
            ),
        {dispatch: false},
    );

    goCurrentCapsuleWidgetsEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                connectionActions.connectSuccessAction,
                routerActions.goWidgetsAction,
            ),
            tap(() => logger.debug('goCurrentCapsuleWidgetsEffect')),
            switchMap(() => this.actions$.pipe(
                ofType(capsuleActions.loadCurrentCapsuleWidgetsSuccessAction),
                tap(() => logger.debug('goCurrentCapsuleWidgetsEffect')),
                tap(() => this.router.navigate(['/widgets'])),
                first(),
            )),
        ),
        {dispatch: false},
    );

    goConnectOnLastDisconnectEffect$ = createEffect(
        () => this.store$.pipe(
            select(connectionSelectors.selectCurrentConnection),
            filter(currentConnection => !currentConnection),
            tap(() => logger.debug('goConnectOnLastDisconnectEffect')),
            tap(() => {
                this.router.navigate(['/scanner'])
                    .catch(reason => logger.error('Navigate failed', reason));
            }),
        ),
        {dispatch: false},
    );

    goFavoriteLocationListEffect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    routerActions.goFavoriteLocationListAction,
                ),
                tap(() => this.router.navigate(['/favorites/locations'])),
            ),
        {dispatch: false},
    );

}
