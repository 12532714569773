import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    selector: 'app-widget-chat',
    templateUrl: './widget-chat.component.html',
    styleUrls: ['./widget-chat.component.scss'],
})
export class WidgetChatComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
