import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationsFacade } from '../../../facades/notifications.facade';
import { Notification } from '../../../schemas/notification.schemas';


@Component({
    selector: 'app-capsule-notification-list',
    templateUrl: './capsule-notification-list.component.html',
    styleUrls: ['./capsule-notification-list.component.scss'],
})
export class CapsuleNotificationListComponent implements OnInit {

    @Input() id: string | null | undefined;
    capsuleTitle: string | undefined;
    capsuleNotifications$: Observable<Array<Notification>> = this.notificationsFacade.capsuleNotifications$;

    constructor(private notificationsFacade: NotificationsFacade, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.notificationsFacade.loadCapsuleNotifications(this.id);
        this.capsuleTitle = this.notificationsFacade.capsuleTitle;
    }

}
