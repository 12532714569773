import {
    createReducer,
    on,
} from '@ngrx/store';
import { WorkTableStyle } from '../../schemas/layout.schemas';

import * as layoutActions from '../actions/layout.actions';


export const DEFAULT_LAYOUT_FEATURE_NAME = 'layout';

export interface LayoutReducerState {
    workTableStyle: WorkTableStyle;
}

const initialState: LayoutReducerState = {
    workTableStyle: {
        title: '',
        background: '',
    },
};

export const layoutReducer = createReducer(
    initialState,
    on(
        layoutActions.upsertWorkTableStyleAction,
        (state, {workTableStyle}) => ({
            ...state,
            workTableStyle,
        }),
    ),
);
