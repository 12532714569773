import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import {
    DEFAULT_LAYOUT_FEATURE_NAME,
    LayoutReducerState,
} from '../reducers/layout.reducer';


const selectLayoutState = createFeatureSelector<LayoutReducerState>(DEFAULT_LAYOUT_FEATURE_NAME);

export const selectWorkTableStyleTitle = createSelector(
    selectLayoutState,
    state => state.workTableStyle.title,
);

export const selectWorkTableStyleBackground = createSelector(
    selectLayoutState,
    state => state.workTableStyle.background,
);
