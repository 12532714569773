<form [formGroup]="forgotCreatePasswordForm" (input)="onFormChange()" (submit)="onSubmit()" novalidate autocomplete="off">

  <div class="form-group">
    <div class="form-group__text">Придумайте новый пароль.</div>
    <div class="form-group__text">Пароль должен быть не короче 6 символов</div>
  </div>

  <div class="form-group">

    <div class="title-form-group">Пароль</div>

    <div class="input-group">
      <input data-test-id="new-password" type="password" formControlName="password" class="form-control" tabindex="1" placeholder="Ваш пароль" required>
    </div>

  </div>

  <div class="form-group">

    <div class="title-form-group">Подтверждение пароля</div>

    <div class="input-group">
      <input data-test-id="new-password-repeat" type="password" formControlName="passwordRepeat" class="form-control" tabindex="2" placeholder="Подтверждение пароля" required>
    </div>

  </div>

  <div class="form-group">
    <button data-test-id="btn-create-new-password" type="submit" class="btn btn-primary sc-wide" [disabled]="cannotSubmit || forgotCreatePasswordForm.invalid || !!formError">Продолжить</button>
  </div>

</form>
