import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import {
    AuthReducerState,
    DEFAULT_AUTH_FEATURE_NAME,
} from '../reducers/auth.reducer';


const selectAuth = createFeatureSelector<AuthReducerState>(DEFAULT_AUTH_FEATURE_NAME);

export const selectError = createSelector(
    selectAuth,
    state => state.error,
);

export const selectAuthorization = createSelector(
    selectAuth,
    state => state.authorization,
);

export const selectAccessToken = createSelector(
    selectAuthorization,
    authorization => authorization?.accessToken,
);

export const selectIsInit = createSelector(
    selectAuth,
    state => state.isInit,
);

export const selectIsAuth = createSelector(
    selectAccessToken,
    accessToken => !!accessToken,
);

export const selectIsLoading = createSelector(
    selectAuth,
    state => state.isLoading,
);
