import {
    HttpClient,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { utils } from '@scatch/ngx-app-lib';
import { ToastrService } from 'ngx-toastr';
import {
    Observable,
    throwError,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';


const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': 'application/json',
};

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private prefix = '';

    constructor(
        private http: HttpClient,
        private alertService: ToastrService,
    ) { }

    private makeUri(path: string): string {
        return `${environment.api.url}${this.prefix}${path}`;
    }

    private handleErrors(response: any): Observable<never> {
        const error = utils.formatErrors(response);
        this.alertService.error(error.message);
        return throwError(response);
    }

    get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
        return this.http.get<T>(this.makeUri(path), {
            ...params,
            headers: {
                Accept: 'application/json',
            },
        })
            .pipe(catchError(this.handleErrors.bind(this)));
    }

    put<T>(path: string, body: object = {}): Observable<T> {
        return this.http.put<T>(this.makeUri(path), body, {headers})
            .pipe(catchError(this.handleErrors.bind(this)));
    }

    patch<T>(path: string, body: object = {}): Observable<T> {
        return this.http.patch<T>(this.makeUri(path), body, {headers})
            .pipe(catchError(this.handleErrors.bind(this)));
    }

    post<T>(path: string, body: object = {}): Observable<T> {
        return this.http.post<T>(this.makeUri(path), body, {headers})
            .pipe(catchError(this.handleErrors.bind(this)));
    }

    delete<T>(path: string): Observable<T> {
        return this.http.delete<T>(this.makeUri(path))
            .pipe(catchError(this.handleErrors.bind(this)));
    }

}
