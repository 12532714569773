import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactSimpleComponent } from './contact-simple/contact-simple.component';


@NgModule({
    declarations: [
        ContactSimpleComponent,
    ],
    exports: [
        ContactSimpleComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class ContactsUiModule {}
