import { Component } from '@angular/core';


@Component({
    selector: 'app-layout-blank',
    templateUrl: './layout-blank.component.html',
    styleUrls: ['./layout-blank.component.scss'],
    styles: [':host { display: block; height:100%; overflow: hidden;}'],
})
export class LayoutBlankComponent {}
