import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import * as GeoJSON from 'geojson';
import {
    BehaviorSubject,
    Observable,
    Subject,
} from 'rxjs';
import {
    debounceTime,
    map,
    tap,
} from 'rxjs/operators';


const logger = new Logger('MapService');

export abstract class MapService {

    private isLoaded = new BehaviorSubject<boolean>(false);
    private boundsSubject = new BehaviorSubject<schemas.MapBounds | undefined>(undefined);
    private mapClickSubject = new Subject<schemas.MapMouseEvent>();
    private pointClickSubject = new Subject<schemas.MapMouseEvent>();
    private clusterClickSubject = new Subject<schemas.MapMouseEvent>();
    private selectedFeatureSubject = new BehaviorSubject<GeoJSON.Feature<GeoJSON.Geometry> | undefined>(undefined);

    abstract init(options: schemas.MapOptions): void;

    abstract reset(): void;

    abstract showGeoJSON(geoJSON: any, id?: string): void;

    abstract showGeoJSONCluster(geoJSON: any, id?: string): void;

    get isLoaded$(): Observable<boolean> {
        return this.isLoaded.asObservable().pipe(
            tap(value => logger.debug('map isLoaded$', value)),
        );
    }

    get bounds$(): Observable<schemas.MapBounds | undefined> {
        return this.boundsSubject.asObservable().pipe(
            debounceTime(50),
        );
    }

    get mapClick$(): Observable<schemas.MapMouseEvent> {
        return this.mapClickSubject.asObservable();
    }

    get pointClick$(): Observable<schemas.MapMouseEvent> {
        return this.pointClickSubject.asObservable();
    }

    get clusterClick$(): Observable<schemas.MapMouseEvent> {
        return this.clusterClickSubject.asObservable();
    }

    get selectedFeature$(): Observable<GeoJSON.Feature<GeoJSON.Geometry> | undefined> {
        return this.selectedFeatureSubject.asObservable();
    }

    get selectedFeatureId$(): Observable<string | number | undefined> {
        return this.selectedFeature$.pipe(
            map(feature => feature?.id),
        );
    }

    protected emitIsLoaded(value: boolean = true): void {
        this.isLoaded.next(value);
    }

    protected emitBounds(value?: schemas.MapBounds): void {
        this.boundsSubject.next(value);
    }

    protected emitMapClick(event: schemas.MapMouseEvent): void {
        this.mapClickSubject.next(event);
    }

    protected emitPointClick(event: schemas.MapMouseEvent): void {
        this.pointClickSubject.next(event);
    }

    protected emitClusterClick(event: schemas.MapMouseEvent): void {
        this.clusterClickSubject.next(event);
    }

    protected emitSelectedFeature(feature?: GeoJSON.Feature<GeoJSON.Geometry>): void {
        this.selectedFeatureSubject.next(feature);
    }

}
