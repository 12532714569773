import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationItemComponent } from './notification-item/notification-item.component';
import {RouterModule} from '@angular/router';


@NgModule({
    declarations: [
        NotificationItemComponent,
    ],
    exports: [
        NotificationItemComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ],
})

export class NotificationUiModule {
}
