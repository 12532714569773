import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SocialUiModule } from '../social/social.module';
import { ConnectionLocationItemComponent } from './connection-location-item/connection-location-item.component';


@NgModule({
    declarations: [
        ConnectionLocationItemComponent,
    ],
    exports: [
        ConnectionLocationItemComponent,
    ],
    imports: [
        CommonModule,
        SocialUiModule,
    ],
})
export class ConnectionUiModule {}
