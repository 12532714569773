import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Widget } from '../../../schemas/about.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-contact-simple',
    templateUrl: './contact-simple.component.html',
    styleUrls: ['./contact-simple.component.scss'],
})
export class ContactSimpleComponent implements OnInit {

    @Input() item: Widget | undefined;

    constructor() { }

    ngOnInit(): void {
    }

}
