import { Injectable } from '@angular/core';
import {
    HTTP_INTERCEPTORS,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class DeviceHashInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        if (request.method === 'POST') {
            request = request.clone({
                setHeaders: {
                    'X-Device-Hash': 'mobile',
                },
            });
        }

        return next.handle(request);
    }
}

export const DeviceHashInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: DeviceHashInterceptor,
    multi: true,
};
