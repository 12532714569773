import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Subscription } from 'rxjs';
import { ConnectionFacade } from '../../../facades/connection.facade';
import { FavoriteFacade } from '../../../facades/favorite.facade';
import { Location } from '../../../schemas/location.schemas';


const logger = new Logger('LocationSlimListComponent');

@Component({
    selector: 'app-location-slim-list',
    templateUrl: './location-slim-list.component.html',
    styleUrls: ['./location-slim-list.component.scss'],
})
export class LocationSlimListComponent implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
    swipedLocation?: Location;
    currentConnectedLocationId?: number;

    @Input() locations: schemas.Location[] = [];

    @Output() locationClick = new EventEmitter<schemas.Location>();

    constructor(
        private connectionFacade: ConnectionFacade,
        private favoriteFacade: FavoriteFacade,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.connectionFacade.currentLocationId$.subscribe(locationId => {
                this.currentConnectedLocationId = locationId;
            }),
        );
    }

    ngOnDestroy(): void {
        if (this.subscriptions.length) {
            this.subscriptions.forEach(
                subscription => subscription.unsubscribe(),
            );
            this.subscriptions = [];
        }
    }

    onLocationClick(location: schemas.Location): void {
        logger.debug('onLocationClick', location);

        this.locationClick.emit(location);
    }

    onLocationSwipe(location: Location): void {
        this.swipedLocation = location;
    }
}
