import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-tab-list-two-items',
    templateUrl: './tab-list-two-items.component.html',
    styleUrls: ['./tab-list-two-items.component.scss'],
})
export class TabListTwoItemsComponent {

    @Output() tabSelect = new EventEmitter<{ tabId: number }>();

    selectTab(tabId: number): void {
        this.tabSelect.emit({tabId});
    }

}
