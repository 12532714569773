import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { schemas } from '@scatch/ngx-app-lib';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-form-phone-form',
    templateUrl: './form-phone-form.component.html',
    styleUrls: ['./form-phone-form.component.scss'],
})
export class FormPhoneFormComponent implements OnInit {

    forgotForm: FormGroup;

    @Input() formError: schemas.ResponseError | null = null;
    @Input() cannotSubmit: boolean | null = null;
    @Output() formSubmit = new EventEmitter();

    constructor() {

        this.forgotForm = new FormGroup({

            phone: new FormControl('', [
                Validators.required,
                Validators.pattern(/^([0-9]{8,14}|)$/),
            ]),

        });

    }

    ngOnInit(): void {
    }

    onFormChange(): void {
        this.formError = null;
    }

    onSubmit(): void {
        this.forgotForm.value.phone = '+7' + this.forgotForm.value.phone;
        this.formSubmit.emit(this.forgotForm.value);
    }

}
