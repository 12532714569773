import {
    Component,
    OnInit,
} from '@angular/core';


@Component({
    selector: 'app-capsule-info',
    templateUrl: './capsule-info.component.html',
    styleUrls: ['./capsule-info.component.scss'],
})
export class CapsuleInfoComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
