import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    LocationApi,
    Logger,
    schemas,
    utils,
} from '@scatch/ngx-app-lib';
import { of } from 'rxjs';
import {
    catchError,
    filter,
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';

import { ConnectionService } from '../../services/connection.service';
import * as capsuleActions from '../actions/capsule.actions';
import * as connectionActions from '../actions/connection.actions';
import * as routerActions from '../actions/router.actions';
import * as connectionSelectors from '../selectors/connection.selectors';


const logger = new Logger('ConnectionEffects');

@Injectable()
export class ConnectionEffects {

    constructor(
        private actions$: Actions,
        private connectionService: ConnectionService,
        private locationApi: LocationApi,
        private store$: Store,
    ) {}

    connectEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(connectionActions.connectAction),
            tap(({token}) => logger.debug('connectEffect (token)', token)),
            switchMap(({token}) =>
                this.connectionService.connect(token).pipe(
                    tap(connection => logger.debug('connectEffect (connection)', connection)),
                    map((connection: schemas.Connection) =>
                        connectionActions.connectSuccessAction({
                            connection,
                        }),
                    ),
                    catchError(response => of(
                        connectionActions.connectFailureAction({
                            error: utils.formatErrors(response),
                        }),
                    )),
                    first(),
                ),
            ),
        ),
    );

    connectToLocationEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(connectionActions.connectToLocationAction),
            tap(({location}) => logger.debug('connectToLocationEffect (location)', location)),
            switchMap(({location}) => this.store$.pipe(
                select(connectionSelectors.selectConnections),
                tap(connections => logger.debug('connectToLocationEffect (connections)', connections)),
                map(connections => connections.find(item => item.location.id === location.id)),
                tap(connection => logger.debug('connectToLocationEffect (connection)', connection)),
                tap(connection => {
                    if (connection) {
                        this.store$.dispatch(connectionActions.setCurrentConnectionAction({
                            connection,
                        }));
                        this.store$.dispatch(routerActions.goWidgetsAction());
                    }
                }),
                filter(connection => !connection),
                switchMap(() =>
                    this.locationApi.fetchPointByLocationId(location.id).pipe(
                        tap(point => logger.debug('connectToLocationEffect (point)', point)),
                        tap(point => {
                            if (point && !point.isDynamic && point.token) {
                                this.store$.dispatch(connectionActions.connectAction({
                                    token: point.token,
                                }));
                            }
                        }),
                    ),
                ),
                first(),
            )),
        ),
        {dispatch: false},
    );

    loadConnectionCapsuleEffect$ = createEffect(
        () => this.actions$.pipe(
            ofType(connectionActions.connectSuccessAction),
            tap(({connection}) => logger.debug('loadConnectionCapsuleEffect (connection)', connection)),
            map(({connection}) =>
                capsuleActions.loadCapsuleAction({
                    capsuleId: connection.capsule.id,
                }),
            ),
        ),
    );

    syncCurrentCapsuleIdEffects$ = createEffect(
        () => this.store$.pipe(
            select(connectionSelectors.selectCurrentConnection),
            tap(connection => logger.debug('syncCurrentCapsuleIdEffects (connection)', connection)),
            map(connection => connection
                ? capsuleActions.setCurrentCapsuleId({
                    capsuleId: connection.capsule.id,
                })
                : capsuleActions.unsetCurrentCapsuleId(),
            ),
        ),
    );

}
