import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SafePipeModule } from '@scatch/ngx-app-lib';
import { WidgetUiModule } from '../../ui/widget/widget.module';
import { WidgetAudioPlayerComponent } from './widget-audio-player/widget-audio-player.component';
import { WidgetChatComponent } from './widget-chat/widget-chat.component';
import { WidgetFrameComponent } from './widget-frame/widget-frame.component';
import { WidgetListComponent } from './widget-list/widget-list.component';


@NgModule({
    declarations: [
        WidgetListComponent,
        WidgetChatComponent,
        WidgetAudioPlayerComponent,
        WidgetFrameComponent,
    ],
    imports: [
        CommonModule,
        WidgetUiModule,
        RouterModule,
        SafePipeModule,
    ],
})
export class WidgetModule {}
