import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConnectionDisconnectComponent } from './connection-disconnect.component';


const components = [
    ConnectionDisconnectComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ...components,
    ],
})
export class ConnectionDisconnectModule {}
