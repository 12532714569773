import {
    Component,
    OnInit,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-account-profile-form',
    templateUrl: './account-profile-form.component.html',
    styleUrls: [
        './account-profile-form.component.scss',
    ],
})
export class AccountProfileFormComponent implements OnInit {

    accountProfileForm: FormGroup;

    languages = [
        {value: 'English', label: 'English'},
        {value: 'Russian', label: 'Русский'},
    ];

    accountData = {
        avatar: './assets/images/avatar.png',
        firstName: 'John',
        lastName: 'Doe',
        gender: 'Женский',
        city: 'Иркутск, Российская Федерация',
        email: 'nmaxwell@mail.com',
        verified: false,
        phone: '+79248307702',
        languages: ['Russian'],
    };

    constructor() {

        this.accountProfileForm = new FormGroup({

            firstName: new FormControl('', [
                Validators.required,
            ]),
            lastName: new FormControl('', []),

            phone: new FormControl('', [
                Validators.required,
            ]),
            gender: new FormControl('', []),
            city: new FormControl('', []),
            email: new FormControl('', []),
            languages: new FormControl('', []),

        });
    }

    ngOnInit(): void {
    }

}
