import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Logger } from '@scatch/ngx-app-lib';


interface Point {
    x: number;
    y: number;
}

const logger = new Logger('FloatPanelComponent');

const requestAnimFrame = ((): (callback: () => void) => void => {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        // @ts-ignore
        window.mozRequestAnimationFrame ||
        (callback => {
            window.setTimeout(callback, 1000 / 60);
        });
})();

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-float-panel',
    templateUrl: './float-panel.component.html',
    styleUrls: ['./float-panel.component.scss'],
})
export class FloatPanelComponent implements OnInit, AfterViewInit, OnChanges {

    private initialTouchPos?: Point;
    private lastTouchPos?: Point;
    private rafPending = false;
    private panelHeight = 0;
    private maxPanelHeight = 0;
    private slopValue = 0;
    private panelElement?: HTMLElement;

    @Input() level = 100;
    @Input() levels: number[] = [];
    @Input() canSwipe = true;

    @Output() panelClose = new EventEmitter<void>();
    @Output() levelChange = new EventEmitter<number>();

    @ViewChild('panel') panelElementRef?: ElementRef;

    @HostListener('window:resize', ['$event'])
    resize(): void {
        this.maxPanelHeight = this.panelElement?.offsetParent?.clientHeight || 0;
        this.panelHeight = this.panelElement?.clientHeight || 0;
        this.slopValue = this.maxPanelHeight * (1 / 10);

        if (this.panelHeight <= 0) {
            this.close();
        }
    }

    ngOnInit(): void {
        this.levels.push(0, 100);
        this.levels = this.levels.map(Number);
        this.levels.sort((a, b) => a - b);
        this.level = Math.max(0, Math.min(100, Number(this.level)));
        logger.debug('canSwipe, level, levels', this.canSwipe, this.level, this.levels);
    }

    ngAfterViewInit(): void {
        this.panelElement = this.panelElementRef?.nativeElement;
        this.resize();

        if (this.panelElement) {
            this.setPanelHeight(this.calcLevelHeight(this.level));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.level) {
            this.setPanelHeight(
                this.calcLevelHeight(changes.level.currentValue),
            );
        }
    }

    onSwipeStart(event: PointerEvent): void {
        if (!this.canSwipe || !this.panelElement) {
            return;
        }

        this.initialTouchPos = {
            x: event.clientX,
            y: event.clientY,
        };

        this.panelElement.style.transition = 'initial';
    }

    onSwipeMove(event: PointerEvent): void {
        if (!this.canSwipe || !this.initialTouchPos) {
            return;
        }

        this.lastTouchPos = {
            x: event.clientX,
            y: event.clientY,
        };

        if (this.rafPending) {
            return;
        }

        this.rafPending = true;

        requestAnimFrame(this.onAnimFrame.bind(this));
    }

    onSwipeEnd(): void {
        if (!this.canSwipe) {
            return;
        }

        this.rafPending = false;

        this.updateSwipeRestPosition();

        this.initialTouchPos = undefined;
        this.lastTouchPos = undefined;
    }

    onAnimFrame(): void {
        if (!this.rafPending) {
            return;
        }

        if (this.panelElement) {
            const differenceInY = this.calcDifferenceInY();
            if (differenceInY > 20) {
                this.panelElement.style.height = this.panelHeight + differenceInY + 'px';
            }
        }

        this.rafPending = false;
    }

    private calcDifferenceInY(): number {
        return (this.initialTouchPos && this.lastTouchPos)
            ? this.initialTouchPos.y - this.lastTouchPos.y
            : 0;
    }

    private updateSwipeRestPosition(): void {
        if (!this.panelElement) {
            return;
        }

        const differenceInY = this.calcDifferenceInY();
        let nextPanelHeight = this.panelHeight;
        let nextLevel = this.level;

        if (Math.abs(differenceInY) > this.slopValue) {
            let currentPanelHeight = this.panelHeight + differenceInY;

            if (currentPanelHeight > this.maxPanelHeight) {
                currentPanelHeight = this.maxPanelHeight;
            } else if (currentPanelHeight < 0) {
                currentPanelHeight = 0;
            }

            const levels = this.levels.slice();

            if (differenceInY < 0) {
                levels.reverse();
            }

            for (const level of levels) {
                const levelHeight = this.calcLevelHeight(level);
                if (differenceInY >= 0) {
                    if (levelHeight >= currentPanelHeight) {
                        nextPanelHeight = levelHeight;
                        nextLevel = level;
                        break;
                    }
                } else {
                    if (levelHeight <= currentPanelHeight) {
                        nextPanelHeight = levelHeight;
                        nextLevel = level;
                        break;
                    }
                }
            }
        }

        this.setPanelHeight(nextPanelHeight);
        this.levelChange.emit(nextLevel);
    }

    private calcLevelHeight(level: number): number {
        return Math.max(
            0,
            Math.min(
                this.maxPanelHeight,
                Math.ceil(this.maxPanelHeight / 100 * level),
            ),
        );
    }

    private setPanelHeight(height: number): void {
        if (!this.panelElement) {
            return;
        }

        this.panelElement.style.height = height + 'px';
        this.panelElement.style.transition = 'all 150ms ease-out';

        setTimeout(this.resize.bind(this), 200);
    }

    close(): void {
        if (this.panelElement) {
            this.panelElement.style.display = 'none';
        }
        this.panelClose.emit();
    }

}
