import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
} from '@angular/core';
import { ExtraCapsule } from '../../../schemas/capsules.schemas';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-connect-location-item',
    templateUrl: './connect-location-item.component.html',
    styleUrls: ['./connect-location-item.component.scss'],
})
export class ConnectLocationItemComponent implements OnInit {

    @Input() capsule: ExtraCapsule | undefined;

    @Output() deletingLocation = new EventEmitter<number>();
    @Output() settingCapsuleFavourites = new EventEmitter<number>();
    @Output() deletingCapsuleFavourites = new EventEmitter<number>();

    constructor() {
    }

    ngOnInit(): void {
    }

    deleteLocation(id: number | undefined): void {
        this.deletingLocation.emit(id);
    }

    setCapsuleFavourites(id: number | undefined): void {
        this.settingCapsuleFavourites.emit(id);
    }

    deleteCapsuleFavourites(id: number | undefined): void {
        this.deletingCapsuleFavourites.emit(id);
    }

}
