import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import {
    DEFAULT_SCANNER_FEATURE_NAME,
    ScannerReducerState,
} from '../reducers/scanner.reducer';


const selectScannerState = createFeatureSelector<ScannerReducerState>(DEFAULT_SCANNER_FEATURE_NAME);

export const selectDemoCodes = createSelector(
    selectScannerState,
    state => state.demoCodes,
);
