import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ui-tag-selector',
    templateUrl: './tag-selector.component.html',
    styleUrls: ['./tag-selector.component.scss'],
})
export class TagSelectorComponent {

    @Input() tags?: schemas.Tag[];
    @Input() tagMore?: schemas.Tag;
    @Input() selectedTag?: schemas.Tag;

    @Output() tagSelect = new EventEmitter<schemas.Tag>();

    isExpanded = false;

    getTags(): schemas.Tag[] {
        const tags: schemas.Tag[] = [];

        if (this.tags) {

            if (this.selectedTag) {
                const index = this.tags.findIndex(item => item.id === this.selectedTag?.id);
                if (index > 2) {
                    this.tags.unshift(...this.tags.splice(index, 1));
                }
            }

            if (this.tagMore && !this.isExpanded && this.tags.length > 4) {
                tags.push(...this.tags.slice(0, 3), this.tagMore);
            } else {
                tags.push(...this.tags);
            }

        }

        return tags;
    }

    onTagSelect(tag: schemas.Tag): void {
        if (this.tagMore && tag.id === this.tagMore.id) {
            this.isExpanded = true;
        } else {
            this.tagSelect.emit(tag);
            this.isExpanded = false;
        }
    }

}
