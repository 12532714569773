import { Location } from '@angular/common';
import {
    Component,
    HostBinding,
    Input,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';
import { ConnectionFacade } from '../../facades/connection.facade';
import { LayoutService } from '../layout.service';


@Component({
    selector: 'app-layout-iterra-navbar-back',
    templateUrl: './layout-iterra-navbar-back.component.html',
    styleUrls: ['./layout-iterra-navbar-back.component.scss'],
})
export class LayoutIterraNavbarBackComponent {

    isRTL: boolean;
    isConnected$: Observable<boolean> = this.connectionFacade.isConnected$;

    @Input() sidenavToggle = true;

    constructor(
        private appService: AppService,
        private layoutService: LayoutService,
        private connectionFacade: ConnectionFacade,
        private location: Location,
    ) {
        this.isRTL = appService.isRTL;
    }

    currentBg(): string {
        return `bg-${this.appService.layoutNavbarBg}`;
    }

    toggleSidenav(): void {
        this.layoutService.toggleCollapsed();
    }

    goBack(): void {
        this.location.back();
    }

}
