import { Injectable } from '@angular/core';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    map,
    switchMap,
} from 'rxjs/operators';

import * as scannerActions from '../store/actions/scanner.actions';
import * as connectionSelectors from '../store/selectors/connection.selectors';
import * as scannerSelectors from '../store/selectors/scanner.selectors';


const log = new Logger('ScannerFacade');

@Injectable({
    providedIn: 'root',
})
export class ScannerFacade {

    constructor(
        private store$: Store,
    ) { }

    get demoCodes$(): Observable<schemas.DemoCode[]> {
        return this.store$.pipe(
            select(scannerSelectors.selectDemoCodes),
            switchMap(demoCodes => this.store$.pipe(
                select(connectionSelectors.selectConnections),
                map(connections => demoCodes.filter(
                    demoCode => !connections.filter(
                        connection => connection.location.id === Number(demoCode.data),
                    ).length,
                )),
            )),
        );
    }

    loadDemoCodes(): void {
        this.store$.dispatch(scannerActions.loadDemoCodesAction());
    }

    parse(data: string): void {
        this.store$.dispatch(scannerActions.parseDataAction({data}));
    }

}
