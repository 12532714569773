<div class="location" (click)="connect(location)">
    <div class="location__levels">
        <i *ngFor="let item of levels; let index = index"
           class="location__level"
           [style.background-color]="getColor(index)">
        </i>
    </div>
    <div class="location__icon"
         [ngClass]="{'location__active': isActive}"
         [ngStyle]="{'background-image': 'url('+ location?.icon +')'}"></div>
    <div class="location__overflow">
        <div class="location__title"> {{ location?.title}}</div>
        <div class="location__rubric">{{ location?.rubric }}</div>
    </div>
</div>
