import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TagModule } from '../tag/tag.module';
import { TagSelectorComponent } from './tag-selector.component';


const components = [
    TagSelectorComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        TagModule,
    ],
    exports: [
        ...components,
    ],
})
export class TagSelectorModule {}
