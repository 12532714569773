<div class="connection-location"
     [ngClass]="{'connection-location--nesting': location?.parentId, 'connection-location--active': isActive}"
     [style.background-color]="getColor()">

    <div class="connection-location__link" (click)="connect(location)">
        <div [hidden]="!location?.parentId" class="connection-location__level">{{ location?.level }}</div>
        <div class="connection-location__icon" [ngStyle]="{'background-image': 'url('+ location?.icon +')'}"></div>
        <div class="connection-location__text">{{ location?.title }}</div>
    </div>

    <div class="connection-location__tools">

        <div [hidden]="location?.isDynamic" class="connection-location__favorites connection-location__link">

            <i [hidden]="!location?.isFavorite"
               (click)="toggleFavorite(location)"
               class="connection-location__social-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit">
                    <g id="24 / basic / star">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                              d="M12 19.1298L4.79403 22.9182L6.17025 14.8942L0.340485 9.21159L8.39702 8.04091L12 0.740448L15.603 8.04091L23.6595 9.21159L17.8298 14.8942L19.206 22.9182L12 19.1298Z"
                              fill="inherit"/>
                    </g>
                </svg>
            </i>

            <i [hidden]="location?.isFavorite"
               (click)="toggleFavorite(location)"
               class="connection-location__social-item connection-location__social-item--favorite">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="inherit">
                    <g id="24 / basic / star">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                              d="M6.17025 14.8942L4.79403 22.9182L12 19.1298L19.206 22.9182L17.8298 14.8942L23.6595 9.21159L15.603 8.04091L12 0.740448L8.39702 8.04091L0.340485 9.21159L6.17025 14.8942ZM15.6808 14.196L16.5497 19.2622L12 16.8702L7.45029 19.2622L8.31921 14.196L4.63841 10.6081L9.72515 9.86892L12 5.25955L14.2749 9.86892L19.3616 10.6081L15.6808 14.196Z"
                              fill="inherit"/>
                    </g>
                </svg>
            </i>

        </div>

        <div [hidden]="!isConnected" class="connection-location__link">
            <i (click)="disconnect(location)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="24 / basic / plus">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                              d="M12 10.5858L18.3639 4.22183L19.7782 5.63604L13.4142 12L19.7782 18.364L18.3639 19.7782L12 13.4142L5.63602 19.7782L4.22181 18.364L10.5858 12L4.22181 5.63604L5.63602 4.22183L12 10.5858Z"
                              fill="inherit"/>
                    </g>
                </svg>
            </i>
        </div>

    </div>

</div>
