import {
    Component,
    OnInit,
} from '@angular/core';
import { schemas } from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';

import { AuthFacade } from '../../../facades/auth.facade';
import { PhoneCredentials } from '../../../schemas/auth.schemas';


@Component({
    selector: 'app-auth-phone-sign-in',
    templateUrl: './auth-phone-sign-in.component.html',
    styleUrls: ['./auth-phone-sign-in.component.scss'],
})
export class AuthPhoneSignInComponent implements OnInit {

    isLoading$: Observable<boolean> = this.authFacade.isLoading$;
    error$: Observable<schemas.ResponseError | null> = this.authFacade.error$;

    constructor(
        private authFacade: AuthFacade,
    ) { }

    ngOnInit(): void { }

    onFormSubmit(credentials: PhoneCredentials): void {
        this.authFacade.signInByPhoneCredentials(credentials);
    }

}
