import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

import { PipesModule } from '../pipes/pipes.module';

import { ButtonComponent } from './button/button.component';
import { FormPhoneCodeFormComponent } from './form/form-phone-code-form/form-phone-code-form.component';
import { FormPhoneFormComponent } from './form/form-phone-form/form-phone-form.component';
import { LogoComponent } from './icons/logo/logo.component';
import { MapControlComponent } from './icons/map-control/map-control.component';
import { PanelComponent } from './panel/panel.component';


@NgModule({
    declarations: [
        MapControlComponent,
        PanelComponent,
        ButtonComponent,
        LogoComponent,
        FormPhoneCodeFormComponent,
        FormPhoneFormComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        PipesModule,
        FormsModule,
        NgSelectModule,
        NgxMaskModule,
    ],
    exports: [
        MapControlComponent,
        PanelComponent,
        LogoComponent,
        FormPhoneCodeFormComponent,
        FormPhoneFormComponent,
    ],
})

export class UiModule {}
