import {
    DEFAULT_ROUTER_FEATURENAME,
    routerReducer,
    RouterReducerState,
} from '@ngrx/router-store';
import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer,
} from '@ngrx/store';

import { environment } from '../../environments/environment';
import { AuthEffects } from './effects/auth.effects';
import { CapsuleEffects } from './effects/capsule.effects';
import { ConnectionEffects } from './effects/connection.effects';
import { HistoryEffects } from './effects/history.effects';
import { FavoriteEffects } from './effects/favorite.effects';
import { LayoutEffects } from './effects/layout.effects';
import { LocalStorageEffects } from './effects/local-storage.effects';
import { LocationEffects } from './effects/location.effects';
import { PasswordEffects } from './effects/password.effects';
import { PhoneEffects } from './effects/phone.effects';
import { RouterEffects } from './effects/router.effects';
import { ScannerEffects } from './effects/scanner.effects';
import { WidgetEffects } from './effects/widget.effects';
import {
    authReducer,
    AuthReducerState,
    DEFAULT_AUTH_FEATURE_NAME,
} from './reducers/auth.reducer';
import {
    capsuleReducer,
    CapsuleReducerState,
    DEFAULT_CAPSULE_FEATURE_NAME,
} from './reducers/capsule.reducer';
import {
    connectionReducer,
    ConnectionReducerState,
    DEFAULT_CONNECTION_FEATURE_NAME,
} from './reducers/connection.reducer';
import {
    DEFAULT_FAVORITE_FEATURE_NAME,
    favoriteReducer,
    FavoriteReducerState,
} from './reducers/favorite.reducer';
import {
    DEFAULT_LAYOUT_FEATURE_NAME,
    layoutReducer,
    LayoutReducerState,
} from './reducers/layout.reducer';
import {
    DEFAULT_LOCATION_FEATURE_NAME,
    locationReducer,
    LocationReducerState,
} from './reducers/location.reducer';
import {
    DEFAULT_PHONE_FEATURE_NAME,
    phoneReducer,
    PhoneReducerState,
} from './reducers/phone.reducer';
import {
    DEFAULT_SCANNER_FEATURE_NAME,
    scannerReducer,
    ScannerReducerState,
} from './reducers/scanner.reducer';
import {
    DEFAULT_WIDGET_FEATURE_NAME,
    widgetReducer,
    WidgetReducerState,
} from './reducers/widget.reducer';


export interface State {
    [DEFAULT_AUTH_FEATURE_NAME]: AuthReducerState;
    [DEFAULT_PHONE_FEATURE_NAME]: PhoneReducerState;
    [DEFAULT_ROUTER_FEATURENAME]: RouterReducerState;
    [DEFAULT_CAPSULE_FEATURE_NAME]: CapsuleReducerState;
    [DEFAULT_SCANNER_FEATURE_NAME]: ScannerReducerState;
    [DEFAULT_WIDGET_FEATURE_NAME]: WidgetReducerState;
    [DEFAULT_CONNECTION_FEATURE_NAME]: ConnectionReducerState;
    [DEFAULT_LAYOUT_FEATURE_NAME]: LayoutReducerState;
    [DEFAULT_LOCATION_FEATURE_NAME]: LocationReducerState;
    [DEFAULT_FAVORITE_FEATURE_NAME]: FavoriteReducerState;
}

export const reducers: ActionReducerMap<State> = {
    [DEFAULT_AUTH_FEATURE_NAME]: authReducer,
    [DEFAULT_PHONE_FEATURE_NAME]: phoneReducer,
    [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
    [DEFAULT_CAPSULE_FEATURE_NAME]: capsuleReducer,
    [DEFAULT_SCANNER_FEATURE_NAME]: scannerReducer,
    [DEFAULT_WIDGET_FEATURE_NAME]: widgetReducer,
    [DEFAULT_CONNECTION_FEATURE_NAME]: connectionReducer,
    [DEFAULT_LAYOUT_FEATURE_NAME]: layoutReducer,
    [DEFAULT_LOCATION_FEATURE_NAME]: locationReducer,
    [DEFAULT_FAVORITE_FEATURE_NAME]: favoriteReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [debug]
    : [];

export const effects = [
    AuthEffects,
    PasswordEffects,
    PhoneEffects,
    RouterEffects,
    LocalStorageEffects,
    CapsuleEffects,
    ScannerEffects,
    WidgetEffects,
    ConnectionEffects,
    LayoutEffects,
    LocationEffects,
    FavoriteEffects,
    HistoryEffects
];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        console.log('[State]', state);
        console.log('[Action]', action);

        return reducer(state, action);
    };
}
