import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import {
    select,
    Store,
} from '@ngrx/store';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import { Observable } from 'rxjs';
import {
    filter,
    first,
    tap,
} from 'rxjs/operators';

import * as scannerActions from '../store/actions/scanner.actions';
import * as scannerSelectors from '../store/selectors/scanner.selectors';


const log = new Logger('DemoCodesResolver');

@Injectable({
    providedIn: 'root',
})
export class DemoCodesResolver implements Resolve<schemas.DemoCode[]> {

    constructor(
        private store$: Store,
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<schemas.DemoCode[]> {
        return this.store$.pipe(
            select(scannerSelectors.selectDemoCodes),
            tap(loaded => {
                if (!loaded.length) {
                    this.store$.dispatch(scannerActions.loadDemoCodesAction());
                }
            }),
            filter(loaded => loaded.length > 0),
            first(),
        );
    }

}
