import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocationIconStructureComponent } from './location-icon-structure.component';


const components = [
    LocationIconStructureComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ],
    exports: [
        ...components,
    ],
})
export class LocationIconStructureModule {}
