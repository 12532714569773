import { Injectable } from '@angular/core';
import {
    Logger,
    schemas,
} from '@scatch/ngx-app-lib';
import * as GeoJSON from 'geojson';
import {
    CirclePaint,
    EventData,
    GeoJSONSource,
    Map,
    MapboxGeoJSONFeature,
    MapboxOptions,
    MapLayerMouseEvent,
    MapMouseEvent,
    Point,
    GeolocateControl,
} from 'mapbox-gl';
import { environment } from '../../../environments/environment';
import { MapboxStyles } from '../../app.constants';
import { MapService } from './map.service';


const logger = new Logger('MapBoxService');

@Injectable({
    providedIn: 'root',
})
export class MapBoxService extends MapService {

    private map: Map | undefined;
    private layerIdList: string[] = [];
    private sourceIdList: string[] = [];

    private static prepareMapOptions(options: schemas.MapOptions): MapboxOptions {
        const mapOptions: MapboxOptions = {
            ...MapboxStyles.mapInitOptions,
            accessToken: environment.mapboxKey,
            style: environment.mapboxStyle,
        };

        if (options.container) {
            mapOptions.container = options.container;
        }

        if (options.center) {
            mapOptions.center = options.center;
        }

        if (options.zoom) {
            mapOptions.zoom = options.zoom;
        }

        if (typeof options.canRotate === 'boolean') {
            // disable map rotation using right click + drag
            mapOptions.dragRotate = options.canRotate;
        }

        return mapOptions;
    }

    init(options: schemas.MapOptions): void {
        this.map = new Map(MapBoxService.prepareMapOptions(options));

        if (!options.canRotate) {
            // disable map rotation using touch rotation gesture
            this.map.touchZoomRotate.disableRotation();
        }

        const geolocate = new GeolocateControl({
            fitBoundsOptions: {
                zoom: 11,
            },
            positionOptions: {
                enableHighAccuracy: false
            },
            trackUserLocation: true,
            showAccuracyCircle: false,
            showUserLocation: false,
        });
        geolocate.on('error', (error) => {
            logger.debug('An error event has occurred.', error);
        });

        this.map.addControl(geolocate);

        this.map.on('load', () => {
            this.map?.resize();
            this.emitIsLoaded(true);
        });
        this.map.on('click', this.onMapClick.bind(this));
        this.map.on('resize', this.updateBounds.bind(this));
        this.map.on('moveend', this.updateBounds.bind(this));
        this.map.on('zoomend', this.updateBounds.bind(this));
        this.map.on('rotateend', this.updateBounds.bind(this));
        // this.updateBounds();
    }

    reset(): void {
        this.emitIsLoaded(false);
        this.emitSelectedFeature();
        this.emitBounds();

        if (this.map) {
            const map = this.map;

            this.layerIdList.forEach(layerId => {
                if (map.getLayer(layerId)) {
                    map.removeLayer(layerId);
                }
            });

            this.sourceIdList.forEach(sourceId => {
                if (map.getSource(sourceId)) {
                    map.removeSource(sourceId);
                }
            });

            this.map = undefined;
        }
    }

    private initPointsLayerEvents(map: Map): void {
        map.on('click', 'points', this.onPointsLayerClick.bind(this));
        map.on('mousemove', 'points', this.showPointerCursor.bind(this));
        map.on('mouseleave', 'points', this.hidePointerCursor.bind(this));
    }

    private initClusterLayerEvents(map: Map): void {
        map.on('click', 'cluster', this.onClusterLayerClick.bind(this));
        map.on('mousemove', 'cluster', this.showPointerCursor.bind(this));
        map.on('mouseleave', 'cluster', this.hidePointerCursor.bind(this));
    }

    private getMap(): Map {
        if (this.map) {
            return this.map;
        }
        throw new Error('Map not initialized.');
    }

    private showPointerCursor(): void {
        this.getMap().getCanvas().style.cursor = 'pointer';
    }

    private hidePointerCursor(): void {
        this.getMap().getCanvas().style.cursor = '';
    }

    private onPointsLayerClick(event: MapLayerMouseEvent & EventData): void {
        logger.debug('onPointsLayerClick', event);

        if (!event.features || event.features.length === 0) {
            return;
        }

        const feature: MapboxGeoJSONFeature = event.features[0];
        const geometry = feature.geometry as GeoJSON.Point;

        logger.debug('onPointsLayerClick, feature', feature);

        this.setSelectedFeature(feature);

        if (geometry.type === 'Point') {
            this.getMap().flyTo({
                ...MapboxStyles.flyTo,
                center: {
                    lng: geometry.coordinates[0],
                    lat: geometry.coordinates[1],
                },
            });
        }

        this.emitPointClick({
            feature: feature as GeoJSON.Feature<GeoJSON.Geometry>,
            originalEvent: event.originalEvent,
            point: event.point,
            position: event.lngLat as schemas.Position,
            type: 'click',
        });
    }

    private onClusterLayerClick(event: MapLayerMouseEvent & EventData): void {
        logger.debug('onClusterLayerClick', event);

        if (!event.features || event.features.length === 0) {
            return;
        }

        const feature: MapboxGeoJSONFeature = event.features[0];
        const geometry = feature.geometry as GeoJSON.Point;

        logger.debug('onClusterLayerClick, feature', feature);

        this.setSelectedFeature(feature);

        if (geometry.type === 'Point') {
            this.getMap().flyTo({
                ...MapboxStyles.flyTo,
                center: {
                    lng: geometry.coordinates[0],
                    lat: geometry.coordinates[1],
                },
            });
        }

        const clusterId = feature.properties?.cluster_id;
        const pointCount = feature.properties?.point_count;
        const clusterSource = this.getMap().getSource(feature.source);

        if (clusterSource) {
            (clusterSource as GeoJSONSource).getClusterLeaves(
                clusterId,
                pointCount,
                0,
                (error, features: GeoJSON.Feature<GeoJSON.Geometry>[]) => {
                    logger.debug('onClusterLayerClick, cluster leaves:', error, features);

                    if (error) {
                        return logger.error(error);
                    }

                    this.emitClusterClick({
                        feature: feature as GeoJSON.Feature<GeoJSON.Geometry>,
                        features,
                        originalEvent: event.originalEvent,
                        point: event.point,
                        position: event.lngLat as schemas.Position,
                        type: 'click',
                    });
                },
            );
        }
    }

    private onMapClick(event: MapMouseEvent & EventData): void {
        logger.debug('onMapClick', event);

        this.unsetCurrentSelectedFeature();

        const features = this.getMap().queryRenderedFeatures(event.point);
        logger.debug('onMapClick, features', features);

        if (features && features.length) {
            const feature: MapboxGeoJSONFeature = features[0];
            logger.debug('onMapClick, feature', feature);
        }

        this.emitMapClick({
            originalEvent: event.originalEvent,
            point: event.point,
            position: event.lngLat as schemas.Position,
            type: 'click',
        });
    }

    private unsetCurrentSelectedFeature(): void {
        this.selectedFeature$.subscribe(feature => {
            if (feature) {
                logger.debug('unsetCurrentSelectedFeature', feature);

                this.getMap().removeFeatureState(
                    {
                        source: (feature as MapboxGeoJSONFeature).source,
                        id: feature.id,
                    },
                    'selected',
                );

                this.emitSelectedFeature();
            }
        }).unsubscribe();
    }

    private setSelectedFeature(feature: MapboxGeoJSONFeature): void {
        logger.debug('setSelectedFeature', feature);

        this.getMap().setFeatureState(
            {
                source: feature.source,
                id: feature.id,
            },
            {
                selected: true,
            },
        );

        this.emitSelectedFeature(feature);
    }

    private updateBounds(): void {
        const mapboxBounds = this.getMap().getBounds();

        logger.debug('updateBounds, mapboxBounds:', mapboxBounds);
        logger.debug('updateBounds, SouthWest:', mapboxBounds.getSouthWest());
        logger.debug('updateBounds, NorthEast:', mapboxBounds.getNorthEast());

        this.emitBounds({
            sw: mapboxBounds.getSouthWest(),
            ne: mapboxBounds.getNorthEast(),
        });
    }

    showGeoJSON(geoJSON: GeoJSON.Feature<GeoJSON.Geometry>, sourceId = 'geoJSON'): void {
        const map = this.getMap();
        const source = map.getSource(sourceId);

        if (source) {
            (source as GeoJSONSource).setData(geoJSON);
        } else {
            map.addSource(sourceId, {
                type: 'geojson',
                data: geoJSON,
                cluster: true,
                clusterMaxZoom: 15, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            });
            this.sourceIdList.push(sourceId);
        }

        const layerId = 'points';

        if (!map.getLayer(layerId)) {
            map.addLayer({
                id: layerId,
                type: 'circle',
                source: sourceId,
                filter: ['!', ['has', 'point_count']],
                paint: {...MapboxStyles.points as CirclePaint},
            });
            this.layerIdList.push(layerId);
            this.initPointsLayerEvents(map);
        }

        // if (!map.getLayer('points_labels')) {
        //     this.getMap().addLayer({
        //         id: 'points_labels',
        //         type: 'symbol',
        //         source: sourceId,
        //         filter: ['!', ['has', 'point_count']],
        //         layout: {...MapboxStyles.circlesText as SymbolLayout},
        //         paint: {'text-color': '#cccccc'},
        //     });
        //     this.layerIdList.push('points_labels');
        // }
    }

    showGeoJSONCluster(geoJSON: GeoJSON.Feature<GeoJSON.Geometry>, sourceId = 'geoJSON'): void {
        logger.debug('geoJSON', geoJSON);

        this.showGeoJSON(geoJSON, sourceId);

        const map = this.getMap();

        if (!map.getLayer('cluster')) {
            this.getMap().addLayer({
                id: 'cluster',
                type: 'circle',
                source: sourceId,
                filter: ['has', 'point_count'],
                paint: {...MapboxStyles.clusters as CirclePaint},
            });
            this.layerIdList.push('cluster');
            this.initClusterLayerEvents(map);
        }

        if (!map.getLayer('cluster-count')) {
            this.getMap().addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: sourceId,
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['Open Sans SemiBold', 'Open Sans Regular'],
                    'text-size': 12,
                },
                paint: {
                    'text-color': '#fff',
                },
            });
            this.layerIdList.push('cluster-count');
        }
    }

}

export const MapBoxServiceProvider = {
    provide: MapService,
    useExisting: MapBoxService,
};
