import { NavigationExtras } from '@angular/router';
import {
    createAction,
    props,
} from '@ngrx/store';


export const goWithCurrentRedirectUriAction = createAction(
    '[Router] Go With Current Redirect Uri',
    props<{ commands: any[], extras?: NavigationExtras }>(),
);

export const goWidgetsAction = createAction(
    '[Router] Go Widgets Actions',
);

export const goFavoriteLocationListAction = createAction(
    '[Router] Go Favorite Location List Action',
);
